import React, { useContext, useEffect, useRef } from 'react';
import CSS from 'csstype';
import styled, { useTheme } from 'styled-components';
import { Box, ColorVariants, ErrorContext } from '..';

type TextInputProps = {
    /** Background color override */
    bg?: string;
    /** Children to render after the input element */
    children?: React.ReactNode;
    /** The default value */
    value?: any;
    /** If true, the input is disabled */
    disabled?: boolean;
    /** The error message to display to the user */
    error?: string;
    /** The name of the field which will show up in the higher-level form */
    name: string;
    /** This method is invoked when the escape button is pressed while the control has focus */
    onCancel?: () => void;
    /** This method is invoked when the enter button is pressed while the control has focus */
    onSubmit?: (val: string) => void;
    /** If this property is true, the input will be masked */
    password?: boolean;
    /** Placeholder text */
    placeholder?: string;
    /** If true, all borders will be gone */
    noborder?: boolean;
    /** If true, the background and foreground will be inverted */
    inverted?: boolean;
    /** Width override */
    w?: number;
    /** Style to override the Input container */
    style?: CSS.Properties;
    /** The color variant */
    variant?: ColorVariants;
    /** Optional icon to display */
    icon?: React.ReactNode;
} & JSX.IntrinsicElements['input'];

const InputContainer = styled.div`
    display: flex;
    &:focus {
        outline: 2px solid ${(props: any) => props.theme.colors.error};
    }
`;

const InputBox = styled.input`
    border: 1px solid ${(props: any) => props.bg ?? props.theme.colors[props.variant].border};
    border-radius: 9px;
    color: ${(props: any) =>
        props.bg ?? props.inverted
            ? props.theme.colors[props.variant].invertedFg
            : props.theme.colors[props.variant].fg};
    width: 100%;

    padding: ${(props: any) => props.theme.input.padding};
    font-family: ${(props: any) => props.theme.font};
    font-weight: 600;
    background-color: ${(props: any) =>
        props.bg ?? props.inverted
            ? props.theme.colors[props.variant].invertedBg
            : props.theme.colors[props.variant].bg};

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: ${(props: any) => props.theme.colors.disabled};
    }
`;

InputBox.defaultProps = {
    // @ts-ignore
    variant: 'primary',
};

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
    (
        {
            bg,
            color,
            error,
            children,
            name,
            value,
            disabled,
            password,
            noborder,
            onSubmit,
            onCancel,
            w,
            style,
            icon,
            ...props
        }: TextInputProps,
        ref,
    ) => {
        const inputRef = useRef<HTMLInputElement>(null);
        const activeRef = (ref ?? inputRef) as React.MutableRefObject<HTMLInputElement>;

        const formErrors = useContext(ErrorContext);
        const handleKeyUp = (evt: React.KeyboardEvent) => {
            switch (evt.code) {
                case 'Enter':
                    {
                        onSubmit && activeRef && activeRef.current && onSubmit(activeRef.current.value);
                    }
                    break;
                case 'Escape':
                    {
                        activeRef && activeRef.current && activeRef.current.blur();
                        onCancel && onCancel();
                    }
                    break;
            }
        };

        const errorMessage = error ?? formErrors[name];

        useEffect(() => {
            if (activeRef && activeRef.current) {
                activeRef.current.value = value ?? '';
            }
        }, [value, activeRef]);

        const theme = useTheme() as any;
        const inputType = password ? 'password' : 'text';

        return (
            <React.Fragment>
                <InputContainer
                    data-testid="input-container"
                    style={{
                        backgroundColor: disabled ? theme.colors.disabled : '',
                        width: w ? `${w}px` : '',
                        position: 'relative',
                        ...style,
                    }}
                >
                    {icon && (
                        <Box center="all" style={{ position: 'absolute', left: '8px', height: '100%' }}>
                            {icon}
                        </Box>
                    )}
                    <InputBox
                        data-testid="inputbox"
                        name={name}
                        style={{
                            fontSize: theme.text.size,
                            padding: theme.input.padding,
                            ...(icon && { paddingLeft: `calc(${theme.input.padding} + 20px` }),
                            width: w ? `${w}px` : '100%',
                            backgroundColor: bg,
                            color: color,
                            ...(noborder && { border: 'none' }),
                            ...(errorMessage && { borderColor: theme.input.errorBorderColor }),
                        }}
                        type={inputType}
                        disabled={disabled}
                        onKeyUp={handleKeyUp}
                        {...props}
                        ref={activeRef}
                    />
                    {children}
                </InputContainer>
                {errorMessage ? `*${errorMessage}` : ''}
            </React.Fragment>
        );
    },
);

export type { TextInputProps };
export { TextInput };
