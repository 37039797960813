import { DownloadRequest, Sensor, SigmaAPI } from 'oskcore';
import { AppDispatch } from '../store';
import {} from './data/app';
import { includePlatform } from './data/search';

/* Actions */
export const SET_SENSORS = 'SET_SENSORS';
export function setSensors(sensors: Array<Sensor>) {
    return {
        type: SET_SENSORS,
        payload: {
            sensors,
        },
    };
}

export const SET_DOWNLOADS = 'SET_DOWNLOADS';
export function setDownloads(downloadList: Array<DownloadRequest>) {
    return {
        type: SET_DOWNLOADS,
        payload: {
            downloadList,
        },
    };
}

export const CACHE_FILE = 'CACHE_FILE';
export function cacheFile(downloadRequest: DownloadRequest) {
    return {
        type: CACHE_FILE,
        payload: {
            downloadRequest,
        },
    };
}

export const SET_APP_INITIALIZING = 'SET_APP_INITIALIZING';
export function setAppInitializing(initializing: boolean) {
    return {
        type: SET_APP_INITIALIZING,
        payload: {
            initializing,
        },
    };
}

/* Async methods */

/** doPopulateAppAsync()
 *
 * This method will load all the initial data needed to render
 * the UI. This includes things like sensors and other
 * such immutable data.
 */
export function doPopulateAppAsync() {
    return (dispatch: AppDispatch) => {
        dispatch(setAppInitializing(true));

        // The following set of promises are intended to block the UI.
        // The UI will not render until all data in this section has been
        // fetched.
        SigmaAPI.listSensors({ limit: 10000 })
            .then((response) => {
                const { data } = response;
                const { results } = data;
                if (results) {
                    dispatch(setSensors(results));

                    // Select all sensors by default.
                    results.forEach((sensor) => dispatch(includePlatform(sensor.osk_id)));
                }
            })
            .finally(() => {
                dispatch(setAppInitializing(false));
            });

        // The following set of promises are intended to be executed
        // in the background and do not block the UI. Useful for teriary
        // supporting data.
    };
}

/* Reducer */
type OSKAppReducerType = {
    initializing: boolean;
    sensors: Array<Sensor>;
    /**
     * A cache of files that are either pending or
     * ready for download.
     */
    fileCache: Record<string, DownloadRequest>;
};
const initialState: OSKAppReducerType = {
    initializing: true,
    sensors: [],
    fileCache: {},
};

export default function reducer(state = initialState, action: any): OSKAppReducerType {
    switch (action.type) {
        case SET_SENSORS: {
            const { sensors } = action.payload;
            return {
                ...state,
                sensors,
            };
        }

        case SET_APP_INITIALIZING: {
            const { initializing } = action.payload;
            return {
                ...state,
                initializing,
            };
        }

        case CACHE_FILE: {
            const { downloadRequest } = action.payload as { downloadRequest: DownloadRequest };
            const updatedFileCache: Record<string, DownloadRequest> = {};

            for (const fileId of downloadRequest.requested_captures) {
                updatedFileCache[fileId] = downloadRequest;
            }

            return {
                ...state,
                fileCache: {
                    ...state.fileCache,
                    ...updatedFileCache,
                },
            };
        }

        case SET_DOWNLOADS: {
            const { downloadList } = action.payload as { downloadList: Array<DownloadRequest> };
            return {
                ...state,
                fileCache: {
                    ...state.fileCache,
                    ...downloadList.reduce((accumulator, fileItem) => {
                        for (const fileId of fileItem.requested_captures) {
                            accumulator[fileId] = fileItem;
                        }
                        return accumulator;
                    }, {} as Record<string, DownloadRequest>),
                },
            };
        }

        default:
            return { ...state };
    }
}
