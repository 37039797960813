import React from 'react';
import styled, { useTheme } from 'styled-components';
import { OSKLogoSolid } from '../assets/OSKLogo';
import { BoxProps } from '../Box';
import { GlobalZIndex } from '../constants';
import { OSKThemeType } from '../DefaultThemeProvider';
import { OSKIcon } from '../OSKIcon';

type HeaderProps = {
    className?: string;
    children?: React.ReactNode;
    logo?: React.SVGProps<SVGSVGElement>;
    onLogoClick?: () => void;
} & Omit<BoxProps, 'ref'>;

const CoreHeader = ({ className, children, logo = OSKLogoSolid, onLogoClick, ...props }: HeaderProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <div className={className} {...props}>
            <OSKIcon
                svg={logo}
                style={{ zIndex: GlobalZIndex.HeaderLogo }}
                onClick={onLogoClick}
                fill={theme.colors.primary.fg}
                height={30}
                width={75}
            />
            {children}
        </div>
    );
};

CoreHeader.defaultProps = {};

const Header = styled(CoreHeader)`
    display: flex;
    z-index: 1;
    flex-direction: row;
    flex-shrink: 1;
    align-items: center;
    background-color: ${(props: any) => props.theme.colors.primary.bg};
    color: ${(props: any) => props.theme.colors.primary.fg};
    padding: 6px ${(props: any) => props.theme.edgeContentPadding}px;
`;

export type { HeaderProps };
export { Header };
