import React from 'react';
import { noop } from 'lodash';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { Box, Heading, OSKIcon, Text } from 'oskcomponents';
import { OrderStatus } from '~/redux/modules/data/orders';

type OrderFailCardProps = {
    orderStatus: OrderStatus;
};

const OrderFailCard = ({ orderStatus }: OrderFailCardProps) => {
    const status = orderStatus ? orderStatus : 'none';

    if (status !== 'failed') {
        return <></>;
    }

    return (
        <Box center="all" col>
            <OSKIcon code="order-error" w={400} m={-40} />
            <Heading variant="large" m={6}>
                Unable to Complete Order
            </Heading>
            <Text variant="large" w={300} style={{ textAlign: 'center', marginBottom: '40px' }}>
                Your order cannot be completed at this time. Please order again.
            </Text>
        </Box>
    );
};

const mapStateToProps = (state: RootState): OrderFailCardProps => {
    return {
        orderStatus: state.data.orders.activeOrder?.status ?? 'none',
    };
};

export default connect(mapStateToProps, noop)(OrderFailCard);
