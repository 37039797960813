/**
    This component is designed to list Search Results that come back
    from the API. It is listed in the side panel on the Map view.
    Toggling an item will either enqueue or dequeue it from the cart.
*/

import { Box, OSKThemeType, Spinner, Text } from 'oskcomponents';
import { noop } from 'oskcore';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { CaptureGroup } from '~/molecules/CaptureGroup';
import CaptureItem from '~/molecules/CaptureItem';
import { RootState } from '~/redux/store';
import { CartGroupIcon, dequeueFiles, enqueueFilesById, getCollectIcon } from '~/redux/modules/data/cart';

type SearchDataCaptureGroupProps = {
    /** Current taskId to render this group for */
    taskId: string;
    /** The list of fileIds in the collect */
    fileIds: string[];
    /** The icon to show at the summary */
    icon: CartGroupIcon;
};

const SearchDataCaptureGroup = ({ taskId, fileIds, icon }: SearchDataCaptureGroupProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();

    return (
        <CaptureGroup
            onIconClick={() => {
                if (icon === 'all') {
                    dispatch(dequeueFiles(fileIds));
                } else {
                    dispatch(enqueueFilesById(fileIds));
                }
            }}
            defaultExpanded={false}
            mb={8}
            taskId={taskId}
            key={`task_${taskId}`}
            icon={icon}
        >
            {fileIds.map((fileId, idx) => (
                <CaptureItem
                    fileId={fileId}
                    key={`task_item_${fileId}`}
                    style={{
                        margin: '0',
                        backgroundColor: idx % 2 === 0 ? theme.colors.lightGray : theme.colors.white,
                    }}
                />
            ))}
        </CaptureGroup>
    );
};

const mapSearchDataCaptureGroupStateToProps = (state: RootState, ownProps: Partial<SearchDataCaptureGroupProps>) => {
    return {
        fileIds: state.data.search.collectToFileIdList[ownProps.taskId ?? '-1'],
        icon: getCollectIcon(state, ownProps.taskId ?? '-1'),
    };
};

const ConnectedSearchDataCaptureGroup = connect(mapSearchDataCaptureGroupStateToProps, noop)(SearchDataCaptureGroup);

export type SearchDataTaskPreviewProps = {
    /** List of all collects */
    taskIdList: string[];
    /** If true, we are waiting on the search API to return new results */
    isSearching?: boolean;
};

export const SearchDataTaskPreview = ({ isSearching, taskIdList }: SearchDataTaskPreviewProps) => {
    if (isSearching) {
        return (
            <Box grow center="all" style={{ height: '100%' }}>
                <Spinner size="Large" variant="Box" />
            </Box>
        );
    } else if (taskIdList.length === 0) {
        return (
            <Box>
                <Text>No images available for current parameters.</Text>
            </Box>
        );
    } else {
        return (
            <>
                {taskIdList.map((taskId) => (
                    <ConnectedSearchDataCaptureGroup key={`search_data_task_group_${taskId}`} taskId={taskId} />
                ))}
            </>
        );
    }
};

const mapStateToProps = (state: RootState) => {
    return {
        isSearching: state.data.search.isSearching,
        taskIdList: Object.keys(state.data.search.collectToFileIdList),
    };
};

export default connect(mapStateToProps, noop)(SearchDataTaskPreview);
