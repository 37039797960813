import React from 'react';
import styled, { useTheme } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Box, IconProps, OSKIcon, OSKIconType, OSKThemeType } from 'oskcomponents';

export type ModeButtonProps = {
    /** Whether this button can be clicked or not */
    disabled?: boolean;
    /** The icon to display inside the button */
    icon: OSKIconType;
    /** An additional theme to apply to the button container */
    className?: string;
    /** href for where to navigate to */
    location: string;
    /** Which mode the user is on */
    active: boolean;
    /** Pass onClick from parent */
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    /** A number to display on a badge next to the button */
    badge?: number;
} & IconProps;

/**
 * ActiveModeButton is a component which renders an icon in a circular button
 * with optional text beneath. It is styled using the variant system
 * and can optionally support mouse-hover effects.
 *
 * To use, simply pass an Icon component and implement onToggle.
 */
const ModeButton = styled(({ badge, className, location, icon, active, onClick, ...props }: ModeButtonProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <NavLink data-isactive={active} to={location} className={`${className}`} onClick={onClick}>
            {badge && badge > 0 ? (
                <Box style={{ position: 'relative' }}>
                    <Box
                        style={{
                            position: 'absolute',
                            top: '-26px',
                            left: '10px',
                            padding: '2px',
                            width: '25px',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '25px',
                            fontSize: '0.8rem',
                            background: theme.colors.accent2,
                            fontWeight: 'bold',
                            color: 'white',
                            borderRadius: '30px',
                        }}
                    >
                        {badge}
                    </Box>
                </Box>
            ) : (
                <></>
            )}
            <OSKIcon code={icon} fill={active ? theme.colors.primary.invertedFg : theme.colors.primary.fg} {...props} />
        </NavLink>
    );
})`
    margin-top: 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 40px;
    width: 40px;

    &[data-isactive='true'] {
        background: ${(props: any) => props.theme.colors.primary.accent};
    }

    &[data-isactive='false']:hover {
        background: ${(props: any) => props.theme.colors.primary.accent};
        svg {
            fill: ${(props: any) => props.theme.colors.primary.invertedFg};
        }
    }
`;

export { ModeButton };
