import { Box, OSKThemeType, Text } from 'oskcomponents';
import { Capture, DownloadIntent, fround, noop, OSKGeoJson } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { RootState } from '~/redux/store';
import { CartWizardStep, CartWizardTemplate } from '~/templates';
import CartCollectPreview from '~/organisms/CartCollectPreview';

import { getAllItems, getFullItemsByCollect } from '~/redux/modules/data/cart';

export type CheckoutSummaryProps = {
    intents?: DownloadIntent[];
    cartItems?: Record<string, Capture[]>;
} & CartWizardStep;

export const CheckoutSummary = ({ intents, cartItems, onWizardNext, onWizardClose }: CheckoutSummaryProps) => {
    const theme = useTheme() as OSKThemeType;
    const skipMap = (intents ?? []).reduce((acc, cur) => {
        if (cur) {
            acc[cur.fileId] = !!cur.skip;
        }
        return acc;
    }, {} as Record<string, boolean>);

    const totalImagery = (intents ?? []).filter((intent) => !intent.skip).length;
    const totalKm = Object.values(cartItems ?? {})
        .flatMap((capture) => capture)
        .filter((capture) => !skipMap[capture.id])
        .map((capture) => OSKGeoJson.fromAPIGeometry(capture.footprint))
        .map((footprint) => footprint.getApproximateArea())
        .reduce((acc, cur) => {
            return acc + cur;
        }, 0);

    return (
        <CartWizardTemplate
            buttons={[
                { variant: 'primary', inverted: true, label: 'Close', onClick: onWizardClose ?? noop },
                {
                    variant: 'action',
                    noborder: true,
                    label: 'Next',
                    onClick: onWizardNext ?? noop,
                },
            ]}
            style={{
                overflowY: 'hidden',
            }}
        >
            <Text variant="small">
                Approx. Surface Area: <span style={{ fontWeight: 'bold' }}>{fround(totalKm, 2)} km&sup2;</span>
            </Text>
            <Text variant="small">
                Total Images: <span style={{ fontWeight: 'bold' }}>{totalImagery}</span>
            </Text>
            <Box
                fg={theme.colors.black900}
                col
                style={{ overflowY: 'auto', height: '100%', alignItems: 'center', width: '100%' }}
            >
                <Text pt={14}>Please review your cart below.</Text>
                <Box grow style={{ maxWidth: '650px', width: '50%', paddingTop: '20px', paddingBottom: '20px' }} col>
                    <CartCollectPreview />
                </Box>
            </Box>
        </CartWizardTemplate>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        intents: getAllItems(state),
        cartItems: getFullItemsByCollect(state),
        footprints: state.data.map.footprints,
    };
};

export default connect(mapStateToProps, noop)(CheckoutSummary);
