import React from 'react';
import PromptWhenLeaving from '../PromptWhenLeaving';
import { connect, useDispatch } from 'react-redux';
import { noop } from 'oskcore';
import { emptyCart, getVisibleItems } from '~/redux/modules/data/cart';
import { RootState } from '~/redux/store';

type PromptWhenLeavingHomeViewProps = {
    /** From redux, the # of items in the cart. */
    cartLength: number;
};

const PromptWhenLeavingHomeView = ({ cartLength }: PromptWhenLeavingHomeViewProps) => {
    const dispatch = useDispatch();
    return (
        <PromptWhenLeaving
            shouldBlock={() => cartLength > 0}
            onProceed={() => dispatch(emptyCart())}
            title="Clear Cart?"
            body="Your cart will be emptied. Do you want to proceed?"
            cancelLabel="Cancel"
            proceedLabel="Clear Cart and Proceed"
        />
    );
};

function mapStateToProps(state: RootState) {
    return {
        cartLength: getVisibleItems(state).length,
    };
}

export default connect(mapStateToProps, noop)(PromptWhenLeavingHomeView);
