import { ControlPosition } from 'leaflet';
import { decodeOSKPosition } from 'oskcore';
import React from 'react';
import { MapContainer, MapContainerProps, ScaleControl, useMapEvents, ZoomControl } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { useToggles } from '~/hooks/useToggles';
import './map.css';
import { getGoogleApiKey } from '~/utils';

type MapEventShimProps = {
    onMove?: any;
    onZoom?: any;
    onLoad?: any;
    onLoading?: any;
};

/**
 * This component will listen to various events and surface them as props
 * to crease a nice interface for the OSKMap component.
 */
const MapEventShim = ({ onMove, onZoom, onLoad, onLoading }: MapEventShimProps) => {
    useMapEvents({
        // NOTE: these things cannot be null or undefined
        // otherwise the map will throw exceptions.
        move(e) {
            onMove && onMove(e);
        },

        zoom(e) {
            onZoom && onZoom(e);
        },
        loading(e) {
            onLoading && onLoading(e);
        },
        load(e) {
            onLoad && onLoad(e);
        },
        mousemove(e) {},
    });

    return null;
};

type MapProps = {
    /** Additional children components to render inside the map */
    children?: React.ReactNode;
    /** Whether the map should be in edit mode or not */
    editable?: boolean;
    /** An OSK encoded position vector */
    position?: string;
    /** Layers to render */
    layers?: L.Layer[];
    /** The location of the zoom controls */
    zoomPosition?: ControlPosition;
} & MapContainerProps &
    MapEventShimProps;

/***
 * Map is a LeafletMap which, by default, renders google maps underneath.
 **/
const Map = ({ children, editable, position, layers, zoomPosition = 'topright', ...props }: MapProps) => {
    const oskPosition = decodeOSKPosition(position);
    const toggles = useToggles();

    // Hide maps if the relevant feature flags are set
    if (toggles.hideMaps()) {
        return <></>;
    }

    const googleApiKey = getGoogleApiKey();

    return (
        <MapContainer
            editable={editable}
            style={{ flexGrow: 1 }}
            center={oskPosition.coords}
            zoom={oskPosition.zoom}
            zoomControl={false}
            scrollWheelZoom={true}
            layers={layers}
            {...props}
        >
            {
                /* @ts-ignore */
                <ReactLeafletGoogleLayer apiKey={googleApiKey} type={'satellite'} />
            }
            <ScaleControl position="bottomright" />
            <ZoomControl position={zoomPosition} />
            <MapEventShim {...props} />
            {children}
        </MapContainer>
    );
};

export type { MapProps };
export { Map };
