import React, { useEffect, useRef, useState } from 'react';
import { Box, OSKThemeType, RatioLayout, RatioPanel } from 'oskcomponents';
import { connect } from 'react-redux';
import { noop } from 'oskcore';
import { useTheme } from 'styled-components';
import { ActionBar } from '~/atoms/ActionBar';
import { Breadcrumb } from '~/atoms';
import DateRangePicker from '~/molecules/DateRangePicker';
import Filters from './Filters';
import Results from './Results';
import { FindingFilter } from './findingUtils';
import { IconHeader } from '~/molecules/Monitor';
import { getProgramId } from '~/utils';
import { useNavigate } from 'react-router-dom';

// Constants used with param serialization/deserialization
const ALERTS_ONLY_FIELD = 'alertsOnly';
const ASSETS_FIELD = 'assets';
const DETECTIONS_FIELD = 'detectionType';

function deserializeUrlToFilter(): FindingFilter {
    const searchParams = new URLSearchParams(window.location.search);
    const alertsOnly = searchParams.get(ALERTS_ONLY_FIELD) === 'true';
    const detections: string[] = [];
    const assets: number[] = [];

    const assetList = (searchParams.get(ASSETS_FIELD) ?? '').split(',');
    for (const asset of assetList) {
        if (asset.length > 0) {
            assets.push(parseInt(asset));
        }
    }

    const detectionList = (searchParams.get(DETECTIONS_FIELD) ?? '').split(',');
    for (const detection of detectionList) {
        if (detection.length > 0) {
            detections.push(detection);
        }
    }

    return {
        alertsOnly,
        assets,
        detections,
    };
}

function serializeFilterToSearchParam(filter: FindingFilter): string {
    const { alertsOnly, assets, detections } = filter;
    return `${ALERTS_ONLY_FIELD}=${alertsOnly}&${ASSETS_FIELD}=${assets.join(
        ',',
    )}&${DETECTIONS_FIELD}=${detections.join(',')}`;
}

const ProgramFindingsGrid = () => {
    const [activeFilter, setActiveFilter] = useState<FindingFilter>(deserializeUrlToFilter());

    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const theme = useTheme() as OSKThemeType;
    const [loading, setLoading] = useState<boolean>(false);
    const [filtersVisible, setFiltersVisible] = useState<boolean>(true);
    const [totalItems, setTotalItems] = useState<number | undefined>(undefined);

    useEffect(() => {
        navigate(`/program/${getProgramId()}/monitor/findings?${serializeFilterToSearchParam(activeFilter)}`, {
            replace: true,
        });
    }, [activeFilter, navigate]);

    return (
        <Box data-testid="Alerts-Grid" col grow>
            <ActionBar left={<Breadcrumb nodes={[{ title: 'Findings' }]} />} right={<DateRangePicker />} />
            <Box
                ref={ref}
                style={{
                    height: '100%',
                    overflowY: 'scroll',
                    placeContent: 'flex-start',
                }}
                grow
            >
                <RatioLayout m={14} grow>
                    <RatioPanel
                        style={{ borderRadius: '30px', margin: '0px 9px' }}
                        bg={theme.colors.primary.transBg}
                        data-testid="Assets-Section"
                        pl={24}
                        pr={24}
                        pt={24}
                        weight={2}
                    >
                        <Box
                            center="vertical"
                            style={{ paddingBottom: '14px', borderBottom: `1px solid ${theme.colors.white2a}` }}
                        >
                            <IconHeader title="Findings" iconCode="alert" />
                        </Box>
                        <Box style={{ overflowY: 'hidden' }} grow>
                            <Box
                                style={{
                                    position: 'relative',
                                    left: filtersVisible ? '0px' : '-300px',
                                    width: filtersVisible ? '300px' : '0px',
                                    borderRight: filtersVisible ? `1px solid ${theme.colors.white2a}` : '',
                                    transition: 'width .25s, left .25s, position .25s',
                                }}
                            >
                                <Box style={{ minWidth: '300px' }} mb={14} pt={24} p={10}>
                                    <Filters
                                        defaultFilter={activeFilter}
                                        onChange={(filter) => {
                                            setActiveFilter(filter);
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    overflow: 'hidden',
                                    paddingBottom: '0px',
                                }}
                                grow
                                p={12}
                                col
                            >
                                <Results
                                    setFilterPanelVisibility={(visible) => {
                                        setFiltersVisible(visible);
                                    }}
                                    filter={activeFilter}
                                />
                            </Box>
                        </Box>
                    </RatioPanel>
                </RatioLayout>
            </Box>
        </Box>
    );
};

export { ProgramFindingsGrid };
export default connect(noop, noop)(ProgramFindingsGrid);
