import { Box, Button, Heading, Spinner, Table, TableColumn, Text } from 'oskcomponents';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SigmaOrder } from '~/redux/modules/data/orders';
import { date_format } from '~/utils';

type TaskingOrderTableProps = {
    /** The data that will go in the table. */
    data?: SigmaOrder[];
    /** Method to invoke when a row is clicked */
    onRowClick?: (asset: Record<string, any>) => void;
    /** The row to highlight (if applicable) */
    selectedIndex?: number;
    /** Whether to show the spinner or not */
    loading?: boolean;
    /** Children nodes to render underneath the table */
    children?: React.ReactNode | JSX.Element | Array<React.ReactNode | JSX.Element>;
};

// @ts-ignore
const StyledTable = styled(Table)`
    tbody tr {
        background-color: transparent !important;
        border-bottom: 1px solid ${(props) => props.theme.colors.black200} !important;
    }
`;

const TaskingOrderTable = ({ data, onRowClick, selectedIndex, children, loading }: TaskingOrderTableProps) => {
    const navigate = useNavigate();

    const columns = React.useMemo<Array<TableColumn>>(
        () => [
            { Header: 'ID', accessor: 'id', disableSortBy: true },
            {
                Header: 'Start Collect Date',
                accessor: 'start_collect_date_time',
                disableSortBy: true,
                Cell: ({ value }: any) => <Text>{date_format(value)}</Text>,
            },
            {
                Header: 'End Collect Date',
                accessor: 'end_collect_date_time',
                disableSortBy: true,
                Cell: ({ value }: any) => <Text>{date_format(value)}</Text>,
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableSortBy: true,
                Cell: ({ value }: any) => {
                    if (value) {
                        const v = value.toLowerCase();
                        const formatMap: { [key: string]: string[] } = {
                            // Display Name / Background / Foreground
                            none: ['', '', ''],
                            complete: ['Complete', '#00F31854', '#017C0A'],
                            pending: ['In Progress', '#0018F354', '#001D85'],
                            failed: ['Failed', '#FF706A36', '#DF0000'],
                        };

                        return (
                            <Box
                                style={{
                                    backgroundColor: formatMap[v][1],
                                    color: formatMap[v][2],
                                    padding: '4px 8px',
                                    borderRadius: '5px',
                                    width: 'fit-content',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <Text strong variant="small">
                                    {formatMap[v][0]}
                                </Text>
                            </Box>
                        );
                    } else {
                        return <Text>N/A</Text>;
                    }
                },
            },
        ],
        [],
    );

    if (!data) {
        return <></>;
    }

    if (data.length === 0) {
        return (
            <Box col center="all">
                <Heading>No tasks yet.</Heading>
                <Button
                    m={12}
                    variant="action"
                    onClick={() => {
                        navigate('/tasking');
                    }}
                >
                    Place Order
                </Button>
            </Box>
        );
    }

    return (
        <Box col style={{ height: '100%' }}>
            {loading ? (
                <Box grow center="all">
                    <Spinner variant="Box" size="Large" />
                </Box>
            ) : (
                <Box grow style={{ overflow: 'scroll', flex: '1 0px' }}>
                    <StyledTable
                        inverted
                        selectedIndex={selectedIndex}
                        onRowClick={onRowClick}
                        columns={columns}
                        data={data}
                        style={{ height: 'fit-content' }}
                    />
                </Box>
            )}
            {children}
        </Box>
    );
};

export default TaskingOrderTable;
