import React, { useEffect } from 'react';
import { noop, Program } from 'oskcore';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { useNavigate } from 'react-router-dom';
import { Box, Text } from 'oskcomponents';

type CurrentProgramRedirectProps = {
    /** The id of the currently active program, received from redux. */
    activeProgramId?: number;
    /** The list of programs this user is part of, received from redux */
    programs?: Array<Program>;
};
const CurrentProgramRedirect = ({ activeProgramId, programs }: CurrentProgramRedirectProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (activeProgramId && programs && programs.length > 0) {
            navigate(`/program/${activeProgramId}`, { replace: true });
        }
    }, [navigate, activeProgramId]);

    if (programs!.length === 0) {
        return (
            <Box grow center="all" col>
                <Text variant="large" p={12} strong>
                    No program selected.
                </Text>
                <Text variant="small">If you are not part of a program, contact your program administrator.</Text>
            </Box>
        );
    }

    return <React.Fragment></React.Fragment>;
};

const mapStateToProps = (state: RootState) => {
    return {
        activeProgramId: state.session.program_id,
        programs: state.session.programs,
    };
};

export default connect(mapStateToProps, noop)(CurrentProgramRedirect);
