import React, { useRef } from 'react';
import { NavItem } from 'oskcore';
import { IconButton } from '~/atoms';
import { RiGridFill } from 'react-icons/ri';
import { Box, OSKThemeType, Overlay, useClickAway } from 'oskcomponents';
import { Link } from 'react-router-dom';
import { FaSatellite, FaSearch, FaUser } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '~/redux/store';
import { NavItemEntry, showNavItem } from '~/redux/modules/data/nav';
import { GlobalZIndex } from '~/constants';
import { useToggles } from '~/hooks/useToggles';

export type AppListProps = {
    show: boolean;
    setShow: (show: boolean) => void;
    onNavigate?: () => void;
};

/**
 * AppNavItem is a button that pops-up an overlay
 * listing all the applications supported within
 * Sigma.
 */
export const AppList = ({ show, setShow, onNavigate }: AppListProps) => {
    const ref = useRef(null);
    const theme = useTheme() as OSKThemeType;
    useClickAway(ref, () => setShow(false), show);
    const toggles = useToggles();

    const defaultStyle = {
        padding: '4px',
    };

    const toggledStyle = {
        backgroundColor: theme.colors.primary.accent,
        borderRadius: '5px',
        borderColor: theme.colors.primary.border,
    };

    return (
        <Box ref={ref} style={{ zIndex: GlobalZIndex.AppList }}>
            <NavItem>
                <IconButton
                    p={0}
                    iconSize={theme.navIconSize}
                    icon={RiGridFill}
                    onToggle={() => {
                        setShow(!show);
                    }}
                    style={show ? { ...defaultStyle, ...toggledStyle } : defaultStyle}
                    variant="action"
                    selected={show}
                    hoverable
                />
                <Box style={{ position: 'absolute', top: '55px', right: 0 }}>
                    <Overlay p={10} show={show} style={{ width: '250px', height: '250px', right: '0' }} col>
                        <Box
                            p={14}
                            style={{
                                border: `1px solid ${theme.colors.primary.border}`,
                                borderRadius: '10px',
                                backdropFilter: 'blur(10px)',
                            }}
                            bg={theme.colors.primary.halfTransBg}
                            grow
                            col
                        >
                            <Box grow style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                {
                                    /* TODO: remove this condition eventually */
                                    toggles.showSigmaData() && (
                                        <Link to="/map" style={{ width: '50%' }} onClick={onNavigate}>
                                            <IconButton
                                                iconSize={theme.largeIconSize}
                                                icon={FaSearch}
                                                label="Data"
                                                variant="action"
                                                hoverable
                                            />
                                        </Link>
                                    )
                                }

                                <Link to="/monitor" style={{ width: '50%' }} onClick={onNavigate}>
                                    <IconButton
                                        iconSize={theme.largeIconSize}
                                        icon={FaSatellite}
                                        label="Monitor"
                                        variant="action"
                                        hoverable
                                    />
                                </Link>
                                {toggles.showOSKAdminFeatures() && (
                                    <Link to="/admin/view_programs" style={{ width: '50%' }} onClick={onNavigate}>
                                        <IconButton
                                            iconSize={theme.largeIconSize}
                                            icon={FaUser}
                                            label="Admin"
                                            variant="action"
                                            hoverable
                                        />
                                    </Link>
                                )}
                            </Box>
                        </Box>
                    </Overlay>
                </Box>
            </NavItem>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        show: state.data.nav.showAppList,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setShow: (show: boolean) => {
            dispatch(showNavItem(show ? NavItemEntry.AppList : NavItemEntry.None));
        },
        onNavigate: () => {
            // Close all nav items
            dispatch(showNavItem(NavItemEntry.None));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppList);
