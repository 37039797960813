import React, { useCallback } from 'react';
import { OSKView, fround, NavComponent } from 'oskcore';
import { useNavigate, useParams } from 'react-router-dom';
import { LeafletEvent, Map } from 'leaflet';
import { Box } from 'oskcomponents';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';
import { useSessionStorage } from '~/hooks';
import AppShimmerBar from '~/organisms/AppShimmerBar';
import ActiveModeSwitcher from '~/organisms/ActiveModeSwitcher';
import EditableMap from '~/organisms/map/EditableMap';
import AoiTools from '~/organisms/map/AoiTools';
import TaskingAoiTools from '~/organisms/map/TaskingAoiTools';
import { debounce } from 'lodash';
import SearchSidePanel from '~/organisms/SearchSidePanel';
import CartButton from '~/organisms/CartButton';
import CartWizardPopup from '~/organisms/CartWizardPopup';
import FootprintList from '~/organisms/map/FootprintList';
import TaskingSidePanel from '~/organisms/TaskingSidePanel';
import { useDispatch } from 'react-redux';
import { emptyCart } from '~/redux/modules/data/cart';
import PromptWhenLeavingHomeView from '~/atoms/PromptWhenLeavingHomeView';

export type HomeViewMode = 'tasking' | 'search';

export type HomeViewProps = {
    /** The mode we are rendering for. Side panel will be different for tasking vs. search */
    mode: HomeViewMode;
};

const HomeView = ({ mode }: HomeViewProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { position } = useParams() as any;
    const [, setSessionStorage] = useSessionStorage();

    const saveMapCoordinates = useCallback(
        (evt: LeafletEvent) => {
            const map = evt.target as Map;
            const { lat, lng } = map.getCenter();
            const froundLat = fround(lat, 3);
            const froundLng = fround(lng, 3);
            const zoom = map.getZoom();

            setSessionStorage('map', { coords: [froundLat, froundLng], zoom: zoom });
            switch (mode) {
                case 'search': {
                    navigate(`/map/@${froundLat},${froundLng},${zoom}/`, { replace: true });
                    break;
                }
                case 'tasking': {
                    navigate(`/tasking/@${froundLat},${froundLng},${zoom}/`, { replace: true });
                    break;
                }
            }
        },
        [navigate],
    );

    return (
        <OSKView constrained nopadding nogradient>
            <AppShimmerBar />
            {mode === 'search' && <PromptWhenLeavingHomeView />}
            <Box style={{ flexGrow: 1 }}>
                <ActiveModeSwitcher
                    onProceed={() => {
                        dispatch(emptyCart());
                    }}
                />
                <NavComponent>
                    <Box style={{ paddingLeft: '10px' }}>{mode === 'search' && <CartButton />}</Box>
                    <SigmaNavItems />
                </NavComponent>
                <EditableMap position={position} onMove={debounce(saveMapCoordinates, 300)} zoomPosition="bottomright">
                    {(() => {
                        switch (mode) {
                            case 'search':
                                return (
                                    <>
                                        <SearchSidePanel />
                                        <FootprintList />
                                        <AoiTools />
                                    </>
                                );
                            case 'tasking':
                                return (
                                    <>
                                        <TaskingSidePanel />
                                        <TaskingAoiTools />
                                    </>
                                );
                        }
                    })()}

                    <CartWizardPopup />
                </EditableMap>
            </Box>
        </OSKView>
    );
};

export default HomeView;
