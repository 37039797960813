import { noop } from 'oskcore';
import { Box, OSKThemeType, RatioPanel, RatioLayout, Spinner } from 'oskcomponents';
import React from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { OSKContentView } from '~/molecules/OSKContentView';
import OrderSummary from '~/organisms/OrderSummary';
import OrderListPanel from '~/organisms/OrderListPanel';

import { DataResourceProvider } from '~/redux';
import { getProgramId } from '~/utils';
import { RootState } from '~/redux/store';
import { selectOrderDashboardReady } from '~/redux/modules/data/orders';

type OrderDashboardViewProps = {
    isLoading: boolean;
};

const OrderDashboardView = ({ isLoading }: OrderDashboardViewProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <OSKContentView nogradient>
            <DataResourceProvider
                features={['tasking_orders', 'archive_orders']}
                programId={getProgramId()}
                orderPage={0}
            >
                <Box style={{ width: '100%', height: '100%' }} bg={theme.colors.white} grow>
                    {isLoading && (
                        <Box grow style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 999 }}>
                            <Box
                                grow
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                center="all"
                                bg={theme.colors.black200}
                            >
                                <Spinner variant="Box" size="Large" />
                            </Box>
                        </Box>
                    )}
                    <RatioLayout>
                        <RatioPanel weight={2} grow p={14}>
                            <Box
                                bg={theme.colors.white}
                                fg={theme.colors.black}
                                style={{ width: '100%', height: '100%', position: 'relative' }}
                                grow
                                col
                            >
                                <OrderListPanel />
                            </Box>
                        </RatioPanel>
                        <RatioPanel style={{ outline: `1px solid ${theme.colors.black600}` }} weight={3}>
                            <Box
                                bg={theme.colors.whiteAccentBackground}
                                p={40}
                                fg={theme.colors.black}
                                style={{ width: '100%', height: '100%' }}
                                grow
                            >
                                <OrderSummary />
                            </Box>
                        </RatioPanel>
                    </RatioLayout>
                </Box>
            </DataResourceProvider>
        </OSKContentView>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: !selectOrderDashboardReady(state),
    };
};
export default connect(mapStateToProps, noop)(OrderDashboardView);
