import * as React from 'react';
import styled from 'styled-components';
import { ColorVariants } from '../DefaultThemeProvider';

type ButtonProps = {
    /** Background color */
    bg?: string;
    /** Foreground color */
    fg?: string;
    /** ClassName generated by styled-components */
    className?: string;
    /** The text label for this button */
    label?: string;
    /** The variant of this button */
    variant?: ColorVariants;
    /** Amount of padding */
    p?: number;
    /** Amount of margin */
    m?: number;
    /** Width */
    w?: number;
    /** Font size */
    fs?: string;
    /** Children components to be rendered inside the button */
    children?: React.ReactNode;
    /** Override the border */
    border?: string;
    /** If true, this button will submit forms on click */
    submit?: boolean;
    /** If true, disable special hover styling */
    nohover?: boolean;
    /** If true, there will be no border rendered */
    noborder?: boolean;
    /** If true, there will be no border and no background */
    flat?: boolean;
    /** If true, the button will have a border */
    hollow?: boolean;
    /** If true, the inverted attributes will be used instead */
    inverted?: boolean;
} & (JSX.IntrinsicElements['input'] | JSX.IntrinsicElements['button']);

const CoreButton = React.forwardRef<HTMLButtonElement & HTMLInputElement, ButtonProps>(
    (
        {
            bg,
            className,
            noborder,
            nohover,
            children,
            flat,
            inverted,
            p,
            m,
            fs,
            submit,
            label,
            hollow,
            ...props
        }: ButtonProps,
        ref,
    ) => {
        if (submit) {
            return (
                <input
                    ref={ref}
                    role="button"
                    type="submit"
                    value={label}
                    className={className}
                    {...(props as JSX.IntrinsicElements['input'])}
                />
            );
        } else {
            return (
                <button
                    ref={ref}
                    role="button"
                    type="button"
                    className={className}
                    {...(props as JSX.IntrinsicElements['button'])}
                >
                    {label}
                    {children}
                </button>
            );
        }
    },
);

const Button = styled(CoreButton)`
    outline: 1px solid
        ${(props: any) => (props.hollow ? props.theme.colors[props.variant].buttonBorder : 'transparent')};
    border: none;
    border-radius: 10px;
    margin: ${(props: any) => (props.m ? props.m + 'px' : '')};
    font-size: ${(props: any) => props.fs ?? props.theme.text.size};
    font-family: ${(props: any) => props.theme.font};
    padding: ${(props: any) => props.p ?? props.theme.input.padding} ${(props: any) => props.p ?? '24px'};
    background-color: ${(props: any) =>
        props.flat
            ? '#ffffff00'
            : props.disabled
            ? props.theme.colors.disabled.bg
            : props.bg ?? props.inverted
            ? props.theme.colors[props.variant].invertedBg
            : props.theme.colors[props.variant].bg};
    color: ${(props: any) =>
        props.disabled
            ? props.theme.colors.disabled.fg
            : props.fg ?? props.inverted
            ? props.theme.colors[props.variant].invertedFg
            : props.theme.colors[props.variant].fg};
    cursor: ${(props: any) => (props.disabled ? 'default' : 'pointer')};
    width: ${(props: any) => (props.w ? props.w + 'px' : 'fit-content')};

    :hover {
        transition: background-color 0.4s, outline 0.4s, color 0.4s;
        outline: 1px solid
            ${(props: any) => (props.hollow ? props.theme.colors[props.variant].buttonBorder : 'transparent')};
        background-color: ${(props: any) =>
            props.nohover
                ? props.theme.colors[props.variant].bg
                : props.disabled
                ? props.theme.colors.disabled.bg
                : props.inverted
                ? props.theme.colors[props.variant].bg
                : props.theme.colors[props.variant].invertedBg};
        color: ${(props: any) =>
            props.disabled
                ? props.theme.colors.disabled.fg
                : props.nohover
                ? props.theme.colors[props.variant].fg
                : props.fg ?? props.inverted
                ? props.theme.colors[props.variant].fg
                : props.theme.colors[props.variant].invertedFg};
        outline: none;
    }

    :focus {
        outline: none;
    }
`;

Button.defaultProps = {
    variant: 'primary',
};

export type { ButtonProps };
export { Button };
