import { Box, Heading, OSKThemeType, Spinner, Text } from 'oskcomponents';
import { fround, noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { SigmaOrderDetails } from '~/redux/modules/data/orders';
import { RootState } from '~/redux/store';
import { SummaryTabSection } from './SummaryTabSection';

export type SummaryTabProps = {
    orderLoading?: boolean;
    activeOrder?: SigmaOrderDetails;
};

export const SummaryTab = ({ activeOrder, orderLoading }: SummaryTabProps) => {
    const theme = useTheme() as OSKThemeType;

    const totalKm = 200;
    const totalImagery = activeOrder?.captures?.length ?? 0;
    const bucket = activeOrder?.bucket ?? 'None';
    const dataLevel = activeOrder?.data_level ?? 'None';
    const fileType = activeOrder?.delivery_type ?? 'None';

    if (orderLoading) {
        return (
            <Box center="all" grow style={{ width: '100%', height: '100%' }}>
                <Spinner size="Large" variant="Box" />
            </Box>
        );
    } else {
        return (
            <Box col>
                <Heading>Summary</Heading>
                <Box
                    style={{
                        marginTop: '20px',
                        paddingBottom: '20px',
                        borderTop: `2px solid ${theme.colors.gray50}`,
                        borderBottom: `2px solid ${theme.colors.gray50}`,
                    }}
                    col
                >
                    <SummaryTabSection style={{ borderTop: '0', marginTop: '' }}>
                        <Box>
                            <Text style={{ flexGrow: 1 }}>Total Imagery</Text>
                            <Text w={100}>{totalImagery}</Text>
                            <Box grow />
                        </Box>
                        <Box>
                            <Text style={{ flexGrow: 1 }}>Total Area</Text>
                            <Text w={100}>{fround(totalKm, 2)} km&sup2;</Text>
                            <Box grow />
                        </Box>
                    </SummaryTabSection>
                    <SummaryTabSection>
                        <Box center="vertical" col>
                            <Text style={{ fontWeight: 'bold', flexGrow: 1 }}>Destination Bucket</Text>
                            <Text w={100}>{bucket}</Text>
                        </Box>
                    </SummaryTabSection>
                    <SummaryTabSection>
                        <Box center="vertical">
                            <Text style={{ fontWeight: 'bold', flexGrow: 1 }}>Data Level</Text>
                            <Text w={100}>{dataLevel}</Text>
                            <Box grow />
                        </Box>
                    </SummaryTabSection>
                    <SummaryTabSection>
                        <Box center="vertical">
                            <Text style={{ fontWeight: 'bold', flexGrow: 1 }}>File Type</Text>
                            <Text w={100}>{fileType}</Text>
                            <Box grow />
                        </Box>
                    </SummaryTabSection>
                </Box>
            </Box>
        );
    }
};

const mapStateToProps = (state: RootState): Partial<SummaryTabProps> => {
    return {
        activeOrder: state.data.orders.activeOrder,
        orderLoading: state.data.orders.loadingSingleOrder,
    };
};

export default connect(mapStateToProps, noop)(SummaryTab);
