import React, { useEffect, useState, useCallback, ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

import CSS from 'csstype';
import { FaCaretDown } from 'react-icons/fa';
import { ColorVariants, OSKThemeType } from '../DefaultThemeProvider';
import { List } from '../List';
import { Box, BoxProps } from '../Box';
import { Text } from '../Typography';
import type { ListEntry } from '../List';
import { Overlay } from '../Overlay';

export const SelectionContext = React.createContext({ onClick: (options: any) => {} });

type SelectProps = {
    /** The name of the component */
    name: string;
    /** Maximum width of the select component */
    w?: number;
    /** Children components to be rendered with the option list */
    children?: React.ReactNode;
    /** The default color to render the placeholder/selected text */
    color?: string;
    /** If true, the input will not be changeable */
    disabled?: boolean;
    /** Label text */
    placeholder?: string;
    /** The default selected value */
    defaultValue?: any;
    /** If specified, this will override whatever value is displayed */
    value?: string | ReactNode;
    /** An array of options to populate the list with */
    items: Array<ListEntry>;
    /** If true, the placeholder will be duplicated as an option. When selected, the value will be null */
    allowNull?: boolean;
    /** If true, the background and foreground colors will be inverted */
    inverted?: boolean;
    /** A method to be invoked when an option is selected */
    onSelect?: (option?: ListEntry) => void;
    /** The style to apply to the main input element */
    style?: CSS.Properties;
    /** The variant theme with which to apply */
    variant?: ColorVariants;
} & Omit<Omit<BoxProps, 'ref'>, 'onSelect'>;

const Select = styled(
    ({
        allowNull,
        children,
        color,
        disabled,
        placeholder,
        name,
        w,
        defaultValue,
        value,
        inverted,
        items,
        onSelect,
        style,
        variant,
        ...props
    }: SelectProps) => {
        const [open, setOpen] = useState<boolean>(false);
        const [selectedItem, setSelectedItem] = useState<any>({ placeholder, value: undefined });
        const theme = useTheme() as OSKThemeType;

        /**
         * This method will handle keyboard events captured while the Select component
         * is open. Such events are used to control focus and monitor for submit/cancel intents.
         *
         * @param evt The keyboard event which is passed from the browser window
         */
        const handleKeyDown = (evt: KeyboardEvent) => {
            evt.preventDefault();
            evt.stopPropagation();
            if (evt.code === 'Escape') {
                setOpen(false);
            }
        };

        /** This hook registers the keydown event handler when the dropdown is open */
        useEffect(() => {
            if (open) window.addEventListener('keydown', handleKeyDown);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        });

        /** This method will toggle the popup list */
        const toggleOptions = (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            setOpen(!open);
        };

        /** This hook is the callback method for handling the selection of an element. It saves the selection and closes the popup. */
        const handleSelect = useCallback(
            (option?: ListEntry, silent = false) => {
                if (!option || selectedItem.value !== option.value) {
                    setOpen(false);
                    setSelectedItem(option);
                    if (!silent) {
                        onSelect && onSelect(option);
                    }
                }
            },
            [onSelect, setOpen, setSelectedItem],
        );

        useEffect(() => {
            if (defaultValue) {
                for (const option of items) {
                    if (option.value === defaultValue) {
                        handleSelect(option, true);
                        break;
                    }
                }
            }
        }, [items]);

        const isUnset = (selectedItem.value ?? '') === '';
        const fg = inverted ? theme.colors[variant ?? 'primary'].invertedFg : theme.colors[variant ?? 'primary'].fg;

        return (
            <Box role={'select'} w={w} onClick={toggleOptions} {...props} col>
                {/* This input is a focus-trap. Used to capture tab events.
                NOTE: This trick /may/ introduce unwanted values in form submissions.
                If you see such things, we'll have to rethink this approach.
            */}
                <input
                    style={{ position: 'absolute', top: '-100px' }}
                    onFocus={() => {
                        setOpen(true);
                    }}
                />
                <Box w={w} p={6} style={{ alignItems: 'center', justifyContent: 'center' }} grow>
                    <Box style={style} grow>
                        <Text as="div" color={color ?? fg}>
                            {isUnset ? placeholder : value ?? selectedItem.label}
                        </Text>
                    </Box>
                    <Text color={color ?? fg}>
                        <FaCaretDown />
                    </Text>
                </Box>
                {!disabled && (
                    <Overlay
                        animation="fadeIn"
                        style={{
                            width: '100%',
                            paddingBottom: '50px',
                            paddingTop: '8px',
                        }}
                        show={open}
                    >
                        {open && (
                            <List
                                variant={variant}
                                inverted={inverted}
                                style={{
                                    width: '100%',
                                    outline: `1px solid ${theme.colors[variant ?? 'primary'].border}`,
                                    borderRadius: '9px',
                                    overflow: 'hidden',
                                }}
                                itemStyle={{ padding: '10px' }}
                                items={items}
                                onCancel={() => setOpen(false)}
                                onSelect={handleSelect}
                            />
                        )}
                    </Overlay>
                )}

                <input name={name} type="hidden" value={selectedItem.value ?? ''} />
            </Box>
        );
    },
)`
    cursor: pointer;
    background-color: ${(props: any) =>
        props.variant
            ? props.inverted
                ? props.theme.colors[props.variant].invertedBg
                : props.theme.colors[props.variant].bg
            : props.theme.colors.primary.bg};
    padding: 4px;
    border-radius: 9px;
    outline: 1px solid
        ${(props: any) =>
            props.variant ? props.theme.colors[props.variant].border : props.theme.colors.primary.border};
`;

export type { SelectProps };
export { Select };
