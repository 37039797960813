import React from 'react';
import { useTheme } from 'styled-components';
import { OSKView, NavComponent } from 'oskcore';
import { PortalTemplate, ViewItem } from '~/templates/PortalTemplate';
import { OSKThemeType } from 'oskcomponents';
import { useParams } from 'react-router-dom';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';
import { ProfileDashboard } from './ProfileDashboard';
import CurrentProgramRedirect from './CurrentProgramRedirect';
import OSKDeveloperTools from './OSKDeveloperTools';
import { useToggles } from '~/hooks/useToggles';

const UserSettingsPortal = () => {
    const { view } = useParams() as any;
    const theme = useTheme() as OSKThemeType;
    const toggles = useToggles();

    const adminPortalOptions: Array<ViewItem> = [
        {
            title: 'Settings',
            iconCode: 'gear',
            subItems: [
                { title: 'Profile', component: <ProfileDashboard />, iconCode: 'user' },
                // { title: 'App', component: <AppSettingsDashboard />, iconCode: 'list' },
                { title: 'Current Program', component: <CurrentProgramRedirect />, iconCode: 'edit' },
                {
                    title: 'Developer Tools',
                    component: <OSKDeveloperTools />,
                    iconCode: 'gear',
                    visible: toggles.showOSKAdminFeatures(),
                },
            ],
        },
    ];

    return (
        <OSKView
            nopadding
            headerStyle={{
                boxShadow: theme.boxShadow,
            }}
            style={{
                backgroundColor: theme.colors.primary.bg,
            }}
            nogradient
        >
            <NavComponent>
                <SigmaNavItems />
            </NavComponent>
            <PortalTemplate basePath="/settings" defaultViewItem={view} viewItems={adminPortalOptions} />
        </OSKView>
    );
};

export { UserSettingsPortal };
