import React, { useState } from 'react';
import { Box, Button, Form, PageScaffold, Heading, OSKIcon } from 'oskcomponents';
import { ProfileForm, ProfileSchema } from '~/organisms/forms/ProfileForm';
import { useProfileMetadata } from 'oskcore/src/hooks/useProfileMetadata';
import { AdminAPI, User } from 'oskcore';
import { PortalContentView } from '~/atoms';

export const ProfileDashboard = () => {
    const [profile, setProfile] = useProfileMetadata();
    const [editMode, setEditMode] = useState<boolean>(false);

    const handleSubmit = (data: any) => {
        if (profile?.id) {
            setEditMode(false);
            AdminAPI.updateProfile({ userRequest: data as User });
            setProfile({
                ...profile,
                ...data,
            });
        }
    };

    return (
        <PortalContentView crumbs={[{ title: 'Settings', url: '/settings' }, { title: 'Profile' }]}>
            <PageScaffold
                title={
                    <Box center="vertical">
                        <OSKIcon code="sigma" style={{ paddingRight: '8px' }} />
                        <Heading>SIGMA Data: Profile</Heading>
                    </Box>
                }
            >
                <Box p={10} col>
                    <Form schema={ProfileSchema} onSubmit={handleSubmit}>
                        <ProfileForm
                            data={profile}
                            editMode={editMode}
                            onEditModeChange={(value) => setEditMode(value)}
                        />
                        {editMode && (
                            <Box style={{ justifyContent: 'flex-end' }}>
                                <Button label="Save Changes" submit variant="neutral" inverted />
                            </Box>
                        )}
                    </Form>
                </Box>
            </PageScaffold>
        </PortalContentView>
    );
};
