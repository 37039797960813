import { Box, Button, Divider, OSKThemeType, Text } from 'oskcomponents';
import { OSKIcon } from 'oskcomponents/src/OSKIcon';
import React, { useCallback, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { GlobalZIndex } from '~/constants';
import { useDisableFeatureOnMouseOver } from '~/hooks';
import { IconHeader } from '~/molecules/Monitor';

type AssetDetailsSidePanelProps = {
    /** The title to display at the top */
    title?: string;
    /** The element(s) to display underneath the header. */
    children?: React.ReactNode | Array<React.ReactNode>;
    /** A flag that determines whether the "back to all detections" button is shown */
    showBackButton?: boolean;
    /** The method that's called when the back button is clicked. */
    onBackClick?: () => void;
};

const AssetDetailsSidePanel = ({ title, children, showBackButton, onBackClick }: AssetDetailsSidePanelProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);
    const [minimized, setMinimized] = useState<boolean>(false);
    const theme = useTheme() as OSKThemeType;

    // This prevents the scrollbar from showing arbitrarily when interacting with the minimize functionality.
    const toggleMinimized = useCallback(() => {
        setMinimized(!minimized);
        if (ref.current) {
            ref.current.style.overflow = 'hidden';
        }
    }, [minimized, ref]);

    useDisableFeatureOnMouseOver(ref, 'Drag', true);
    useDisableFeatureOnMouseOver(ref, 'Zoom', true);

    const minimizedHeight = `${titleRef.current ? titleRef.current?.offsetHeight + 36 : '72'}px`; // titleRef.current && > 40 ? '96px' : '72px';

    return (
        <Box
            bg={theme.colors.primary.transBg}
            m={24}
            style={{
                position: 'relative',
                zIndex: GlobalZIndex.MapSection,
                width: '600px',
                minHeight: minimizedHeight,
                height: `${minimized ? '0%' : '80%'}`,
                transition: 'height 0.3s ease',
                borderRadius: '7px',
            }}
            ref={ref}
            id="asset-detail-side-panel"
            col
        >
            <Box
                col
                grow
                p={8}
                style={{
                    overflow: 'scroll',
                }}
            >
                <Box
                    style={{
                        placeContent: 'space-between',
                        padding: '10px',
                        alignItems: 'center',
                    }}
                >
                    {showBackButton ? (
                        <Box>
                            <Button
                                style={{
                                    backgroundColor: `${theme.colors.primary.bg}`,
                                    border: `2px solid ${theme.colors.primary.border}`,
                                    height: '36px',
                                    marginRight: '10px',
                                }}
                                w={110}
                                onClick={() => onBackClick && onBackClick()}
                            >
                                <Box row center="vertical">
                                    <OSKIcon code="arrow-left" />
                                    <Text variant="small">To All</Text>
                                </Box>
                            </Button>
                            <IconHeader ref={titleRef} title={title ?? '--'} />
                        </Box>
                    ) : (
                        <>
                            <IconHeader ref={titleRef} title={title ?? '--'} iconCode="pipeline" />
                        </>
                    )}

                    <Button
                        style={{
                            backgroundColor: `${theme.colors.primary.bg}`,
                            border: `2px solid ${theme.colors.primary.border}`,
                            height: '36px',
                        }}
                        onClick={toggleMinimized}
                    >
                        <OSKIcon code={minimized ? 'arrow-right' : 'arrow-down'} />
                    </Button>
                </Box>
                <Divider m={8} />
                {children}
                {!minimized && <React.Fragment />}
            </Box>
        </Box>
    );
};

export default AssetDetailsSidePanel;
export type { AssetDetailsSidePanelProps };
