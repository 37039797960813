import { Box, Checkbox, Form, Heading, ListEntry, OSKThemeType, Select, Spinner, Text, TextInput } from 'oskcomponents';
import { Capture, DownloadIntent, fround, noop, OSKGeoJson, SigmaAPI } from 'oskcore';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { CartCustomization } from '~/molecules';
import { getAllItems, getFullItemsByCollect } from '~/redux/modules/data/cart';
import { RootState } from '~/redux/store';
import { CartWizardStep, CartWizardTemplate } from '~/templates';
import { SummarySection } from './SummarySection';
import * as yup from 'yup';
import { getProgramId } from '~/utils';

export type FinalizeCheckoutProps = {
    intents?: DownloadIntent[];
    cartItems?: Record<string, Capture[]>;
} & CartWizardStep;

export const PlaceOrderSchema: yup.SchemaOf<any> = yup.object().shape({
    destination: yup.string().required('Destination is a required field'),
    data_level: yup.string().required('Data Level is a required field'),
    file_type: yup.string().required('Data Level is a required field'),
});

export const FinalizeCheckout = ({ intents, cartItems, onWizardBack, onWizardNext }: FinalizeCheckoutProps) => {
    const theme = useTheme() as OSKThemeType;
    const skipMap = (intents ?? []).reduce((acc, cur) => {
        if (cur) {
            acc[cur.fileId] = !!cur.skip;
        }
        return acc;
    }, {} as Record<string, boolean>);

    const [error, setError] = useState(null);
    const [bucket, setBucket] = useState<string | null>(null);
    const [dataLevel, setDataLevel] = useState<ListEntry>({ label: '1A', value: '1a' });
    const [fileType, setFileType] = useState<ListEntry>({ label: 'ENVI', value: 'envi' });
    const [submitting, setSubmitting] = useState(false);

    const totalImagery = (intents ?? []).filter((intent) => !intent.skip).length;
    const totalKm = Object.values(cartItems ?? {})
        .flatMap((capture) => capture)
        .filter((capture) => !skipMap[capture.id])
        .map((capture) => OSKGeoJson.fromAPIGeometry(capture.footprint))
        .map((footprint) => footprint.getApproximateArea())
        .reduce((acc, cur) => {
            return acc + cur;
        }, 0);

    return (
        <Form
            onSubmit={(data) => {
                // Make API Call
                setSubmitting(true);
                setError(null);

                console.log({ data });

                SigmaAPI.createOrder({
                    orderRequest: {
                        orderType: 'archive',
                        customerPoc: '',
                    },
                    program: getProgramId(),
                })
                    .then(() => {
                        onWizardNext && onWizardNext();
                    })
                    .catch((ex) => {
                        console.error(ex);
                        setError(ex.toString());
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
            schema={PlaceOrderSchema}
            style={{ flexDirection: 'row', flexGrow: 1, display: 'flex', width: '100%' }}
        >
            <CartWizardTemplate
                buttons={[
                    { variant: 'primary', inverted: true, label: 'Back', onClick: onWizardBack ?? noop },
                    {
                        variant: 'action',
                        noborder: true,
                        label: 'Place Order',
                        submit: true,
                        onClick: noop,
                    },
                ]}
                style={{
                    width: '100%',
                    overflowY: 'hidden',
                }}
            >
                <Box style={{ width: '100%' }} col>
                    <Box center="all" pt={40}>
                        {error && <Text color={theme.colors.red}>{error}</Text>}
                    </Box>
                    <Box pt={60} style={{ overflowY: 'auto' }} grow center="horizontal">
                        {submitting && <Spinner style={{ marginTop: '200px' }} size="Large" variant="Box" />}
                        {submitting === false && (
                            <Box style={{ maxWidth: '1000px' }} grow>
                                <Box fg={theme.colors.black900} col grow>
                                    <CartCustomization title={'Destination'}>
                                        <Box pb={10} col>
                                            <Text variant="small">
                                                Select the destination bucket for file delivery.
                                            </Text>
                                            <Text variant="small">This should be a fully qualified s3 uri.</Text>
                                        </Box>
                                        <TextInput
                                            inverted
                                            value={bucket}
                                            onChange={(evt) => {
                                                setBucket(evt.target.value);
                                            }}
                                            placeholder="s3://somebucket"
                                            name="destination"
                                        />
                                    </CartCustomization>
                                    <CartCustomization title={'Data Level'}>
                                        <Box pb={10} col>
                                            <Text variant="small">
                                                Select the data level that you want files delivered as.
                                            </Text>
                                        </Box>
                                        <Select
                                            style={{ maxWidth: '600px', flexGrow: 1 }}
                                            name="data_level"
                                            inverted
                                            variant={'primary'}
                                            defaultValue={dataLevel.value}
                                            onSelect={(option) => {
                                                if (option) {
                                                    setDataLevel(option);
                                                }
                                            }}
                                            items={[
                                                { label: '1A', value: '1a' },
                                                { label: '1B', value: '1b' },
                                                { label: '2A', value: '2a' },
                                            ]}
                                        ></Select>
                                    </CartCustomization>
                                    <CartCustomization title={'File Type'}>
                                        <Box pb={10} col>
                                            <Text variant="small">
                                                Select the file type that you want data delivered as.
                                            </Text>
                                        </Box>
                                        <Select
                                            name="file_type"
                                            inverted
                                            variant={'primary'}
                                            defaultValue={fileType.value}
                                            onSelect={(option) => {
                                                if (option) {
                                                    setFileType(option);
                                                }
                                            }}
                                            items={[
                                                { label: 'NITF', value: 'nitf' },
                                                { label: 'ENVI', value: 'envi' },
                                            ]}
                                        ></Select>
                                    </CartCustomization>
                                </Box>
                                <Box col>
                                    <Box
                                        ml={40}
                                        w={400}
                                        p={40}
                                        style={{ borderRadius: '8px', lineHeight: '3rem' }}
                                        bg={theme.colors.black900}
                                        fg={theme.colors.white}
                                        col
                                    >
                                        <Heading>Summary</Heading>
                                        <SummarySection>
                                            <Box>
                                                <Text style={{ flexGrow: 1 }}>Total Imagery</Text>
                                                <Text w={100}>{totalImagery}</Text>
                                            </Box>
                                            <Box>
                                                <Text style={{ flexGrow: 1 }}>Total Area</Text>
                                                <Text w={100}>{fround(totalKm, 2)} km&sup2;</Text>
                                            </Box>
                                        </SummarySection>
                                        <SummarySection>
                                            <Box center="vertical" col>
                                                <Text style={{ fontWeight: 'bold', flexGrow: 1 }}>
                                                    Destination Bucket
                                                </Text>
                                                <Text w={100}>{bucket ?? 'None'}</Text>
                                            </Box>
                                        </SummarySection>
                                        <SummarySection>
                                            <Box center="vertical">
                                                <Text style={{ fontWeight: 'bold', flexGrow: 1 }}>Data Level</Text>
                                                <Text w={100}>{dataLevel.label}</Text>
                                            </Box>
                                        </SummarySection>
                                        <SummarySection>
                                            <Box center="vertical">
                                                <Text style={{ fontWeight: 'bold', flexGrow: 1 }}>File Type</Text>
                                                <Text w={100}>{fileType.label}</Text>
                                            </Box>
                                        </SummarySection>
                                        <Box
                                            style={{
                                                marginTop: '1rem',
                                                paddingTop: '1.2rem',
                                                borderTop: `1px solid ${theme.colors.gray80}`,
                                                justifyContent: 'center',
                                            }}
                                            col
                                        >
                                            <Checkbox label="Make my order a recurring order"></Checkbox>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </CartWizardTemplate>
        </Form>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        intents: getAllItems(state),
        cartItems: getFullItemsByCollect(state),
        footprints: state.data.map.footprints,
    };
};

export default connect(mapStateToProps, noop)(FinalizeCheckout);
