import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    FileUpload,
    IconProps,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    OSKIcon,
    OSKIconType,
    OSKThemeType,
    Text,
    useClickAway,
} from 'oskcomponents';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { GlobalZIndex } from '~/constants';
import styled from 'styled-components';
import { OSKGeoJson, SearchArea, SigmaAPI } from 'oskcore';
import { MapModes, MapMode } from '~/atoms';
import { AOIFormAttributes, AOISaveDialog } from '~/molecules/AOISaveDialog';
import {
    getLibraryLoading,
    getCustomAreas,
    doFetchCustomAreasAsync,
    deleteCustomAreaAsync,
} from '~/redux/modules/data/library';
import { Polygon } from 'react-leaflet';
import { useTheme } from 'styled-components';
import { doSearchAsync, setRoi } from '~/redux/modules/data/search';
import { useMap } from '~/hooks';
import { format } from 'date-fns';

const MAP_FEATURE_MODE_HASH_KEY = 'drawing_feature';

/**
 * Given a file name candidate and a list of current file names,
 * create a distinct name that isn't in the list.
 */
function ensureDistinctName(fileName: string | undefined, currentNames: string[]) {
    const base = fileName ?? format(new Date(), `yyyy-MM-dd`);
    let name = base;
    let matches = 1;

    for (let i = 0; i < currentNames.length + 1; i++) {
        if (currentNames.includes(name)) {
            name = format(new Date(), `${base} (${matches++})`);
        }
    }

    return name;
}

type ToolIconProps = {
    /** The icon to display for this tool option */
    code: OSKIconType;
    /** The label to display for this tool option */
    label: string;
    /** A method to call whent this tool option is clicked */
    onClick?: () => void;
    /** A flag that disables the tool icon */
    disabled?: boolean;
    /** Styled-component class name pass-through */
    className?: string;
} & IconProps;

const ToolIconBase = ({ className, code, label, onClick, disabled, ...props }: ToolIconProps) => {
    return (
        <Box
            className={className}
            center="all"
            col
            onClick={() => {
                if (!disabled && onClick) {
                    onClick();
                }
            }}
        >
            <OSKIcon code={code} className={` ${disabled ? 'tool-icon-disabled' : 'tool-icon-icon'}`} {...props} />
            <Text variant="tiny" className={`tool-icon-label ${disabled ? 'tool-icon-disabled' : ''}`}>
                {label}
            </Text>
        </Box>
    );
};

const ToolIcon = styled(ToolIconBase)`
    & {
        width: 100%;
        aspect-ratio: 1;
        padding: 4px;
        color: ${(props: any) => props.theme.colors.black900};
    }

    &:hover {
        cursor: ${(props: any) => (props.disabled ? 'default' : 'pointer')};
        background-color: ${(props: any) => (props.disabled ? '' : props.theme.colors.black900)};
        color: white;

        .tool-icon-icon {
            svg {
                fill: white;
            }
        }
    }

    .tool-icon-icon:hover {
        cursor: ${(props: any) => (props.disabled ? 'default' : 'pointer')};
        svg:hover {
            cursor: ${(props: any) => (props.disabled ? 'default' : 'pointer')};
        }
    }

    .tool-icon-label {
        text-align: center;
        margin-top: 4px;
        line-height: 1;
    }

    .tool-icon-disabled {
        color: ${(props: any) => props.theme.colors.black300} !important;
        svg {
            fill: ${(props: any) => props.theme.colors.black300} !important;
        }
    }
`;

type LibraryRowItemProps = {
    /** Icon to display for the library item */
    iconCode: OSKIconType;
    /** Text to display for the library item */
    label: string;
    /** Method to fire when the library item is clicked */
    onClick?: () => void;
    /** Method to fire when the library item's delete icon is clicked */
    onDelete?: () => void;
    /** Passthrough for styled components */
    className?: string;
};
const LibraryRowItemBase = ({ className, iconCode, label, onClick, onDelete, ...props }: LibraryRowItemProps) => {
    const TRUNC_LENGTH = 19;
    const truncLabel = label.length > TRUNC_LENGTH ? `${label.substring(0, TRUNC_LENGTH - 3)}...` : label;
    return (
        <Box center="vertical" p={8} row onClick={onClick} className={className} {...props}>
            <OSKIcon code={iconCode} style={{ marginRight: '8px' }} />
            <Text variant="small">{truncLabel}</Text>
            <OSKIcon
                className="tool-icon-delete"
                code="trash"
                onClick={(e: any) => {
                    e.preventDefault();
                    onDelete && onDelete();
                }}
            />
        </Box>
    );
};

const LibraryRowItem = styled(LibraryRowItemBase)`
    .tool-icon-delete {
        display: none;
        position: absolute;
        right: 8px;
    }
    .tool-icon-delete:hover {
        svg {
            fill: ${(props: any) => props.theme.colors.accent};
        }
    }
    & {
        width: 100%;
    }

    &:hover {
        background-color: ${(props: any) => props.theme.colors.lightGray};
        cursor: pointer;

        .tool-icon-delete {
            display: block;
        }

        svg {
            cursor: pointer;
        }
    }
`;

const emptyKmlOutput = {
    succeeded: 0,
    lackingNames: 0,
    failed: 0,
};

type AoiToolsProps = {
    /** Boolean to indicate whether the library is loading */
    libraryLoading: boolean;
    /** A list of SearchArea elements to show in the library */
    libraryItems: Array<SearchArea>;
    /** Method to invoke when an item is deleted from the library */
    deleteLibraryItem: (id: number) => void;
    /** Method to invoke when we wish to re-fetch the library items */
    reloadLibrary: () => void;
    /** Aggregate all search options from store and query API */
    doSearch: () => void;
    /** Method to invoke when a new aoi is drawn or selected from the library. */
    onAoiChanged?: (aoi: OSKGeoJson) => void;
    /** Checks Editable map for an AOI and sets to redux store */
    setMapRoi: (geoJson: OSKGeoJson) => void;
    /** A flag to display the "Seach Area" button */
    searchEnabled?: boolean;
};

export const AoiTools = ({
    libraryItems,
    deleteLibraryItem,
    reloadLibrary,
    doSearch,
    onAoiChanged,
    setMapRoi,
    searchEnabled = true,
}: AoiToolsProps) => {
    const [showLibrary, setShowLibrary] = useState(false);
    const [currentAoi, setCurrentAoi] = useState<OSKGeoJson>(new OSKGeoJson());
    const [isSavingAoi, setIsSavingAoi] = useState(false);
    const [saveModalVisible, setSaveModalVisible] = useState(false);
    const [uploadResultsModalVisible, setUploadResultsModalVisible] = useState(false);
    const [modalError, setSaveModalError] = useState<string | undefined>(undefined);
    const [mode, setMode] = useState<MapModes>('None');
    const libraryPopupRef = useRef(null);
    const kmlOutput = useRef({ ...emptyKmlOutput });
    const theme = useTheme() as OSKThemeType;
    const map = useMap();
    const libraryItemNames = libraryItems.map((libraryItem) => libraryItem.name);

    useClickAway(
        libraryPopupRef,
        () => {
            setShowLibrary(false);
        },
        showLibrary,
    );

    useEffect(() => {
        reloadLibrary();
    }, []);

    useEffect(() => {
        // If they are drawing or something, disable zoom and scroll. Otherwise, enable it.
        if (mode === 'Polygon' || mode === 'Point') {
            map.requestUpdateToFeatureEnabled('Drag', MAP_FEATURE_MODE_HASH_KEY, false);
            map.requestUpdateToFeatureEnabled('Zoom', MAP_FEATURE_MODE_HASH_KEY, false);
        } else {
            map.requestUpdateToFeatureEnabled('Drag', MAP_FEATURE_MODE_HASH_KEY, true);
            map.requestUpdateToFeatureEnabled('Zoom', MAP_FEATURE_MODE_HASH_KEY, true);
        }
    }, [mode]);

    // Handle cancelling the save modal
    const hideSaveModal = () => {
        setSaveModalVisible(false);
        setSaveModalError(undefined);
    };

    // Handle showing the modal
    const showSaveModal = () => {
        setSaveModalVisible(true);
    };

    const UpdateCurrentAoi = (aoi: OSKGeoJson) => {
        setCurrentAoi(aoi);
        onAoiChanged && onAoiChanged(aoi);
    };

    const SaveAoi = (AreaName: string, aoi: OSKGeoJson) => {
        return SigmaAPI.createSearchArea({
            searchAreaRequest: {
                name: AreaName,
                area: JSON.stringify(aoi.toAPIGeometry()) as any,
            },
        }).then(() => {
            setMode('Clear');
            // setMode('clear') will cause taskingArea to be wiped out
            // so we need a bit of a delay before repopulating it and
            // becuase of react, there is no real way to guarantee
            // the operation has been completed or not.
            // So we use a setTimeout with reckless abandon.
            setTimeout(() => {
                setTimeout(() => {
                    UpdateCurrentAoi(aoi);
                    onAoiChanged && onAoiChanged(aoi);
                }, 150);
            });

            reloadLibrary();
        });
    };

    // Handle submitting the modal
    const handleModalSubmit = useCallback(
        ({ AreaName }: AOIFormAttributes) => {
            // Clear modal error
            setSaveModalError(undefined);
            setIsSavingAoi(true);

            // Basic validations
            if (AreaName === undefined) {
                setSaveModalError('Please specify an area name');
                setIsSavingAoi(false);
            } else if (currentAoi) {
                SaveAoi(AreaName, currentAoi)
                    .catch((err) => {
                        console.error(err);
                        setSaveModalError(err.toString());
                    })
                    .finally(() => {
                        setIsSavingAoi(false);
                        hideSaveModal();
                    });
            } else {
                setIsSavingAoi(false);
                setSaveModalVisible(false);
            }
        },
        [currentAoi, setIsSavingAoi, setSaveModalVisible, setSaveModalError],
    );

    const handleFileUpload = async (files: FileList | null) => {
        if (files) {
            kmlOutput.current = { ...emptyKmlOutput };
            const fileName = files[0].name;
            const data = await files[0].text();
            const oskJson = OSKGeoJson.fromKML(data);
            const promises = [];

            for (const feature of oskJson.features) {
                const name = feature.name ?? ensureDistinctName(fileName, libraryItemNames);

                promises.push(
                    SaveAoi(name, OSKGeoJson.fromFeature(feature))
                        .then(() => {
                            kmlOutput.current.succeeded++;
                        })
                        .catch((err) => {
                            kmlOutput.current.failed++;
                        }),
                );
            }

            await Promise.all(promises);
            setUploadResultsModalVisible(true);
        }
    };

    return (
        <React.Fragment>
            <Modal visible={uploadResultsModalVisible}>
                <ModalHeader variant="primary">Upload Complete</ModalHeader>
                <ModalBody>
                    <Box col>
                        {kmlOutput.current.succeeded > 0 && <Text>{kmlOutput.current.succeeded} succeeded.</Text>}
                        {kmlOutput.current.lackingNames > 0 && (
                            <Text>{kmlOutput.current.lackingNames} failed because they needed a title.</Text>
                        )}
                        {kmlOutput.current.failed > 0 && (
                            <Text>{kmlOutput.current.failed} failed because they already exist.</Text>
                        )}
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button variant="action" label="Close" onClick={() => setUploadResultsModalVisible(false)} />
                </ModalFooter>
            </Modal>

            <AOISaveDialog
                isLoading={isSavingAoi}
                errorMessage={modalError}
                onSubmit={handleModalSubmit}
                visible={saveModalVisible}
                onCancel={hideSaveModal}
            />

            <Polygon positions={currentAoi.toLeafletCoordinates()} />
            <MapMode
                mode={mode}
                onBeforeEdit={() => {
                    if (!currentAoi.isEmpty()) {
                        UpdateCurrentAoi(new OSKGeoJson());
                    }
                }}
                onSave={(geoJson) => {
                    setMode('None');
                    UpdateCurrentAoi(geoJson);
                }}
            />
            {showLibrary && (
                <Box
                    ref={libraryPopupRef}
                    style={{
                        position: 'absolute',
                        top: '280px',
                        right: 'calc(50px + 25px + 4px)',
                    }}
                    col
                >
                    <Box
                        style={{
                            zIndex: GlobalZIndex.Timeline,
                            alignItems: 'center',
                            backgroundColor: 'white',
                            color: 'black',
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                            width: '200px',
                            position: 'relative',
                            right: '0px',
                            borderRadius: '4px',
                        }}
                        col
                    >
                        <Box
                            bg={theme.colors.lightGray}
                            style={{
                                justifyContent: 'space-between',
                                width: '100%',
                                padding: '8px',
                                borderRadius: '4px',
                            }}
                            center="vertical"
                            onClick={() => setShowLibrary(false)}
                        >
                            <Box center="vertical">
                                <OSKIcon code="report" fill={theme.colors.black900} style={{ marginRight: '8px' }} />
                                <Text variant="small" strong color={theme.colors.black900}>
                                    My Library
                                </Text>
                            </Box>
                            <OSKIcon code="arrow-right" />
                        </Box>
                        <Box col style={{ width: '100%' }}>
                            {libraryItems.map((item, idx) => (
                                <React.Fragment key={`library-item-${idx}`}>
                                    {idx > 0 && (
                                        <Box
                                            style={{ borderTop: `1px solid ${theme.colors.lightGray}`, width: '100%' }}
                                        />
                                    )}
                                    <LibraryRowItem
                                        iconCode="draw-polygon"
                                        label={item.name}
                                        onClick={() => {
                                            const geo = OSKGeoJson.fromAPIGeometry(item.area);
                                            UpdateCurrentAoi(geo);
                                            map.fitCoordinates([geo], 0.01);
                                        }}
                                        onDelete={() => {
                                            deleteLibraryItem(item.id);
                                            setMode('Clear');
                                            reloadLibrary();
                                        }}
                                    />
                                </React.Fragment>
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}

            <Box style={{ position: 'absolute', top: '25px', right: '25px' }} col>
                <Box
                    style={{
                        zIndex: GlobalZIndex.Timeline,
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        width: '50px',
                        overflow: 'hidden',
                    }}
                    col
                >
                    <ToolIcon
                        code="draw-polygon"
                        label="Draw"
                        onClick={() => {
                            setMode('Polygon');
                        }}
                    />
                    <ToolIcon
                        code="location-arrow"
                        label="Point"
                        onClick={() => {
                            setMode('Point');
                        }}
                    />
                    <ToolIcon
                        code="undo"
                        label="Clear"
                        onClick={() => {
                            setMode('Clear');
                        }}
                    />
                    <Box style={{ borderTop: `1px solid ${theme.colors.black600}`, width: '80%' }} />

                    <FileUpload fileTypes=".kml" onFilesChosen={handleFileUpload}>
                        <ToolIcon code="upload" label="Upload" />
                    </FileUpload>

                    <ToolIcon
                        code="save"
                        label="Save"
                        onClick={() => {
                            showSaveModal();
                        }}
                    />
                    <ToolIcon
                        code="report"
                        label="Library"
                        w={12}
                        onClick={() => {
                            setShowLibrary(!showLibrary);
                        }}
                    />
                </Box>
                {searchEnabled && (
                    <Box
                        style={{
                            marginTop: '12px',
                            zIndex: GlobalZIndex.Timeline,
                            alignItems: 'center',
                            backgroundColor: 'white',
                            borderRadius: '4px',
                            boxShadow: `0px 4px 4px ${theme.colors.gray2a}`,
                            width: '50px',
                            overflow: 'hidden',
                        }}
                        col
                    >
                        <ToolIcon
                            code="location-crosshairs"
                            label="Search Area"
                            w={14}
                            onClick={() => {
                                setMapRoi(currentAoi);
                                doSearch && doSearch();
                                setShowLibrary(false);
                            }}
                            disabled={currentAoi.features.length === 0}
                        />
                    </Box>
                )}
            </Box>
        </React.Fragment>
    );
};

const mapStateToProps = (state: RootState) => {
    return { libraryLoading: getLibraryLoading(state), libraryItems: getCustomAreas(state) };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        reloadLibrary: () => {
            dispatch<any>(
                doFetchCustomAreasAsync({
                    offset: 0,
                    limit: 1000,
                    silent: false,
                }),
            );
        },

        deleteLibraryItem: (id: number) => {
            dispatch<any>(deleteCustomAreaAsync(id, { silent: true }));
        },

        setMapRoi: (geoJson: OSKGeoJson) => {
            dispatch(setRoi(geoJson));
        },
        doSearch: () => {
            // @ts-ignore TODO: figure out how to dispatch thunks in a way that makes typescript happy
            dispatch(doSearchAsync());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AoiTools);
