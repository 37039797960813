import { noop } from 'oskcore';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import TaskingOrderPaginator from './paginator';
import TaskingOrderTable from '~/organisms/tables/TaskingOrderTable';
import { DataResourceProvider } from '~/redux';
import { selectTaskingOrdersByPage, setActiveOrderId, SigmaOrder } from '~/redux/modules/data/orders';
import { RootState } from '~/redux/store';
import { getProgramId } from '~/utils';

export type TaskingOrdersProps = {
    orders: SigmaOrder[];
    selectedIndex?: number;
    orderPage: number;
    loading: boolean;
};

export const TaskingOrders = ({ orders, selectedIndex, orderPage, loading }: TaskingOrdersProps) => {
    const dispatch = useDispatch();

    return (
        <DataResourceProvider programId={getProgramId()} features={['tasking_orders']} orderPage={orderPage}>
            <TaskingOrderTable
                selectedIndex={selectedIndex}
                onRowClick={(row) => {
                    const { id } = row;
                    dispatch(setActiveOrderId(id));
                }}
                loading={loading}
                data={orders}
            >
                <TaskingOrderPaginator inverted />
            </TaskingOrderTable>
        </DataResourceProvider>
    );
};

const mapStateToProps = (state: RootState) => {
    const orders = selectTaskingOrdersByPage(state, state.data.orders.taskingOrderPage);
    const selectedIndex = (orders ?? []).findIndex((order) => order.id === state.data.orders.activeOrderId);

    return {
        orders,
        selectedIndex,
        loading: state.data.orders.loadingTaskingOrders,
        orderPage: state.data.orders.taskingOrderPage,
    };
};

export default connect(mapStateToProps, noop)(TaskingOrders);
