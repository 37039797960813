import React from 'react';
import { Box } from 'oskcomponents';
import { useNavigate } from 'react-router-dom';
import { useCurrentApp } from 'oskcore';
import { AppNames } from '~/constants';
import { getProgramId } from '~/utils';

export type BreadcrumbNodeProps = {
    title: string;
    url?: string;
};

const BreadcrumbNode = ({ title, url }: BreadcrumbNodeProps) => {
    const navigate = useNavigate();
    return (
        <Box
            style={{ paddingRight: '4px', textDecoration: url ? 'underline' : 'none' }}
            {...(url && { onClick: () => navigate(url) })}
        >
            {title}
        </Box>
    );
};

const BreadcrumbDivider = () => <Box style={{ padding: '0px 6px 0px 6px' }}>/</Box>;

export type BreadcrumbProps = {
    nodes?: Array<BreadcrumbNodeProps>;
};

export const Breadcrumb = ({ nodes }: BreadcrumbProps) => {
    const currentApp = useCurrentApp();
    const programId = getProgramId();

    const currentAppNode: BreadcrumbNodeProps = {
        title: AppNames[currentApp],
        url: '/',
    };

    switch (currentApp) {
        case 'monitor':
            currentAppNode.url = `/program/${programId}/monitor`;
            break;
        // TODO: Handle other apps
    }

    const appNodes = [currentAppNode, ...(nodes ?? [])];

    return (
        <React.Fragment>
            {appNodes && (
                <Box>
                    {appNodes.map((node, idx) => (
                        <React.Fragment key={`breadcrumb_${node.title}_${idx}`}>
                            <BreadcrumbNode {...node} />
                            {idx < appNodes.length - 1 && <BreadcrumbDivider />}
                        </React.Fragment>
                    ))}
                </Box>
            )}
        </React.Fragment>
    );
};
