import { Box, Heading, TabView, OSKThemeType } from 'oskcomponents';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { noop } from 'oskcore';
import { connect, useDispatch } from 'react-redux';
import CatalogOrders from './CatalogOrders';
import TaskingOrders from './TaskingOrders';
import {
    selectArchiveOrdersByPage,
    selectTaskingOrdersByPage,
    setActiveOrderId,
    SigmaOrder,
} from '~/redux/modules/data/orders';
import { RootState } from '~/redux/store';

export type OrderListPanelProps = {
    firstArchiveOrder?: SigmaOrder;
    firstTaskingOrder?: SigmaOrder;
};

const OrderListPanel = ({ firstArchiveOrder, firstTaskingOrder }: OrderListPanelProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        // Select the first order
        if (firstArchiveOrder) {
            dispatch(setActiveOrderId(firstArchiveOrder.id));
        } else if (firstTaskingOrder) {
            console.log('set active orderId ' + firstTaskingOrder.id);
            dispatch(setActiveOrderId(firstTaskingOrder.id));
            setActiveTab(1);
        }
    }, [dispatch, firstArchiveOrder, firstTaskingOrder]);

    return (
        <>
            <Heading>Order Dashboard</Heading>
            <Box style={{ placeContent: 'space-between', height: '100%' }}>
                <TabView
                    tabContainerStyle={{
                        backgroundColor: 'transparent',
                        marginBottom: '24px',
                    }}
                    defaultTab={activeTab}
                    tabStyle={{ color: theme.colors.black, padding: '8px' }}
                    tabItems={[
                        {
                            label: 'Catalog',
                            component: <CatalogOrders />,
                        },
                        {
                            label: 'Taskings',
                            component: <TaskingOrders />,
                        },
                    ]}
                />
            </Box>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    const firstTaskingOrder = (selectTaskingOrdersByPage(state, 0) ?? [])?.[0];
    const firstArchiveOrder = (selectArchiveOrdersByPage(state, 0) ?? [])?.[0];

    return {
        firstTaskingOrder,
        firstArchiveOrder,
    };
};

export default connect(mapStateToProps, noop)(OrderListPanel);
