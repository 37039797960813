/**
    This component is designed to list the FileIntents that a user 
    is preparing to purchase. FileIntent is essentially a fileId/taskId
    tuple. They can be "skipped" (which is what happens when you toggle a line item)
    and if it is skipped, it will be omitted from the final order.
*/

import { OSKThemeType } from 'oskcomponents';
import { DownloadIntent, noop } from 'oskcore';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { CaptureGroup } from '~/molecules/CaptureGroup';
import CaptureItem from '~/molecules/CaptureItem';
import { RootState } from '~/redux/store';
import { CartGroupIcon, getAllItems, getCollectIcon, setFileIntent } from '~/redux/modules/data/cart';

type CartCaptureGroupProps = {
    /** Current taskId to render this group for */
    taskId: string;
    /** The list of fileIds in the collect */
    fileIds: string[];
    /** The icon to show at the summary */
    icon: CartGroupIcon;
};

const CartCaptureGroup = ({ taskId, fileIds, icon }: CartCaptureGroupProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();

    return (
        <CaptureGroup
            onIconClick={() => {
                for (const fileId of fileIds) {
                    if (icon === 'all') {
                        dispatch(setFileIntent(fileId, true));
                    } else {
                        dispatch(setFileIntent(fileId, false));
                    }
                }
            }}
            defaultExpanded={true}
            mb={8}
            taskId={taskId}
            key={`task_${taskId}`}
            icon={icon}
        >
            {fileIds.map((fileId, idx) => (
                <CaptureItem
                    intentOnly={true}
                    fileId={fileId}
                    key={`collect_item_${fileId}`}
                    style={{
                        margin: '0',
                        backgroundColor: idx % 2 === 0 ? theme.colors.lightGray : theme.colors.white,
                    }}
                />
            ))}
        </CaptureGroup>
    );
};

const mapCartCaptureGroupStateToProps = (state: RootState, ownProps: Partial<CartCaptureGroupProps>) => {
    return {
        fileIds: getAllItems(state)
            .filter((x) => x !== undefined)
            .filter((x) => x.taskId === ownProps.taskId)
            .map((item) => item.fileId),
        icon: getCollectIcon(state, ownProps.taskId ?? '-1', true),
    };
};

const ConnectedCartCaptureGroup = connect(mapCartCaptureGroupStateToProps, noop)(CartCaptureGroup);

export type CartCollectPreviewProps = {
    /** List of all collects */
    taskIdList: string[];
};

export const CartCollectPreview = ({ taskIdList }: CartCollectPreviewProps) => {
    return (
        <>
            {taskIdList.map((taskId) => (
                <ConnectedCartCaptureGroup key={`search_data_collect_group_${taskId}`} taskId={taskId} />
            ))}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    const visibleItems = getAllItems(state).filter((x) => x !== undefined) as DownloadIntent[];
    const collectMap = visibleItems.reduce((acc, cur) => {
        acc[cur.taskId] = true;
        return acc;
    }, {} as Record<string, boolean>);

    return {
        taskIdList: Object.keys(collectMap),
    };
};

export default connect(mapStateToProps, noop)(CartCollectPreview);
