import React, { useEffect, useState } from 'react';
import { Box, BoxProps, Heading, OSKIcon } from 'oskcomponents';

export type CollapsableGroupProps = {
    /** Whether to start with the container expanded */
    defaultExpanded?: boolean;
    /** A title to render at the top */
    title?: string | React.ReactNode;
    /** If true, the caret will be on the right side */
    flipCaret?: boolean;
    /** If true, the caret will be hidden */
    hideCaret?: boolean;
    /** Method to invoke when expanded status changes */
    onExpandChange?: (isExpanded: boolean) => void;
    /** Children to render inside the container */
    children?: React.ReactNode;
} & Omit<Omit<BoxProps, 'ref'>, 'title'>;

export const CollapsableGroup = ({
    defaultExpanded,
    onExpandChange,
    title,
    children,
    flipCaret,
    hideCaret,
    ...props
}: CollapsableGroupProps) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? false);

    useEffect(() => {
        onExpandChange && onExpandChange(isExpanded);
    }, [onExpandChange, isExpanded]);

    return (
        <Box col {...props}>
            <Box
                onClick={() => {
                    setIsExpanded(!isExpanded);
                }}
            >
                <Box
                    style={{
                        justifyContent: 'space-around',
                        flexDirection: flipCaret ? 'row-reverse' : 'row',
                        paddingRight: '8px',
                        paddingLeft: '8px',
                    }}
                    center="vertical"
                    grow
                >
                    {hideCaret ? (
                        <></>
                    ) : (
                        <OSKIcon scale={150} pr={4} pl={4} code={isExpanded ? 'caret-down' : 'arrow-right'} />
                    )}
                    {React.isValidElement(title) ? (
                        title
                    ) : (
                        <Box grow>
                            <Heading>{title}</Heading>
                        </Box>
                    )}
                </Box>
            </Box>

            <Box style={{ visibility: isExpanded ? 'visible' : 'hidden', display: isExpanded ? 'flex' : 'none' }} col>
                {children}
            </Box>
        </Box>
    );
};
