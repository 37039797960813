import React from 'react';
import styled from 'styled-components';
import { IconType } from 'react-icons';
import {
    FaCalendar,
    FaCaretDown,
    FaCheck,
    FaCheckSquare,
    FaCircle,
    FaDownload,
    FaDrawPolygon,
    FaFileDownload,
    FaFolder,
    FaInfo,
    FaList,
    FaLocationArrow,
    FaRegCircle,
    FaRegSquare,
    FaSatellite,
    FaSave,
    FaSearch,
    FaShoppingCart,
    FaSquare,
    FaThLarge,
    FaTable,
    FaTimes,
    FaTimesCircle,
    FaTrash,
    FaUndo,
    FaUpload,
    FaUser,
    FaCheckCircle,
    FaPlus,
    FaCaretUp,
    FaMap,
    FaCaretRight,
    FaCaretLeft,
    FaCog,
    FaMailBulk,
    FaBuilding,
    FaBell,
    FaClipboardCheck,
    FaPaperclip,
    FaCopy,
    FaPencilAlt,
    FaMinusSquare,
    FaFilter,
    FaGlobeAmericas,
    FaMinusCircle,
    FaLayerGroup,
    FaEllipsisH,
    FaSun,
    FaCloud,
    FaGlobe,
    FaExclamationTriangle,
} from 'react-icons/fa';
import { Box, BoxProps } from '../Box';

type OSKIconType =
    | 'api'
    | 'arrow-left-with-border'
    | 'arrow-right-with-border'
    | 'bands'
    | 'bell'
    | 'reg-square'
    | 'check-square'
    | 'dash-square'
    | 'caret-down'
    | 'times-circle'
    | 'square'
    | 'layers'
    | 'scale'
    | 'circle'
    | 'reg-circle'
    | 'draw-polygon'
    | 'trash'
    | 'times'
    | 'info'
    | 'shopping-cart'
    | 'file-download'
    | 'download'
    | 'table'
    | 'satellite'
    | 'user'
    | 'list'
    | 'th-large'
    | 'check'
    | 'location-arrow'
    | 'undo'
    | 'folder'
    | 'save'
    | 'upload'
    | 'search'
    | 'calendar'
    | 'check-circle'
    | 'dash-circle'
    | 'ghost'
    | 'arrow-up'
    | 'arrow-down'
    | 'arrow-right'
    | 'arrow-left'
    | 'plus'
    | 'map'
    | 'grid'
    | 'gps'
    | 'triangle'
    | 'construction'
    | 'alert-circle'
    | 'assets'
    | 'alert'
    | 'report'
    | 'reports'
    | 'pipeline'
    | 'visibility'
    | 'visibility-off'
    | 'AOI'
    | 'gear'
    | 'sigma'
    | 'email'
    | 'company'
    | 'clipboard-check'
    | 'paperclip'
    | 'logo'
    | 'alert-marker'
    | 'quilt'
    | 'digging'
    | 'methane'
    | 'hydrocarbon'
    | 'vehicle'
    | 'exposed-piping'
    | 'copy'
    | 'fill-outline'
    | 'edit'
    | 'filter'
    | 'location-crosshairs'
    | 'globe'
    | 'order-error'
    | 'ellipsis'
    | 'sun'
    | 'cloud'
    | 'big-check-circle';

const SVGIconMap = (id: OSKIconType, color1?: string) => {
    const iconMap: { [id: string]: React.SVGProps<SVGSVGElement> | IconType } = {
        // TODO: Move each SVG to an individual file if we ever have a significant number of them.
        api: (
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
                <line className="cls-1" x1="818.05" y1="484.72" x2="408.85" y2="481.06" />{' '}
                <polyline
                    className="cls-1"
                    points="758.03 416.87 788.04 450.8 818.05 484.72 787.44 518.1 756.83 551.49"
                />{' '}
                <line className="cls-1" x1="164.34" y1="355.83" x2="573.54" y2="359.49" />{' '}
                <polyline
                    className="cls-1"
                    points="225.57 289.07 194.96 322.45 164.34 355.83 194.35 389.76 224.37 423.68"
                />
                <circle className="cls-1" cx="491.2" cy="420.28" r="213.61" />
            </svg>
        ),
        bands: (
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1128 1080">
                <polygon className="cls-1" points="777.57 398.57 542 503.5 306.43 398.57 542 293.65 777.57 398.57" />{' '}
                <polyline
                    className="cls-1"
                    points="689.58 437.77 777.57 476.96 542 581.89 306.43 476.96 396.44 436.87"
                />{' '}
                <polyline
                    className="cls-1"
                    points="689.58 516.16 777.57 555.35 542 660.28 306.43 555.35 394.42 516.16"
                />{' '}
                <polyline
                    className="cls-1"
                    points="689.58 594.54 777.57 633.74 542 738.66 306.43 633.74 394.42 594.54"
                />{' '}
                <polyline className="cls-2" points="741.5 293.5 846.5 293.5 846.5 738.5 741.5 738.5" />
            </svg>
        ),
        ghost: (
            <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M22.2685 17.2691L16.6863 22.1592L9.31765 23.3094L1.72314 7.41536L7.56058 3.0597L14.674 1.375L22.2685 17.2691Z"
                    stroke="white"
                    fill="transparent"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                />
                <path
                    d="M14.674 1.375L22.2685 17.2691L16.6863 22.1592L9.31765 23.3094L1.72314 7.41536"
                    stroke="white"
                    fill="transparent"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                />
                <path
                    d="M20.1242 12.7812L14.542 17.6714L7.17334 18.8216"
                    stroke="white"
                    fill="transparent"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                />
                <path
                    d="M9.76934 7.71686C11.8002 6.76965 13.2392 5.56789 12.9835 5.03264C12.7277 4.4974 10.874 4.83136 8.8432 5.77858C6.81235 6.72579 5.37334 7.92755 5.62909 8.4628C5.88483 8.99804 7.73849 8.66407 9.76934 7.71686Z"
                    stroke="white"
                    fill="transparent"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                />
                <path
                    d="M5.65869 8.52383L8.88623 15.2788L8.86098 15.2252L8.81563 15.1305C8.81656 15.1328 8.88155 15.2687 8.88249 15.271C9.13824 15.8064 10.9921 15.4724 13.0227 14.5254C15.0533 13.5784 16.4854 12.3797 16.2297 11.8443C16.2198 11.8244 16.1693 11.7182 16.1693 11.7182L13.0054 5.09668"
                    stroke="white"
                    fill="transparent"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                />
                <path d="M10.7266 25.6246L12.2405 22.8936" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
                <path d="M23.2768 19.7716L20.1802 19.1904" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
            </svg>
        ),
        grid: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M14 10v4h-4v-4h4zm2 0h5v4h-5v-4zm-2 11h-4v-5h4v5zm2 0v-5h5v4a1 1 0 0 1-1 1h-4zM14 3v5h-4V3h4zm2 0h4a1 1 0 0 1 1 1v4h-5V3zm-8 7v4H3v-4h5zm0 11H4a1 1 0 0 1-1-1v-4h5v5zM8 3v5H3V4a1 1 0 0 1 1-1h4z"></path>
            </svg>
        ),
        construction: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="none">
                <circle cx="18" cy="18" r="17" fill="#F96621" stroke={color1} strokeWidth="2" />
                <path
                    d="M26.45 24.3775L21.51 19.4375L19.39 21.5575L24.33 26.4975C24.92 27.0875 25.87 27.0875 26.45 26.4975C27.03 25.9075 27.03 24.9575 26.45 24.3775Z"
                    fill={color1}
                />
                <path
                    d="M23.11 16.3875C25.04 16.3875 26.61 14.8175 26.61 12.8875C26.61 12.3075 26.45 11.7675 26.2 11.2875L23.5 13.9875L22.01 12.4975L24.71 9.7975C24.23 9.5475 23.69 9.3875 23.11 9.3875C21.18 9.3875 19.61 10.9575 19.61 12.8875C19.61 13.2975 19.69 13.6875 19.82 14.0475L17.97 15.8975L16.19 14.1175C16.58 13.7275 16.58 13.0975 16.19 12.7075L15.48 11.9975L17.6 9.8775C16.43 8.7075 14.53 8.7075 13.36 9.8775L10.54 12.7075C10.15 13.0975 10.15 13.7275 10.54 14.1175L11.25 14.8275H8.71C8.52 14.8275 8.34 14.8975 8.21 15.0375C7.93 15.3175 7.93 15.7575 8.21 16.0375L10.75 18.5775C11.03 18.8575 11.47 18.8575 11.75 18.5775C11.88 18.4475 11.96 18.2675 11.96 18.0775V15.5375L12.66 16.2375C13.05 16.6275 13.68 16.6275 14.07 16.2375L15.85 18.0175L9.5 24.3675C8.91 24.9575 8.91 25.9075 9.5 26.4875C10.09 27.0775 11.04 27.0775 11.62 26.4875L21.94 16.1775C22.31 16.3075 22.7 16.3875 23.11 16.3875Z"
                    fill={color1}
                />
            </svg>
        ),
        triangle: <FaExclamationTriangle />,
        'alert-circle': (
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <circle cx="18" cy="18" r="17" stroke={color1} strokeWidth="2" />
                <path
                    d="M10.8346 25.2989H24.6396C26.0513 25.2989 26.9313 23.6984 26.2254 22.4239L19.3229 9.95594C18.6171 8.68135 16.8571 8.68135 16.1513 9.95594L9.24876 22.4239C8.54292 23.6984 9.42292 25.2989 10.8346 25.2989ZM17.7371 18.5905C17.2329 18.5905 16.8204 18.1593 16.8204 17.6322V15.7155C16.8204 15.1884 17.2329 14.7572 17.7371 14.7572C18.2413 14.7572 18.6538 15.1884 18.6538 15.7155V17.6322C18.6538 18.1593 18.2413 18.5905 17.7371 18.5905ZM18.6538 22.4239H16.8204V20.5072H18.6538V22.4239Z"
                    fill={color1}
                />
            </svg>
        ),
        alert: (
            <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 3.99L18.53 17H3.47L11 3.99ZM11 0L0 19H22L11 0ZM12 14H10V16H12V14ZM12 8H10V12H12V8Z" />
            </svg>
        ),
        assets: (
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 8L12.5 17H23.5L18 8Z" />
                <path d="M23.5 28C25.9853 28 28 25.9853 28 23.5C28 21.0147 25.9853 19 23.5 19C21.0147 19 19 21.0147 19 23.5C19 25.9853 21.0147 28 23.5 28Z" />
                <path d="M9 19.5H17V27.5H9V19.5Z" />
            </svg>
        ),
        reports: (
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="11" y="11" width="29" height="29" fill="white" />
                <path d="M39.5833 6.25H10.4167C8.125 6.25 6.25 8.125 6.25 10.4167V39.5833C6.25 41.875 8.125 43.75 10.4167 43.75H39.5833C41.875 43.75 43.75 41.875 43.75 39.5833V10.4167C43.75 8.125 41.875 6.25 39.5833 6.25ZM29.1667 35.4167H14.5833V31.25H29.1667V35.4167ZM35.4167 27.0833H14.5833V22.9167H35.4167V27.0833ZM35.4167 18.75H14.5833V14.5833H35.4167V18.75Z" />
            </svg>
        ),
        pipeline: (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M13.4,9.75h4.55c.1,0,.18,.08,.18,.18v9.43c0,.1-.08,.18-.18,.18H6.05c-.1,0-.18-.08-.18-.18V9.93c0-.1,.08-.18,.18-.18h4.54c.1,0,.18-.08,.18-.18h0c0-.46,0-.91,0-1.37,0-.11,.03-.16,.14-.17,.33-.03,.66-.09,.99-.11,.19-.01,.39,.03,.59,.05,.18,.02,.37,.04,.56,.07,.09,.01,.16,.09,.16,.18v1.35c0,.1,.08,.18,.18,.18Z" />
                <path d="M19.37,14.64c0-1.61,0-3.22,0-4.83,0-.43,.12-.8,.48-1.06,.39-.29,.82-.32,1.25-.14,.43,.18,.67,.52,.71,.99,0,.08,.01,.17,.01,.25,0,3.2,0,6.39,0,9.59,0,.32-.05,.63-.26,.89-.27,.34-.63,.46-1.05,.44-.7-.03-1.14-.56-1.14-1.28,0-1.62,0-3.24,0-4.86Z" />
                <path d="M4.62,14.65c0,1.61,0,3.22,0,4.83,0,.31-.05,.61-.25,.86-.26,.33-.61,.46-1.02,.44-.72-.04-1.17-.51-1.17-1.23,0-3.27,0-6.53,0-9.8,0-.61,.33-1.06,.91-1.2,.68-.16,1.34,.18,1.51,.89,.03,.13,.02,.28,.03,.41,0,1.6,0,3.2,0,4.8Z" />
                <path d="M16.09,4.8h-2.51c0-.87-.71-1.58-1.58-1.58s-1.58,.71-1.58,1.58h-2.51c-.71,0-1.28,.57-1.28,1.28h0c0,.71,.57,1.28,1.28,1.28h8.18c.71,0,1.28-.57,1.28-1.28h0c0-.71-.57-1.28-1.28-1.28Z" />
            </svg>
        ),
        visibility: (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 16.5C9.24 16.5 7 14.26 7 11.5C7 8.74 9.24 6.5 12 6.5C14.76 6.5 17 8.74 17 11.5C17 14.26 14.76 16.5 12 16.5ZM12 8.5C10.34 8.5 9 9.84 9 11.5C9 13.16 10.34 14.5 12 14.5C13.66 14.5 15 13.16 15 11.5C15 9.84 13.66 8.5 12 8.5Z"
                    fill="white"
                />
            </svg>
        ),
        'visibility-off': (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 6.49995C14.76 6.49995 17 8.73995 17 11.5C17 12.01 16.9 12.5 16.76 12.96L19.82 16.02C21.21 14.79 22.31 13.25 23 11.49C21.27 7.10995 17 3.99995 12 3.99995C10.73 3.99995 9.51 4.19995 8.36 4.56995L10.53 6.73995C11 6.59995 11.49 6.49995 12 6.49995ZM3.42 2.44995L2.01 3.86995L4.69 6.54995C3.06 7.82995 1.77 9.52995 1 11.5C2.73 15.89 7 19 12 19C13.52 19 14.97 18.7 16.31 18.18L19.74 21.61L21.15 20.2L3.42 2.44995ZM12 16.5C9.24 16.5 7 14.26 7 11.5C7 10.73 7.18 9.99995 7.49 9.35995L9.06 10.93C9.03 11.11 9 11.3 9 11.5C9 13.16 10.34 14.5 12 14.5C12.2 14.5 12.38 14.47 12.57 14.43L14.14 16C13.49 16.32 12.77 16.5 12 16.5ZM14.97 11.17C14.82 9.76995 13.72 8.67995 12.33 8.52995L14.97 11.17Z"
                    fill="white"
                />
            </svg>
        ),
        scale: (
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_4_389)">
                    <rect width="36" height="36" rx="8" fill="#878787" fillOpacity="0.01" />
                    <g clipPath="url(#clip0_4_389)">
                        <path
                            d="M26.8232 17.1768L27.25 17.6036V17V9V8.75H27H19H18.3964L18.8232 9.17678L21.9364 12.29L12.29 21.9364L9.17678 18.8232L8.75 18.3964V19V27V27.25H9H17H17.6036L17.1768 26.8232L14.0636 23.71L23.71 14.0636L26.8232 17.1768Z"
                            fill="white"
                            stroke="#8E8E8E"
                            strokeWidth="0.5"
                        />
                    </g>
                    <rect x="0.5" y="0.5" width="35" height="35" rx="7.5" stroke="#E5E5E5" />
                </g>
                <defs>
                    <filter
                        id="filter0_b_4_389"
                        x="-4"
                        y="-4"
                        width="44"
                        height="44"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4_389" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4_389" result="shape" />
                    </filter>
                    <clipPath id="clip0_4_389">
                        <rect width="24" height="24" fill="white" transform="translate(6 6)" />
                    </clipPath>
                </defs>
            </svg>
        ),
        AOI: (
            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13.5" cy="13.5" r="13.5" fill="#F96621" fillOpacity="0.35" />
                <rect
                    x="5.02718"
                    y="11.88"
                    width="10.1772"
                    height="13.9029"
                    transform="rotate(-45 5.02718 11.88)"
                    stroke="#F96621"
                    strokeWidth={'1px'}
                />
                <path d="M9.58911 17.1489L17.4926 9.24547" stroke="#F96621" strokeWidth={'1px'} />
                <path d="M6.95459 14.5145L14.858 6.61107" stroke="#F96621" strokeWidth={'1px'} />
                <path d="M12.2236 19.7834L20.1271 11.88" stroke="#F96621" strokeWidth={'1px'} />
            </svg>
        ),
        sigma: (
            <Box center="all" w={51} h={51}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="51"
                    height="51"
                    viewBox="0 0 51 51"
                    fill="none"
                    style={{ position: 'absolute', zIndex: '-1' }}
                >
                    <path
                        d="M25.1016 51C38.9648 51 50.2031 39.5833 50.2031 25.5C50.2031 11.4167 38.9648 0 25.1016 0C11.2384 0 0 11.4167 0 25.5C0 39.5833 11.2384 51 25.1016 51Z"
                        fill="url(#paint0_linear_49_1240)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_49_1240"
                            x1="50.2031"
                            y1="-16.3359"
                            x2="-28.2891"
                            y2="90.8438"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0.220866" stopColor="#FFE6C5" />
                            <stop offset="0.395833" stopColor="#F86623" />
                            <stop offset="0.763505" stopColor="#554FFC" />
                            <stop offset="0.994792" stopColor="#4A55C0" />
                        </linearGradient>
                    </defs>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.853233 1.52134C1.10227 0.93079 1.68083 0.546875 2.32174 0.546875H21.2656V3.73438H6.10097L13.2263 11.044C13.804 11.6367 13.8319 12.5729 13.2905 13.1989L5.62583 22.0625H21.2656V25.25H2.14065C1.51698 25.25 0.950622 24.8862 0.691256 24.319C0.431891 23.7519 0.527184 23.0855 0.935123 22.6138L9.92224 12.221L1.18049 3.2531C0.733125 2.79415 0.604192 2.11188 0.853233 1.52134Z"
                        fill="white"
                    />
                </svg>
            </Box>
        ),
        table: <FaTable />,
        logo: (
            <svg width="110" height="42" viewBox="0 0 110 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_11_2873)">
                    <path d="M81.7676 1.02148H77.9268V41.6799H81.7676V1.02148Z" fill={'url(#paint0_linear_11_2873)'} />
                    <path
                        d="M20.6596 42C9.27205 42 0 32.5939 0 21.0229C0 9.45193 9.27205 0.0305176 20.6596 0.0305176C32.0471 0.0305176 41.3191 9.45193 41.3191 21.0229C41.3191 32.5939 32.0471 42 20.6596 42ZM20.6596 3.93324C11.3875 3.93324 3.84085 11.6015 3.84085 21.0229C3.84085 30.4443 11.3875 38.1126 20.6596 38.1126C29.9316 38.1126 37.4783 30.4443 37.4783 21.0229C37.4783 11.6015 29.9316 3.93324 20.6596 3.93324Z"
                        fill={'url(#paint1_linear_11_2873)'}
                    />
                    <path
                        d="M49.5561 26.5263C43.5998 20.474 43.5998 10.6105 49.5561 4.55824C55.5124 -1.49402 65.2196 -1.49402 71.1759 4.55824L68.4603 7.31759C63.9893 2.77458 56.7277 2.78982 52.2717 7.31759C47.8007 11.8606 47.8007 19.2392 52.2717 23.767L49.5561 26.5263Z"
                        fill={'url(#paint2_linear_11_2873)'}
                    />
                    <path
                        d="M56.4129 41.817C52.467 41.817 48.5212 40.2925 45.5205 37.2435L48.2361 34.4842C52.7521 39.0729 60.0887 39.0729 64.5897 34.4842C69.1057 29.8954 69.1057 22.4406 64.5897 17.8671L67.3203 15.0925C73.3216 21.1905 73.3216 31.1303 67.3203 37.2435C64.3197 40.2925 60.3588 41.817 56.4129 41.817Z"
                        fill={'url(#paint3_linear_11_2873)'}
                    />
                    <path
                        d="M103.642 1.02148H98.7213C98.7213 1.02148 91.3847 10.1532 88.6541 13.8273C85.7435 17.7453 85.1433 23.2487 88.6091 28.1881C91.1297 31.8012 99.2164 41.6799 99.2164 41.6799H104.138C104.138 41.6799 94.9855 30.2309 92.675 27.1057C89.7194 23.1115 89.2092 19.6814 92.675 15.0012C95.6306 11.007 103.642 1.02148 103.642 1.02148Z"
                        fill={'url(#paint4_linear_11_2873)'}
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_11_2873"
                        x1="19.611"
                        y1="82.5711"
                        x2="124.207"
                        y2="-20.3663"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.2213" stopColor="#4954C0" />
                        <stop offset="0.27" stopColor="#855A8A" />
                        <stop offset="0.3258" stopColor="#C56150" />
                        <stop offset="0.3522" stopColor="#DE643A" />
                        <stop offset="0.3938" stopColor="#E86531" />
                        <stop offset="0.4821" stopColor="#F96621" />
                        <stop offset="0.5488" stopColor="#FF671C" />
                        <stop offset="0.5877" stopColor="#FF7833" />
                        <stop offset="0.6693" stopColor="#FFA56E" />
                        <stop offset="0.7774" stopColor="#FFE6C5" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_11_2873"
                        x1="4.77659"
                        y1="37.1541"
                        x2="91.9697"
                        y2="-48.6566"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.2213" stopColor="#4954C0" />
                        <stop offset="0.27" stopColor="#855A8A" />
                        <stop offset="0.3258" stopColor="#C56150" />
                        <stop offset="0.3522" stopColor="#DE643A" />
                        <stop offset="0.3938" stopColor="#E86531" />
                        <stop offset="0.4821" stopColor="#F96621" />
                        <stop offset="0.5488" stopColor="#FF671C" />
                        <stop offset="0.5877" stopColor="#FF7833" />
                        <stop offset="0.6693" stopColor="#FFA56E" />
                        <stop offset="0.7774" stopColor="#FFE6C5" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_11_2873"
                        x1="13.5372"
                        y1="52.1381"
                        x2="101.468"
                        y2="-34.3987"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.2213" stopColor="#4954C0" />
                        <stop offset="0.27" stopColor="#855A8A" />
                        <stop offset="0.3258" stopColor="#C56150" />
                        <stop offset="0.3522" stopColor="#DE643A" />
                        <stop offset="0.3938" stopColor="#E86531" />
                        <stop offset="0.4821" stopColor="#F96621" />
                        <stop offset="0.5488" stopColor="#FF671C" />
                        <stop offset="0.5877" stopColor="#FF7833" />
                        <stop offset="0.6693" stopColor="#FFA56E" />
                        <stop offset="0.7774" stopColor="#FFE6C5" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_11_2873"
                        x1="26.8057"
                        y1="67.3243"
                        x2="114.737"
                        y2="-19.2129"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.2213" stopColor="#4954C0" />
                        <stop offset="0.27" stopColor="#855A8A" />
                        <stop offset="0.3258" stopColor="#C56150" />
                        <stop offset="0.3522" stopColor="#DE643A" />
                        <stop offset="0.3938" stopColor="#E86531" />
                        <stop offset="0.4821" stopColor="#F96621" />
                        <stop offset="0.5488" stopColor="#FF671C" />
                        <stop offset="0.5877" stopColor="#FF7833" />
                        <stop offset="0.6693" stopColor="#FFA56E" />
                        <stop offset="0.7774" stopColor="#FFE6C5" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_11_2873"
                        x1="30.273"
                        y1="93.6678"
                        x2="129.773"
                        y2="-4.25457"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.2213" stopColor="#4954C0" />
                        <stop offset="0.27" stopColor="#855A8A" />
                        <stop offset="0.3258" stopColor="#C56150" />
                        <stop offset="0.3522" stopColor="#DE643A" />
                        <stop offset="0.3938" stopColor="#E86531" />
                        <stop offset="0.4821" stopColor="#F96621" />
                        <stop offset="0.5488" stopColor="#FF671C" />
                        <stop offset="0.5877" stopColor="#FF7833" />
                        <stop offset="0.6693" stopColor="#FFA56E" />
                        <stop offset="0.7774" stopColor="#FFE6C5" />
                    </linearGradient>
                    <clipPath id="clip0_11_2873">
                        <rect width="110" height="42" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
        'alert-marker': (
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="19" y="18" width="12" height="20" fill={color1} />
                <path
                    d="M9.31247 43.75H40.6875C43.8958 43.75 45.8958 40.2709 44.2916 37.5L28.6041 10.3959C27 7.62504 23 7.62504 21.3958 10.3959L5.7083 37.5C4.10413 40.2709 6.10413 43.75 9.31247 43.75ZM25 29.1667C23.8541 29.1667 22.9166 28.2292 22.9166 27.0834V22.9167C22.9166 21.7709 23.8541 20.8334 25 20.8334C26.1458 20.8334 27.0833 21.7709 27.0833 22.9167V27.0834C27.0833 28.2292 26.1458 29.1667 25 29.1667ZM27.0833 37.5H22.9166V33.3334H27.0833V37.5Z"
                    fill="#FF0000"
                />
            </svg>
        ),
        report: (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
                <path d="M10 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM12 16H4V14H12V16ZM12 12H4V10H12V12ZM9 7V1.5L14.5 7H9Z" />
            </svg>
        ),
        quilt: (
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13">
                <path d="M0 0V13H17V0H0ZM2 11V2H5V11H2ZM7 11V7.5H10V11H7ZM15 11H12V7.5H15V11ZM7 5.5V2H15V5.5H7Z" />
            </svg>
        ),
        digging: (
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <circle cx="18" cy="18" r="17" stroke={color1} strokeWidth="2" />
                <path
                    d="M7.90802 26.8894H29.1405C29.1574 25.7081 28.1936 24.8513 26.4461 24.5006C26.7368 23.2913 26.3036 22.3688 25.2705 21.72C24.2674 21.09 23.2361 21.0731 22.203 21.66C21.6161 20.0813 20.523 19.1044 18.8336 18.9806C17.0636 18.8494 15.8205 19.7119 15.0161 21.2888C14.343 20.6738 13.5686 20.4956 12.7324 20.8275C11.9093 21.1538 11.3824 21.75 11.3261 22.6725C9.22614 22.965 7.55552 24.6094 7.90802 26.8894V26.8894Z"
                    fill={color1}
                />
                <path
                    d="M14.7818 6.8325C15.1587 7.74 15.1399 8.61375 14.4687 9.40688C14.4293 9.45375 14.4537 9.59063 14.493 9.66C15.0443 10.6275 15.603 11.5913 16.1618 12.555C16.7355 13.5488 17.3112 14.5406 17.8943 15.5494C18.783 15.0356 19.6605 14.5313 20.5587 14.0138C20.6918 14.2125 20.8249 14.4019 20.9487 14.5969C21.798 15.9525 22.5105 17.3644 22.6924 18.9844C22.7618 19.6069 22.7374 20.2219 22.4899 20.8294C21.7324 19.4775 20.6449 18.6075 19.0999 18.4144C17.5624 18.2231 16.2762 18.7575 15.1868 19.9125C14.7818 19.1925 14.3918 18.5025 13.998 17.7994C14.9224 17.2669 15.7999 16.7625 16.6849 16.2525C16.6587 16.1981 16.6437 16.1606 16.6249 16.1269C15.5093 14.1975 14.3918 12.27 13.2837 10.3369C13.1993 10.1888 13.1074 10.1981 12.9799 10.2206C12.4587 10.3125 11.973 10.2206 11.5193 9.94688C10.7693 9.49875 10.3643 8.77875 9.97803 8.01375C11.3599 7.21875 12.7343 6.42938 14.1555 5.61188C14.3768 6.03375 14.613 6.41813 14.7837 6.83063L14.7818 6.8325ZM11.2174 8.60813C11.733 9.44438 12.5768 9.72938 13.4055 9.26813C14.2587 8.79375 14.5062 7.93313 14.0168 6.99938C13.0737 7.54125 12.1455 8.07563 11.2174 8.60813Z"
                    fill={color1}
                />
            </svg>
        ),
        'exposed-piping': (
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <circle cx="18" cy="18" r="17" stroke={color1} strokeWidth="2" />
                <path
                    d="M21.127 18.4547L21.7091 18.992L20.5205 20.1459C18.9961 18.6216 17.4412 17.0667 15.9148 15.5382C16.2812 15.1577 16.6618 14.7628 17.0688 14.3416L17.598 14.9175L18.1169 14.3293C16.7879 13.0004 15.4223 11.6347 14.0506 10.263L13.5622 10.7759L12.4082 9.61583C13.9244 8.09961 15.4834 6.54064 17.024 5L18.1739 6.14989L17.5328 6.71567C17.6672 6.80522 17.7547 6.84592 17.8198 6.91105C19.7288 8.81803 21.6439 10.7189 23.5367 12.6401C24.479 13.5967 24.4444 15.119 23.498 16.0796C22.7755 16.8123 22.0428 17.5348 21.3142 18.2613C21.2593 18.3163 21.2084 18.3733 21.1311 18.4547H21.127Z"
                    fill={color1}
                />
                <path
                    d="M14.1041 18.4566L13.5118 17.9091L14.678 16.743L19.3162 21.3812L18.1419 22.5514L17.629 22.0101L17.0897 22.5555C18.4309 23.8987 19.7904 25.2582 21.1682 26.6381L21.5651 26.1659C21.9864 26.6035 22.3629 26.9943 22.7252 27.3708C21.2761 28.8341 19.7863 30.3381 18.3088 31.8299C17.9262 31.4473 17.5415 31.0647 17.1487 30.6698L17.6392 30.2363C17.5415 30.1325 17.4805 30.0654 17.4174 30.0023C15.543 28.1279 13.6665 26.2555 11.7921 24.379C10.8539 23.4388 10.7358 22.0935 11.5092 21.0617C11.6903 20.8195 11.9162 20.6099 12.1299 20.3962C12.7771 19.753 13.4284 19.1181 14.1041 18.4505V18.4566Z"
                    fill={color1}
                />
            </svg>
        ),
        methane: (
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <circle cx="18" cy="18" r="17" stroke={color1} strokeWidth="2" />
                <circle cx="18.3172" cy="18.3168" r="3.01785" fill={color1} />
                <rect
                    x="15.2993"
                    y="26.799"
                    width="4.52677"
                    height="4.52677"
                    rx="2.26339"
                    transform="rotate(-45 15.2993 26.799)"
                    fill={color1}
                />
                <rect
                    x="15.2993"
                    y="10.201"
                    width="4.52677"
                    height="4.52677"
                    rx="2.26339"
                    transform="rotate(-45 15.2993 10.201)"
                    fill={color1}
                />
                <rect
                    x="25"
                    y="15.2007"
                    width="4.52677"
                    height="4.52677"
                    rx="2.26339"
                    transform="rotate(-45 25 15.2007)"
                    fill={color1}
                />
                <rect
                    x="6"
                    y="21.2008"
                    width="4.52677"
                    height="4.52677"
                    rx="2.26339"
                    transform="rotate(-45 6 21.2008)"
                    fill={color1}
                />
            </svg>
        ),
        hydrocarbon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
            >
                <circle cx="18" cy="18" r="17" stroke={color1} strokeWidth="2" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill={color1}
                    d="M18,8.97c0,0-6.04,8.73-6.04,12.04c0,3.31,2.72,6.02,6.04,6.02
	s6.04-2.71,6.04-6.02C24.04,17.7,18,8.97,18,8.97z M15.47,24.74c-1.62-1.11-2.16-3.15-1.21-4.54l5.88,4.04
	C19.19,25.63,17.1,25.86,15.47,24.74z"
                />
            </svg>
        ),
        vehicle: (
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <circle cx="18" cy="18" r="17" stroke={color1} strokeWidth="2" />
                <path
                    d="M24.92 11.0101C24.72 10.4201 24.16 10.0001 23.5 10.0001H12.5C11.84 10.0001 11.29 10.4201 11.08 11.0101L9.11 16.6801C9.04 16.8901 9 17.1101 9 17.3401V24.5001C9 25.3301 9.67 26.0001 10.5 26.0001C11.33 26.0001 12 25.3301 12 24.5001V24.0001H24V24.5001C24 25.3201 24.67 26.0001 25.5 26.0001C26.32 26.0001 27 25.3301 27 24.5001V17.3401C27 17.1201 26.96 16.8901 26.89 16.6801L24.92 11.0101ZM12.5 21.0001C11.67 21.0001 11 20.3301 11 19.5001C11 18.6701 11.67 18.0001 12.5 18.0001C13.33 18.0001 14 18.6701 14 19.5001C14 20.3301 13.33 21.0001 12.5 21.0001ZM23.5 21.0001C22.67 21.0001 22 20.3301 22 19.5001C22 18.6701 22.67 18.0001 23.5 18.0001C24.33 18.0001 25 18.6701 25 19.5001C25 20.3301 24.33 21.0001 23.5 21.0001ZM11 16.0001L12.27 12.1801C12.41 11.7801 12.79 11.5001 13.22 11.5001H22.78C23.21 11.5001 23.59 11.7801 23.73 12.1801L25 16.0001H11Z"
                    fill={color1}
                />
            </svg>
        ),
        'arrow-right-with-border': (
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_4_395)">
                    <g filter="url(#filter0_d_4_395)">
                        <path
                            d="M3.69024 10.505C3.93524 10.75 4.33024 10.75 4.57524 10.505L8.73024 6.35002C8.92524 6.15502 8.92524 5.84002 8.73024 5.64502L4.57524 1.49002C4.33024 1.24502 3.93524 1.24502 3.69024 1.49002C3.44524 1.73502 3.44524 2.13002 3.69024 2.37502L7.31024 6.00002L3.68524 9.62502C3.44524 9.86502 3.44524 10.265 3.69024 10.505V10.505Z"
                            fill="white"
                        />
                        <path
                            d="M3.51346 2.5518L6.95681 5.9999L3.50846 9.44825C3.17084 9.78587 3.17052 10.3449 3.51437 10.6827C3.85707 11.0244 4.40969 11.0241 4.75202 10.6818L8.90702 6.5268C9.19965 6.23417 9.19965 5.76088 8.90702 5.46825L4.75202 1.31325C4.40938 0.970617 3.85609 0.970617 3.51346 1.31325C3.17087 1.65584 3.17083 2.20904 3.51334 2.55168C3.51338 2.55172 3.51342 2.55176 3.51346 2.5518Z"
                            stroke="#8E8E8E"
                            strokeWidth="0.5"
                        />
                    </g>
                </g>
                <defs>
                    <filter
                        id="filter0_d_4_395"
                        x="-0.994141"
                        y="0.806274"
                        width="14.3706"
                        height="18.3824"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_395" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_395" result="shape" />
                    </filter>
                    <clipPath id="clip0_4_395">
                        <rect width="12" height="12" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
        layers: <FaLayerGroup />,
        'arrow-left-with-border': (
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_4_396)">
                    <g filter="url(#filter0_d_4_396)">
                        <path
                            d="M8.30976 1.49498C8.06476 1.24998 7.66976 1.24998 7.42476 1.49498L3.26976 5.64998C3.07476 5.84498 3.07476 6.15998 3.26976 6.35498L7.42476 10.51C7.66976 10.755 8.06476 10.755 8.30976 10.51C8.55476 10.265 8.55476 9.86998 8.30976 9.62498L4.68976 5.99998L8.31476 2.37498C8.55476 2.13498 8.55476 1.73498 8.30976 1.49498V1.49498Z"
                            fill="white"
                        />
                        <path
                            d="M8.48654 9.4482L5.04319 6.0001L8.49154 2.55175C8.82916 2.21413 8.82948 1.65512 8.48563 1.31729C8.14293 0.975567 7.59031 0.975869 7.24798 1.3182L3.09298 5.4732C2.80035 5.76583 2.80035 6.23912 3.09298 6.53175L7.24798 10.6868C7.59062 11.0294 8.14391 11.0294 8.48654 10.6868C8.82913 10.3442 8.82917 9.79096 8.48666 9.44832C8.48662 9.44828 8.48658 9.44824 8.48654 9.4482Z"
                            stroke="#8E8E8E"
                            strokeWidth="0.5"
                        />
                    </g>
                </g>
                <defs>
                    <filter
                        id="filter0_d_4_396"
                        x="-1.37646"
                        y="0.811279"
                        width="14.3706"
                        height="18.3824"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_396" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_396" result="shape" />
                    </filter>
                    <clipPath id="clip0_4_396">
                        <rect width="12" height="12" fill="white" transform="translate(12 12) rotate(180)" />
                    </clipPath>
                </defs>
            </svg>
        ),
        'location-crosshairs': (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100%">
                <path d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256a128 128 0 1 0 256 0 128 128 0 1 0 -256 0z" />
            </svg>
        ),
        'order-error': (
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 2326.2 2227.21">
                <g>
                    <path
                        style={{ fill: '#f2f2f2' }}
                        className="cls-1"
                        d="M1763.89,1105.13c0,310.38-251.62,562-562,562s-562-251.62-562-562c0-243.98,155.46-451.64,372.73-529.33,59.14-21.15,122.86-32.67,189.27-32.67,310.38,0,562,251.62,562,562Z"
                    />
                    <g>
                        <path
                            style={{ fill: 'none', stroke: '#626264', strokeMiterlimit: 10, strokeWidth: '9px' }}
                            className="cls-3"
                            d="M1201.89,471.13c167.57,0,319.95,65.01,433.3,171.17"
                        />
                        <path
                            style={{ fill: 'none', stroke: '#626264', strokeMiterlimit: 10, strokeWidth: '9px' }}
                            className="cls-3"
                            d="M778.61,633.11c33.86-30.38,71-57.17,110.83-79.78"
                        />
                        <path
                            style={{ fill: 'none', stroke: '#626264', strokeMiterlimit: 10, strokeWidth: '9px' }}
                            className="cls-3"
                            d="M615.78,1347.3c-16.28-39.35-28.7-80.69-36.82-123.56-7.27-38.42-11.07-78.07-11.07-118.6,0-46.34,4.97-91.51,14.41-135.02"
                        />
                        <path
                            style={{ fill: 'none', stroke: '#626264', strokeMiterlimit: 10, strokeWidth: '9px' }}
                            className="cls-3"
                            d="M1395.72,1708.96c-61.09,19.6-126.22,30.18-193.83,30.18s-128.98-9.98-188.53-28.5"
                        />
                        <path
                            style={{ fill: 'none', stroke: '#626264', strokeMiterlimit: 10, strokeWidth: '9px' }}
                            className="cls-3"
                            d="M1835.89,1105.13c0,88.14-17.99,172.08-50.49,248.35"
                        />
                    </g>
                    <g>
                        <path
                            style={{ fill: 'none', stroke: '#626264', strokeMiterlimit: 10, strokeWidth: '9px' }}
                            className="cls-3"
                            d="M621.2,718.9c102.08-153.47,254.45-253.44,420.74-292.59"
                        />
                        <path
                            style={{ fill: 'none', stroke: '#626264', strokeMiterlimit: 10, strokeWidth: '9px' }}
                            className="cls-3"
                            d="M511.7,1205.26c-7.2-49.52-9.11-99.86-5.55-150.11"
                        />
                        <path
                            style={{ fill: 'none', stroke: '#626264', strokeMiterlimit: 10, strokeWidth: '9px' }}
                            className="cls-3"
                            d="M1066.63,1789.48c-45.95-9.06-91.39-22.87-135.6-41.55-39.62-16.75-78.25-37.41-115.38-62.11-42.44-28.23-80.79-60.3-114.89-95.45"
                        />
                        <path
                            style={{ fill: 'none', stroke: '#626264', strokeMiterlimit: 10, strokeWidth: '9px' }}
                            className="cls-3"
                            d="M1873.02,1295.45c-19.27,67.89-49.26,133.99-90.44,195.91-40,60.13-87.71,112.05-140.96,155.32"
                        />
                        <path
                            style={{ fill: 'none', stroke: '#626264', strokeMiterlimit: 10, strokeWidth: '9px' }}
                            className="cls-3"
                            d="M1588.12,524.44c80.73,53.69,146.65,121.3,196.71,197.53"
                        />
                    </g>
                </g>
                <g>
                    <circle fill="#f15a24" className="cls-2" cx="1188.93" cy="796.13" r="23.11" />
                    <g>
                        <circle fill="#f15a24" className="cls-2" cx="1092.58" cy="811.32" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1005.64" cy="855.54" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="936.62" cy="924.47" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="892.27" cy="1011.35" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="876.95" cy="1107.68" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="892.14" cy="1204.03" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="936.36" cy="1290.97" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1005.28" cy="1359.99" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1092.16" cy="1404.34" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1188.5" cy="1419.66" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1284.85" cy="1404.47" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1371.79" cy="1360.25" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1440.81" cy="1291.33" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1485.15" cy="1204.45" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1500.48" cy="1108.11" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1485.29" cy="1011.76" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1441.07" cy="924.82" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1372.14" cy="855.8" r="23.11" />
                        <circle fill="#f15a24" className="cls-2" cx="1285.26" cy="811.46" r="23.11" />
                    </g>
                </g>
            </svg>
        ),
        'big-check-circle': (
            <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130" fill="none">
                <circle cx="65" cy="65" r="65" fill="#9DF3A4" />
                <g clipPath="url(#clip0_954_10258)">
                    <path
                        d="M96.6085 49.1601L96.6087 49.1599C98.2726 47.496 98.2726 44.8445 96.6087 43.1806C94.9447 41.5166 92.2933 41.5166 90.6294 43.1806L53.7008 80.1092L40.8738 67.2822C39.2098 65.6182 36.5584 65.6182 34.8944 67.2822C33.2305 68.9461 33.2305 71.5975 34.8944 73.2615L50.6735 89.0405C52.3374 90.7044 55.0263 90.7045 56.6903 89.0407C56.6903 89.0406 56.6904 89.0406 56.6904 89.0405L96.6085 49.1601Z"
                        fill="#017C0A"
                        stroke="#017C0A"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_954_10258">
                        <rect width="90.381" height="90.381" fill="white" transform="translate(19.8086 19.8096)" />
                    </clipPath>
                </defs>
            </svg>
        ),
        copy: <FaCopy />,
        gps: <FaGlobe />,
        gear: <FaCog />,
        'reg-square': <FaRegSquare />,
        'check-square': <FaCheckSquare />,
        'dash-square': <FaMinusSquare />,
        'caret-down': <FaCaretDown />,
        'times-circle': <FaTimesCircle />,
        square: <FaSquare />,
        circle: <FaCircle />,
        'reg-circle': <FaRegCircle />,
        'draw-polygon': <FaDrawPolygon />,
        trash: <FaTrash />,
        times: <FaTimes />,
        info: <FaInfo />,
        'shopping-cart': <FaShoppingCart />,
        'file-download': <FaFileDownload />,
        download: <FaDownload />,
        satellite: <FaSatellite />,
        user: <FaUser />,
        list: <FaList />,
        'th-large': <FaThLarge />,
        check: <FaCheck />,
        'location-arrow': <FaLocationArrow />,
        undo: <FaUndo />,
        folder: <FaFolder />,
        save: <FaSave />,
        upload: <FaUpload />,
        search: <FaSearch />,
        calendar: <FaCalendar />,
        'check-circle': <FaCheckCircle />,
        'dash-circle': <FaMinusCircle />,
        'arrow-up': <FaCaretUp />,
        'arrow-down': <FaCaretDown />,
        'arrow-left': <FaCaretLeft />,
        'arrow-right': <FaCaretRight />,
        plus: <FaPlus />,
        bell: <FaBell />,
        map: <FaMap />,
        email: <FaMailBulk />,
        company: <FaBuilding />,
        'clipboard-check': <FaClipboardCheck />,
        paperclip: <FaPaperclip />,
        edit: <FaPencilAlt />,
        filter: <FaFilter />,
        globe: <FaGlobeAmericas />,
        ellipsis: <FaEllipsisH />,
        sun: <FaSun />,
        cloud: <FaCloud />,
    };

    return iconMap[id];
};

type IconProps = {
    /** Class name used by styled */
    className?: string;
    /** The string code of the glyph to use */
    code?: OSKIconType;
    /** The fill color of the glyph */
    fill?: string;
    /** Optional alternate fill */
    altFill?: string;
    /** The height of the glyph */
    height?: number;
    /** The fill color to use on mouse-over */
    hoverFill?: string;
    /** The stroke color to use on mouse-over */
    hoverStroke?: string;
    /** The function that will be executed when clicked */
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    /** The stroke color of the glyph */
    stroke?: string;
    /** The stroke width of the glyph */
    strokeWidth?: number;
    /** The SVG element to render (in place of a code) */
    svg?: React.SVGProps<SVGSVGElement>;
    /** The width of the glyph */
    width?: number;
    /** The scale (in %, default 100) of the container surrounding the glyph */
    scale?: number;
} & Omit<BoxProps, 'ref'>;

const CoreIcon = ({
    className,
    svg,
    code,
    fill,
    altFill = 'white',
    stroke,
    hoverFill,
    hoverStroke,
    onClick,
    ...props
}: IconProps) => {
    return (
        <Box className={className} onClick={onClick} {...props}>
            {svg && svg}
            {!svg && code && SVGIconMap(code, altFill)}
        </Box>
    );
};

CoreIcon.defaultProps = {};

const OSKIcon = styled(CoreIcon)`
    transform: scale(${(props: any) => props.scale ?? 100}%);

    svg {
        fill: ${(props: any) => props.fill};
        stroke: ${(props: any) => props.stroke ?? props.theme.colors.white};
        stroke-width: ${(props: any) => `${props.strokeWidth ?? 0}px`};
        width: ${(props: any) => props.width}px;
        height: ${(props: any) => props.height}px;
    }
    svg:hover {
        fill: ${(props: any) => props.hoverFill};
        stroke: ${(props: any) => props.hoverStroke};
        ${(props: any) => props.onClick && 'cursor: pointer;'}
    }
`;

export type { IconProps, OSKIconType };
export { OSKIcon };
