import React from 'react';
import { useTheme } from 'styled-components';
import { Box, OSKIcon, OSKThemeType, Text } from 'oskcomponents';
import { OSKContentView } from '~/molecules/OSKContentView';

const NoProgramView = () => {
    const theme = useTheme() as OSKThemeType;

    document.title = 'Sigma';

    return (
        <OSKContentView>
            <Box center="all" grow>
                <Box w={500} center="all" col>
                    <OSKIcon code="user" fill="white" scale={300} h={30} />
                    <Text variant="large" style={{ textAlign: 'center' }} strong>
                        Your account has not been added to any programs yet. The admin for your program(s) will need to
                        do this before you can access Sigma.
                    </Text>
                </Box>
            </Box>
        </OSKContentView>
    );
};

export { NoProgramView };
