import { ControlPosition, Map } from 'leaflet';
import 'leaflet-editable';
import { noop } from 'oskcore';
import React, { useEffect, useRef, useState } from 'react';
import { ReactLeafletEditable } from 'react-leaflet-editable';
import { connect } from 'react-redux';
import { Map as OSKMap, MapProps } from '~/atoms/Map';
import { useToggles } from '~/hooks/useToggles';
import { RootState } from '~/redux/store';

type EditableMapProps = {
    /** Children components to pass to the map */
    children?: React.ReactNode;
    /** If true, the map will be in edit polygon mode which means
     * clicking on the map will begin drawing a polygon.
     */
    editPolygon?: boolean;
    /** Location of the zoom controls */
    zoomPosition?: ControlPosition;
} & MapProps;

type EditableMapContextType = {
    editRef: any;
    map: any;
};

const EditableMapContext = React.createContext({
    editRef: null as any,
    map: {} as any,
} as EditableMapContextType);

/**
 * This component is a react-wrapped map which supports the ability to enter
 * various edit modes through props..
 */
const EditableMap = ({ children, zoomPosition, ...props }: EditableMapProps) => {
    const [map, setMap] = useState<Map | null>(null);
    const editRef = useRef<any>(null);
    const toggles = useToggles();

    useEffect(() => {
        const fn = () => {
            // @ts-ignore
            map.invalidateSize(true);
        };

        // TODO: Something else. But this works ya know?
        const timerId = setInterval(fn, 1000);
        return () => {
            clearInterval(timerId);
        };
    }, [map]);

    // Hide maps if the relevant feature flags are set
    if (toggles.hideMaps()) {
        return <></>;
    }

    return (
        <React.Fragment>
            <EditableMapContext.Provider value={{ editRef, map }}>
                <ReactLeafletEditable ref={editRef} map={map}>
                    {
                        <OSKMap
                            zoomPosition={zoomPosition}
                            editable={true}
                            doubleClickZoom={false}
                            /* @ts-ignore */
                            whenCreated={setMap}
                            {...props}
                        >
                            {children}
                        </OSKMap>
                    }
                </ReactLeafletEditable>
            </EditableMapContext.Provider>
        </React.Fragment>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        editPolygon: state.data.map.editPolygon,
    };
};

export type { EditableMapProps, EditableMapContextType };
export { EditableMap, EditableMapContext };
export default connect(mapStateToProps, noop)(EditableMap);
