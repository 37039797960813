import React from 'react';
import { Box } from 'oskcomponents';
import { connect } from 'react-redux';
import { AppDispatch } from '~/redux/store';
import { noop, Program } from 'oskcore';
import { UserProgramCard } from '~/atoms';
import { setSessionProgramId } from '~/redux/modules/session';
import { useLocation } from 'react-router-dom';
import { getProgramId } from '~/utils';

export type UserProgramsListProps = {
    /** Handle nav change */
    onChangeProgram?: (id: number) => void;
    /** Array or User programs */
    programs: Array<Program>;
};

export const UserProgramsList = ({ programs, onChangeProgram }: UserProgramsListProps) => {
    const location = useLocation();
    const programId = getProgramId();

    return (
        <Box
            style={{
                justifyContent: 'space-around',
                paddingTop: '20px',
                overflow: 'scroll',
                overflowX: 'hidden',
                display: 'block',
                height: '100%',
            }}
        >
            {programs &&
                programs.map((item) => {
                    return (
                        <UserProgramCard
                            key={item.id}
                            active={item.id === programId}
                            companyName={item.company_name}
                            name={item.name}
                            onClick={() => {
                                // If this is not the active program, then we can safely switch to it.
                                // Otherwise, do nothing.
                                if (item.id !== programId) {
                                    onChangeProgram && onChangeProgram(item.id);
                                    // If we are looking at a program resource, change programs
                                    if (window.location.pathname.includes('/program')) {
                                        const findText = `/program/${programId}`;
                                        window.location.assign(
                                            location.pathname.replace(findText, `/program/${item.id}`),
                                        );
                                    } else {
                                        window.location.reload();
                                    }
                                }
                            }}
                        />
                    );
                })}
        </Box>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        onChangeProgram: (programId: number) => {
            // Close all nav items
            dispatch(setSessionProgramId(programId));
        },
    };
};

export default connect(noop, mapDispatchToProps)(UserProgramsList);
