import React, { useCallback, useEffect, useState } from 'react';
import { Box, OSKIcon, TextInput, OSKThemeType, Spinner, List } from 'oskcomponents';
import { debounce } from 'lodash';
import { geocode } from '../../geocode';
import { useTheme } from 'styled-components';
import { GlobalZIndex } from '~/constants';

type GeocodingTextBoxProps = {
    /** A method that's called when a location has been selected */
    onSelected?: (locations: google.maps.GeocoderResult) => void;
};

const GeocodingSearch = ({ onSelected }: GeocodingTextBoxProps) => {
    const theme = useTheme() as OSKThemeType;

    const [query, setQuery] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [geocodeResults, setGeocodeResults] = useState<google.maps.GeocoderResult[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<google.maps.GeocoderResult>();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGeoQuery = useCallback(
        debounce((q) => {
            setLoading(true);
            geocode(q)
                .then((result) => {
                    setGeocodeResults(result.results);
                })
                .finally(() => setLoading(false));
        }, 1000),
        [],
    );

    useEffect(() => {
        if (query && query !== selectedLocation?.formatted_address) {
            debouncedGeoQuery(query);
        } else {
            setGeocodeResults([]);
        }
    }, [query]);

    useEffect(() => {
        if (selectedLocation) {
            setQuery(selectedLocation?.formatted_address);
        }
    }, [selectedLocation]);

    return (
        <Box style={{ width: '100%' }} col>
            <TextInput
                variant="contrast"
                name={''}
                style={{ marginTop: '16px', width: '100%' }}
                icon={
                    loading ? (
                        <Spinner size="Tiny" variant="Circle" style={{ marginLeft: '0px' }} />
                    ) : (
                        <OSKIcon code="globe" height={44} />
                    )
                }
                placeholder="Location, e.g. Gulf of Mexico"
                value={selectedLocation ? selectedLocation.formatted_address : query}
                onChange={(value) => {
                    if (selectedLocation) {
                        setSelectedLocation(undefined);
                    }
                    setQuery(value.target.value);
                }}
                onFocus={(e) => {
                    e.target.select();
                }}
            />

            {geocodeResults.length > 0 && (
                <Box style={{ width: '100%', zIndex: GlobalZIndex.SearchPanel + 1 }}>
                    <Box
                        col
                        style={{
                            position: 'absolute',
                            borderRadius: '0px 0px 5px 5px',
                            marginTop: '-8px',
                            paddingTop: '8px',
                            backgroundColor: theme.colors.white,
                            overflow: 'hidden',
                            width: 'calc(100% - 32px)',
                            border: `1px solid ${theme.colors.black400}`,
                            borderTop: '',
                            filter: `drop-shadow(0 5px 2px ${theme.colors.gray3a} )`,
                        }}
                    >
                        <List
                            items={geocodeResults.map((result) => {
                                return {
                                    label: result.formatted_address,
                                    value: result,
                                };
                            })}
                            itemStyle={{ padding: '8px 14px' }}
                            variant="contrast"
                            onSelect={(item) => {
                                setSelectedLocation(item.value);
                                setGeocodeResults([]);

                                onSelected && onSelected(item.value);
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default GeocodingSearch;
