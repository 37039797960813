import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AdminPortal } from './views/AdminPortal';
import HomeView from './views/HomeView';
import NotFoundView from './views/NotFoundView';
import { ProgramView } from './views/ProgramView';
import { UserSettingsPortal } from './views/UserSettingsPortal';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { OSKThemeType } from 'oskcomponents';
import { AdminAPI, getBaseApi, getRelease, getEnvironment, isDev, GenericErrorView } from 'oskcore';
import { useProfileMetadata } from 'oskcore/src/hooks/useProfileMetadata';
import {
    createRoutesFromChildren,
    matchRoutes,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useNavigationType,
} from 'react-router-dom';
import { createGlobalStyle, useTheme } from 'styled-components';
import { ProfileSchema } from './organisms/forms/ProfileForm';
import { doPopulateAppAsync } from './redux/modules/osk';
import { configureUserSession, setSessionProfile, setSessionProgramId } from './redux/modules/session';
import { AppDispatch, RootState } from './redux/store';
import MonitorDashboardView from './views/MonitorDashboardView';
import MonitorMapView from './views/MonitorMapView';
import MonitorReportsView from './views/MonitorReportsView';
import { ProfileSetupView } from './views/ProfileSetupView';
import { RedirectToMonitorView } from './views/RedirectToMonitorView';
import { RedirectToHomeView } from './views/RedirectToHomeView';
import ProgramFindingsView from './views/ProgramFindingsView';
import AssetsView from './views/ProgramView/AssetsView';
import { NoProgramView } from './views/NoProgramView';
import OrderDashboardView from './views/OrderDashboardView';

import { getProgramId } from './utils';
import { AppNames } from '~/constants';
import { useCurrentApp } from 'oskcore/src/hooks';
import { ToggleContext } from './toggles/toggleContext';

const GlobalStyle = createGlobalStyle`
    body {
        background-color: ${(props: any) => props.theme.colors.primary.bg};
        color: ${(props: any) => props.theme.colors.primary.fg};
    }
`;

let SentryRoutes = Routes;

if (!isDev()) {
    // Compute the base api path, without the protocol
    const apiPath = getBaseApi().replace(/^https?:\/\//, '');
    Sentry.init({
        dsn: 'https://af2032e9c1524a0198e925499fe10105@o1355244.ingest.sentry.io/6639454',
        environment: getEnvironment(),
        release: getRelease(),
        integrations: [
            new BrowserTracing({
                tracingOrigins: ['localhost', apiPath, /^\//],
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],
        tracesSampleRate: 1.0,
    });

    SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
}

type AppProps = {
    initializing: boolean;
    initializeData: () => void;
};

function App({ initializing, initializeData }: AppProps) {
    const dispatch = useDispatch();
    const initialized = useRef(false);
    const theme = useTheme() as OSKThemeType;
    const currentApp = useCurrentApp();
    const navigate = useNavigate();
    const toggleRouter = useContext(ToggleContext);

    // Fetch user profile
    const [profile] = useProfileMetadata();
    const [profileValidated, setProfileValidated] = useState<boolean | null>(null);

    if (currentApp in AppNames) {
        document.title = AppNames[currentApp];
    }

    // If the user has no programs, redirect them to a safe landing page
    if ((!profile.programs || profile.programs.length === 0) && ['monitor', 'data'].includes(currentApp)) {
        navigate('/error/program');
    }

    // This effect will handle all one-shot processing operations
    // on-load.
    useEffect(() => {
        if (initialized.current === false) {
            initialized.current = true;
            // Initialize data
            initializeData();
        }

        // Check whether the profile is validated
        ProfileSchema.validate(profile)
            .then(() => {
                // Configure the user details in redux
                dispatch(configureUserSession(profile));
                if (profile.internal_osk_role === 'admin') {
                    toggleRouter.setFeature('osk_admin');
                }

                // Configure the programId
                const programList = profile.programs ?? [];
                const activeProgramId = getProgramId();
                const activeProgramList = programList.filter((program) => program.active);
                let actualProgramId;

                // Configure the program_id if applicable.
                if (activeProgramId !== null && activeProgramId >= 0) {
                    // Configure active program_id based on local storage.
                    actualProgramId = activeProgramId;
                } else if (activeProgramList.length > 0) {
                    actualProgramId = activeProgramList[0].id;
                } else if (programList.length > 0) {
                    actualProgramId = programList[0].id;
                }

                if (actualProgramId) {
                    // Set the active program
                    dispatch(setSessionProgramId(actualProgramId));

                    // Enrich user profile with information about the program
                    AdminAPI.getProgramProfile({
                        program: actualProgramId,
                    })
                        .then((resp) => {
                            if (resp.data.roles.includes('Program Administrator')) {
                                toggleRouter.setFeature('program_admin');
                            }

                            dispatch(setSessionProfile(resp.data));
                        })
                        .catch((ex) => {
                            console.error(ex);
                        })
                        .finally(() => {
                            setProfileValidated(true);
                        });
                } else {
                    setProfileValidated(true);
                }
            })
            .catch(() => setProfileValidated(false));
    }, [profile, dispatch, initializeData]);

    useEffect(() => {
        if (profile) {
            Sentry.setUser({
                email: profile.email,
            });
        }
    }, [profile]);

    return (
        <React.Fragment>
            <GlobalStyle theme={theme} />
            {/* If the profile is validated, we can render the main app */}
            {profileValidated && !initializing && (
                <React.Fragment>
                    <SentryRoutes>
                        <Route path="/error/program" element={<NoProgramView />} />
                        <Route path="/map" element={<RedirectToHomeView mode="search" />} />
                        <Route
                            errorElement={<GenericErrorView />}
                            path="/map/:position/"
                            element={<HomeView mode="search" />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path="/tasking"
                            element={<RedirectToHomeView mode="tasking" />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path="/tasking/:position/"
                            element={<HomeView mode="tasking" />}
                        />
                        <Route path="/" element={<RedirectToMonitorView />} />
                        <Route errorElement={<GenericErrorView />} path={'/admin'} element={<AdminPortal />} />
                        <Route errorElement={<GenericErrorView />} path={'/admin/:view'} element={<AdminPortal />} />
                        <Route errorElement={<GenericErrorView />} path="/settings" element={<UserSettingsPortal />} />
                        <Route
                            errorElement={<GenericErrorView />}
                            path={'/settings/:view'}
                            element={<UserSettingsPortal />}
                        />
                        <Route errorElement={<GenericErrorView />} path="/program/:id/*" element={<ProgramView />} />
                        <Route errorElement={<GenericErrorView />} path={'/orders'} element={<OrderDashboardView />} />
                        <Route
                            errorElement={<GenericErrorView />}
                            path={'/monitor'}
                            element={<MonitorDashboardView />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path={'/program/:programId/monitor'}
                            element={<MonitorDashboardView />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path={'/program/:programId/monitor/:assetId/grid'}
                            element={<MonitorDashboardView />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path="/program/:programId/monitor/:assetId/map"
                            element={<MonitorMapView />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path="/program/:programId/monitor/:assetId/map/alert/:alertId"
                            element={<MonitorMapView />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path="/program/:programId/monitor/:assetId/map/detection/:detectionId"
                            element={<MonitorMapView />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path={'/program/:programId/monitor/reports/'}
                            element={<MonitorReportsView />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path={'/program/:programId/monitor/reports/:reportId'}
                            element={<MonitorReportsView />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path={'/program/:programId/monitor/findings'}
                            element={<ProgramFindingsView />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path={'/program/:programId/monitor/findings/asset/:assetId'}
                            element={<ProgramFindingsView />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path={'/monitor/findings'}
                            element={<ProgramFindingsView />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path={'/monitor/findings/*'}
                            element={<ProgramFindingsView />}
                        />
                        <Route
                            errorElement={<GenericErrorView />}
                            path={'/program/:programId/asset/:assetId'}
                            element={<AssetsView />}
                        />
                        <Route element={<NotFoundView />} />
                    </SentryRoutes>
                </React.Fragment>
            )}

            {/* If the profile is not validated, we must require the user to fill it out */}
            {profileValidated === false && <ProfileSetupView />}
        </React.Fragment>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        initializing: state.osk.initializing,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        initializeData: () => {
            dispatch<any>(doPopulateAppAsync());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
