import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    DatePicker,
    Divider,
    Heading,
    ListEntry,
    MultiSelect,
    OSKIcon,
    OSKThemeType,
    SidePanel,
    SidePanelHandle,
    Text,
} from 'oskcomponents';
import { useDisableFeatureOnMouseOver, useMap } from '~/hooks';
import { OSKGeoJson, Sensor } from 'oskcore';
import { AppDispatch, RootState } from '~/redux/store';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { excludePlatform, includePlatform, setDateRange, NullableDate } from '~/redux/modules/data/search';
import { SidePanelCard } from '~/atoms';
import SearchDataCollectPreview from '~/organisms/SearchDataCollectPreview';
import { setWizardFlow } from '~/redux/modules/data/cart';
import GeocodingSearch from '~/organisms/GeocodingSearch';
import { LatLng } from 'leaflet';
import { useToggles } from '~/hooks/useToggles';

type SearchSidePanelProps = {
    /** Available Sensor options */
    sensors: Sensor[];
    /** List of sensors that are currently selected (for filtering) */
    selectedSensors: Array<number>;
};

const SearchSidePanel = ({ sensors, selectedSensors }: SearchSidePanelProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const map = useMap();
    const toggles = useToggles();

    const searchPanelHandle = useRef<SidePanelHandle>(null);
    const filterPanelHandle = useRef<SidePanelHandle>(null);
    const searchPanelRef = useRef<HTMLDivElement>(null);
    const filterPanelRef = useRef<HTMLDivElement>(null);

    useDisableFeatureOnMouseOver(searchPanelRef, 'Zoom', true);
    useDisableFeatureOnMouseOver(searchPanelRef, 'Drag', true);
    useDisableFeatureOnMouseOver(filterPanelRef, 'Zoom', true);
    useDisableFeatureOnMouseOver(filterPanelRef, 'Drag', true);

    const sensorOptions: ListEntry[] = sensors.map((sensor) => {
        const { osk_id, osk_sensor_name } = sensor;
        return { label: osk_sensor_name, value: osk_id };
    });

    const [searchStartDate, setSearchStartDate] = useState<NullableDate>(null);
    const [searchEndDate, setSearchEndDate] = useState<NullableDate>(null);
    const [cacheSelectedSensors, setCacheSelectedSensors] = useState<number[]>(selectedSensors);

    useEffect(() => {
        setCacheSelectedSensors(selectedSensors);
    }, [selectedSensors]);

    return (
        <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <SidePanel panelId="search-default" style={{ minHeight: 'min-content' }} ref={searchPanelHandle}>
                <Box ref={searchPanelRef} id="search-panel" col grow style={{ height: '100%' }}>
                    <Box style={{ display: 'block' }} col>
                        <Heading variant="small">Search Data</Heading>
                        {toggles.showGeocoding() && (
                            <GeocodingSearch
                                onSelected={(value) => {
                                    const {
                                        geometry: { location },
                                    } = value;
                                    const url = `/map/@${location.lat},${location.lng}`;
                                    navigate(url, { replace: true });

                                    // @ts-ignore Typescript thinks that lat / lng are both functions. They aren't.
                                    const coord = { lat: location.lat, lng: location.lng } as LatLng;
                                    map.fitCoordinates([OSKGeoJson.fromCoordinate(coord)], 0.1);
                                }}
                            />
                        )}
                    </Box>
                    <SidePanelCard>
                        <Text strong>Need Newer Imagery?</Text>
                        <Text variant="small" style={{ margin: '12px 0px', lineHeight: '1.5rem' }}>
                            Place a custom tasking order! Our satellites are standing by to assist you.
                        </Text>
                        <Button
                            variant="action"
                            style={{ width: '100%' }}
                            onClick={() => {
                                navigate('/tasking');
                            }}
                        >
                            <Text strong>Order</Text>
                        </Button>
                    </SidePanelCard>
                    <Box col style={{ paddingTop: '12px' }}>
                        <Box center="vertical" style={{ justifyContent: 'space-between' }}>
                            <Heading variant="small">Available Images</Heading>

                            <Button
                                inverted
                                onClick={() => {
                                    searchPanelHandle.current?.toggleSub(filterPanelHandle);
                                }}
                            >
                                <OSKIcon code="filter" />
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        col
                        style={{
                            display: 'block',
                            overflow: 'scroll',
                            height: '100%',
                            padding: '10px',
                        }}
                    >
                        <SearchDataCollectPreview />
                    </Box>

                    {/* DO NOT REMOVE this box!!! It adds padding. */}
                    <Box h={120} />

                    <Box
                        h={80}
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'white',
                            width: '100%',
                            padding: '4px 8px',
                            boxShadow: '0px -1px 17px rgba(0, 0, 0, 0.19)',
                        }}
                        center="all"
                    >
                        <Button
                            variant="action"
                            style={{ width: '100%', height: '40px' }}
                            onClick={() => {
                                dispatch(setWizardFlow('search'));
                            }}
                        >
                            <Box row center="all">
                                <OSKIcon code="shopping-cart" style={{ paddingRight: '8px' }} />
                                <Text strong>Checkout</Text>
                            </Box>
                        </Button>
                    </Box>
                </Box>
            </SidePanel>
            <SidePanel ref={filterPanelHandle} panelId="search-filter" expandable={false} visible={false}>
                <Box id="search-filter" grow col ref={filterPanelRef}>
                    <Heading variant="small" style={{ paddingLeft: '22px' }}>
                        Filters
                    </Heading>
                    <Divider style={{ margin: '20px 8px' }} />

                    <Text strong variant="large">
                        Satellites
                    </Text>
                    <Text variant="small" color={theme.colors.black500} style={{ padding: '4px 0px' }}>
                        Select your satellites.
                    </Text>

                    <MultiSelect
                        bg={theme.colors.primary.bg}
                        name="dataSource"
                        variant="primary"
                        items={sensorOptions}
                        values={cacheSelectedSensors}
                        inputStyle={{
                            backgroundColor: 'white',
                            color: theme.colors.black,
                            border: `1px solid ${theme.colors.gray75}`,
                            flexGrow: 0,
                            borderRadius: '9px',
                        }}
                        onChange={(selected, oldSelected) => {
                            setCacheSelectedSensors(selected.map((sensor) => sensor.value));
                        }}
                    />

                    <Divider style={{ margin: '20px 8px' }} />

                    <Text strong variant="large">
                        Date
                    </Text>
                    <Text variant="small" color={theme.colors.black500} style={{ padding: '4px 0px' }}>
                        Select a timeframe to search within.
                    </Text>
                    <Box style={{ justifyContent: 'space-between', paddingTop: '8px' }}>
                        <DatePicker
                            variant="contrast"
                            name="start-date"
                            defaultValue={searchStartDate}
                            style={{ marginRight: '2px' }}
                            onChange={(newDate: NullableDate) => setSearchStartDate(newDate ? newDate : null)}
                        />
                        <DatePicker
                            variant="contrast"
                            name="end-date"
                            defaultValue={searchEndDate}
                            onChange={(newDate: NullableDate) => setSearchEndDate(newDate ? newDate : null)}
                        />
                    </Box>
                    <Divider style={{ margin: '20px 8px' }} />
                    <Box col center="horizontal">
                        <Button
                            variant="action"
                            style={{ width: '200px' }}
                            onClick={() => {
                                dispatch(setDateRange(searchStartDate, searchEndDate));

                                sensorOptions.map((platform) => dispatch(excludePlatform(platform.value)));
                                cacheSelectedSensors.map((platform) => dispatch(includePlatform(platform)));
                            }}
                        >
                            <Text variant="small" strong>
                                Apply Filter
                            </Text>
                        </Button>
                        <Button variant="clear" style={{ width: '200px', marginTop: '8px' }}>
                            <Text variant="small" strong color={theme.colors.black}>
                                Reset All
                            </Text>
                        </Button>
                    </Box>
                </Box>
            </SidePanel>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    const { searchPanel } = state.data.search;
    const { footprints } = state.data.map;

    return {
        searchPanel,
        footprints,
        sensors: state.osk.sensors,
        selectedSensors: state.data.search.platforms,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        includePlatform: (platformId: number) => {
            dispatch(includePlatform(platformId));
        },
        excludePlatform: (platformId: number) => {
            dispatch(excludePlatform(platformId));
        },
    };
};

export { SearchSidePanel };
export default connect(mapStateToProps, mapDispatchToProps)(SearchSidePanel);
