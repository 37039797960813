import { Box, BoxProps, OSKThemeType } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';

export type SummarySectionProps = {
    children: React.ReactNode;
} & Omit<BoxProps, 'ref'>;

export const SummarySection = ({ children, ...props }: SummarySectionProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            style={{
                marginTop: '0.5rem',
                paddingTop: '0.5rem',
                borderTop: `1px solid ${theme.colors.gray80}`,
            }}
            col
            {...props}
        >
            {children}
        </Box>
    );
};
