import { Capture, OSKGeoJson, Sensor, SigmaAPI } from 'oskcore';
import { AppDispatch, RootState } from '../../store';
import { filter, flatMap } from 'lodash';
import { FootprintEntry, setFootprints } from './map';
import { add, format } from 'date-fns';
import { getProgramId } from '~/utils';
import { createSelector } from '@reduxjs/toolkit';

export type TimelineMode = 'daily' | 'weekly' | 'monthly' | 'yearly';

export type NullableDate = Date | null;

const SET_SEARCHING = 'SET_SEARCHING';
export function setSearching(isSearching: boolean) {
    return {
        type: SET_SEARCHING,
        payload: {
            isSearching,
        },
    };
}

const SET_ROI = 'SET_ROI';
export function setRoi(geoJson?: OSKGeoJson) {
    return {
        type: SET_ROI,
        payload: {
            geoJson,
        },
    };
}

const UPDATE_SEARCH_RESULT = 'UPDATE_SEARCH_RESULT';
export function updateSearchResults(result?: Array<Capture>) {
    return {
        type: UPDATE_SEARCH_RESULT,
        payload: {
            result,
        },
    };
}

const INCLUDE_PLATFORM = 'INCLUDE_PLATFORM';
export function includePlatform(platform: number) {
    return {
        type: INCLUDE_PLATFORM,
        payload: {
            platform,
        },
    };
}

const EXCLUDE_PLATFORM = 'EXCLUDE_PLATFORM';
export function excludePlatform(platform: number) {
    return {
        type: EXCLUDE_PLATFORM,
        payload: {
            platform,
        },
    };
}

const SET_SEARCH_ERROR = 'SET_SEARCH_ERROR';
export function setSearchError(errorMessage?: string) {
    return {
        type: SET_SEARCH_ERROR,
        payload: {
            errorMessage,
        },
    };
}

const SET_DATE_RANGE = 'SET_DATE_RANGE';
export function setDateRange(start: NullableDate, end: NullableDate) {
    return {
        type: SET_DATE_RANGE,
        payload: {
            start,
            end,
        },
    };
}

const CLEAR_SEARCH_ERROR = 'CLEAR_SEARCH_ERROR';
export function clearSearchError() {
    return {
        type: CLEAR_SEARCH_ERROR,
    };
}

const SET_TIMELINE_MODE = 'SET_TIMELINE_MODE';
export function setTimelineMode(mode: TimelineMode) {
    return {
        type: SET_TIMELINE_MODE,
        payload: {
            mode,
        },
    };
}

const SET_TIMELINE_DATE = 'SET_TIMELINE_DATE';
export function setTimelineDate(date: NullableDate) {
    return {
        type: SET_TIMELINE_DATE,
        payload: {
            date,
        },
    };
}

const TOGGLE_SEARCH_PANEL = 'TOGGLE_SEARCH_PANEL';
export function toggleSearchPanel() {
    return {
        type: TOGGLE_SEARCH_PANEL,
        payload: {},
    };
}
/*
    Filter Methods
*/

function computeStartDate(startDate: NullableDate, timelineDate: NullableDate): Date | undefined {
    if (timelineDate === null) {
        return startDate ?? undefined;
    } else {
        return timelineDate;
    }
}

function computeEndDate(
    endDate: NullableDate,
    timelineDate: NullableDate,
    timelineMode: TimelineMode,
): Date | undefined {
    if (timelineDate === null) {
        return endDate ?? undefined;
    } else {
        switch (timelineMode) {
            case 'daily':
                return timelineDate;
            case 'weekly':
                return add(timelineDate, { weeks: 1 });
            case 'monthly':
                return add(timelineDate, { months: 1 });
            case 'yearly':
                return add(timelineDate, { years: 1 });
        }
    }
}

export function doSearchAsync() {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const { roi, startDate, endDate, platforms, timelineMode, timelineDate } = getState().data.search;

        // Compute the time periods
        const start = computeStartDate(startDate, timelineDate);
        const end = computeEndDate(endDate, timelineDate, timelineMode);
        const aoiFilter: any =
            roi !== undefined && roi !== null && (roi as OSKGeoJson).features.length > 0
                ? JSON.stringify((roi as OSKGeoJson).toAPIGeometry())
                : undefined;

        dispatch(setSearching(true));
        // @ts-ignore because the typescript-axios client expects a Geometry object for ROI but doesn't serialize it properly.
        SigmaAPI.listCaptures({
            program: getProgramId(),
            aoi: aoiFilter,
            capturedAfter: start ? format(start, 'yyyy-MM-dd') : undefined,
            capturedBefore: end ? format(end, 'yyyy-MM-dd') : undefined,
            sensor: platforms,
            limit: 1000,
        })
            .then((result) => {
                // Extract footprints
                const footprints: Array<FootprintEntry> = flatMap(result.data.results, (result) => ({
                    fileId: result.id,
                    taskId: result.task_id,
                    footprint: OSKGeoJson.fromAPIGeometry(result.footprint),
                }));

                dispatch(clearSearchError());
                dispatch(updateSearchResults(result.data.results));

                // Beam footprints over to map store
                dispatch(setFootprints(footprints));
            })
            .catch((ex) => {
                dispatch(setSearchError(ex.message));
            })
            .finally(() => {
                dispatch(setSearching(false));
            });
    };
}

/* Reducer */
type SearchStateType = {
    errorMessage?: string;
    roi?: OSKGeoJson;
    isErrored: boolean;
    isSearching: boolean;
    results?: Array<Capture>;
    resultMap: Record<string, Capture>;
    fileIdToCollectMap?: Record<string, string>;
    /** An object containing collect to fileId mapping. The key is collect, the value is an array of fileIds */
    collectToFileIdList: Record<string, string[]>;
    platforms: Array<number>;
    startDate: NullableDate;
    endDate: NullableDate;
    timelineMode: TimelineMode;
    timelineDate: NullableDate;
    searchPanel: boolean;
};

const initialState: SearchStateType = {
    errorMessage: undefined,
    resultMap: {},
    fileIdToCollectMap: {},
    collectToFileIdList: {},
    roi: undefined,
    isErrored: false,
    isSearching: false,
    results: [],
    platforms: [],
    startDate: null,
    endDate: null,
    timelineMode: 'daily',
    timelineDate: null,
    searchPanel: true,
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_ROI: {
            const { geoJson } = action.payload;
            return {
                ...state,
                roi: geoJson,
            };
        }

        case CLEAR_SEARCH_ERROR: {
            return {
                ...state,
                isErrored: false,
                errorMessage: undefined,
            };
        }

        case SET_SEARCH_ERROR: {
            const { errorMessage } = action.payload;
            return {
                ...state,
                isErrored: errorMessage !== undefined,
                errorMessage,
            };
        }

        case SET_SEARCHING: {
            const { isSearching } = action.payload;
            return {
                ...state,
                isSearching,
            };
        }

        case UPDATE_SEARCH_RESULT: {
            const { result } = action.payload;
            const fileIdToCollectMap: Record<string, string> = {};
            const collectToFileIdList: Record<string, string[]> = {};
            const resultMap: Record<string, Capture> = {};

            result.forEach((result: Capture) => {
                fileIdToCollectMap[result.id] = result.task_id;
                collectToFileIdList[result.task_id] = collectToFileIdList[result.task_id] ?? [];
                collectToFileIdList[result.task_id].push(result.id);
                resultMap[result.id] = result;
            });

            return {
                ...state,
                results: result,
                fileIdToCollectMap,
                collectToFileIdList,
                resultMap,
            };
        }

        case INCLUDE_PLATFORM: {
            const { platform }: any = action.payload;
            const nextPlatforms = [...state.platforms];
            if (!nextPlatforms.includes(platform)) {
                nextPlatforms.push(platform);
            }

            return {
                ...state,
                platforms: nextPlatforms,
            };
        }

        case SET_TIMELINE_DATE: {
            const { date }: any = action.payload;
            return {
                ...state,
                timelineDate: date,
            };
        }

        case SET_TIMELINE_MODE: {
            const { mode }: any = action.payload;
            return {
                ...state,
                timelineMode: mode,
            };
        }

        case SET_DATE_RANGE: {
            const { start, end }: any = action.payload;
            return {
                ...state,
                startDate: start,
                endDate: end,
            };
        }

        case EXCLUDE_PLATFORM: {
            const { platform } = action.payload;
            const nextPlatforms = [...state.platforms];
            if (nextPlatforms.includes(platform)) {
                nextPlatforms.splice(nextPlatforms.indexOf(platform), 1);
            }
            return {
                ...state,
                platforms: nextPlatforms,
            };
        }

        case TOGGLE_SEARCH_PANEL: {
            const { searchPanel } = state;
            return { ...state, searchPanel: !searchPanel };
        }

        default:
            return { ...state };
    }
}

/* Selectors */
export const hasRoI = (state: RootState) => {
    return state.data.search.roi !== undefined && (state.data.search.roi as OSKGeoJson).toCoordinates().length > 0;
};

export const canSearch = (state: RootState) => {
    return (
        state.data.search.endDate !== null ||
        state.data.search.startDate !== null ||
        hasRoI(state) ||
        state.data.search.platforms.length > 0
    );
};

/* Selectors */
export const selectedSensors = (state: RootState) => {
    return filter(state.osk.sensors, (sensor: Sensor) => state.data.search.platforms.includes(sensor.osk_id));
};

function filterFilesByCaptureDateRange(
    files: Capture[],
    filterStartDate: Date | undefined,
    filterEndDate: Date | undefined,
) {
    return files.filter((file) => {
        if (file.acquisition_time && filterStartDate && filterEndDate) {
            const date = new Date(file.acquisition_time);
            return date.getTime() >= filterStartDate.getTime() && date.getTime() <= filterEndDate?.getTime();
        } else {
            return true;
        }
    });
}

export const getGroupedFiles = createSelector(
    (state: RootState) => state.data.search.results,
    (state: RootState) => state.data.search.startDate,
    (state: RootState) => state.data.search.endDate,
    (state: RootState) => state.data.search.platforms, // selected sensors
    (items: Capture[], startDate: Date, endDate: Date, platforms: number[]) => {
        return filterFilesByCaptureDateRange(items, startDate, endDate)
            .filter((item) => platforms.includes(item.sensor_id)) // Filter by selected sensor
            .reduce((groupedFiles: Record<string, Capture[]>, file: Capture) => {
                const { task_id } = file;
                if (groupedFiles.hasOwnProperty(task_id)) {
                    groupedFiles[task_id] = groupedFiles[task_id].concat(file);
                } else {
                    groupedFiles[task_id] = [file];
                }
                return groupedFiles;
            }, {});
    },
);
