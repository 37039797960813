import { useProfileMetadata } from 'oskcore';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from '~/redux/store';
import { getProgramId } from '~/utils';

/***
 * This method will ensure that the url contains valid parameters
 * and if they're missing or wrong, it'll redirect to the good place.
 * This should be generally used in all top-level views.
 *
 * The required parameter is the templated url to redirect to, if things are wonky.
 * The possible templated values are :programId which will be replaced
 * with the correct programId based on business logic.
 */
export function useEnsureUrlParameters(redirectUri: string) {
    const navigate = useNavigate();
    const location = useLocation();
    const { assetId, reportId } = useParams();
    const assetIsLoading = useSelector((state: RootState) => state.monitor.app.fetchingAssets);
    const reportsAreLoading = useSelector((state: RootState) => state.monitor.reports.isFetching);
    const reportList = useSelector((state: RootState) => state.monitor.reports.reports);
    const assetMap = useSelector((state: RootState) => state.monitor.app.assets);
    const [profile] = useProfileMetadata();
    const currentProgramId = getProgramId();
    const assetList = assetMap ? Object.values(assetMap) : [];
    const assetIsInProgram = assetId ? !!assetList.find((x) => `${x.id}` === assetId) : true;
    const firstProgramId = profile.programs && profile.programs?.length > 0 ? profile.programs[0].id : undefined;
    const userIsInProgram =
        profile.programs && profile.programs?.findIndex((program) => program.id === currentProgramId) >= 0;
    const reportIsInProgram =
        !reportsAreLoading && reportList.length > 0 && reportId
            ? !!reportList.find((x) => x.external_id === reportId)
            : true;

    useEffect(() => {
        if (profile.programs && profile.programs.length > 0) {
            if (!location.pathname.includes('/program')) {
                navigate(`/program/${currentProgramId}${window.location.pathname}`);
            } else if (!userIsInProgram) {
                window.location.assign(redirectUri.replace('/:programId', `/${firstProgramId}`));
            } else if (!assetIsInProgram && !assetIsLoading) {
                navigate(redirectUri.replace('/:programId', `/${currentProgramId}`));
            } else if (!reportIsInProgram && !reportsAreLoading) {
                navigate(redirectUri.replace('/:programId', `/${currentProgramId}`));
            }
        }
    }, [
        location,
        assetMap,
        assetId,
        assetIsLoading,
        firstProgramId,
        assetIsInProgram,
        profile.programs,
        redirectUri,
        currentProgramId,
        userIsInProgram,
        reportIsInProgram,
        reportsAreLoading,
        navigate,
    ]);
}
