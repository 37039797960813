/** Features that are supported by the toggleRouter system */
export type ToggleFeature = 'program_admin' | 'osk_admin' | 'running_in_test_mode';

/**
 * A class for managing toggle features.
 */
export class ToggleRouter {
    features: ToggleFeature[];

    constructor() {
        this.features = [];
    }

    /** Return true if the requested feature is set */
    isFeatureSet(feature: ToggleFeature) {
        return this.features.includes(feature);
    }

    /** Turn on a specific feature */
    setFeature(feature: ToggleFeature) {
        this.features.push(feature);
    }

    /** Turn on many features at once */
    setFeatures(features: ToggleFeature[]) {
        for (const feature of features) {
            this.setFeature(feature);
        }
    }

    /** Turn off a feature */
    unsetFeature(feature: ToggleFeature) {
        while (this.features.includes(feature)) {
            this.features.splice(this.features.indexOf(feature), 1);
        }
    }

    /** Turn off many features at once */
    unsetFeatures(features: ToggleFeature[]) {
        for (const feature of features) {
            this.unsetFeature(feature);
        }
    }
}
