import React from 'react';
import { Box, BoxProps, OSKIcon, OSKThemeType, Text } from 'oskcomponents';
import { Capture, fround, noop, OSKGeoJson } from 'oskcore';
import CSS from 'csstype';
import { useTheme } from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { date_format_long } from '~/utils';
import { dequeueFile, enqueueFile, toggleFileIntent } from '~/redux/modules/data/cart';
import { RootState } from '~/redux/store';

export type CaptureItemProps = {
    /** The fileId (capture.id) this item represents */
    fileId: string | number;
    /** The capture to populate this item with */
    data: Capture;
    /** A style to override the container with */
    style?: CSS.Properties;
    /** Whether this item is actively selected or not */
    selected?: boolean;
    /** Whether to hide the selection functionality or not */
    hideIcon?: boolean;
    /** Optional click event to invoke when the item is selected */
    onClick?: () => void;
    /** If true, all click actions will only affect the DownloadIntent.skip flag */
    intentOnly?: boolean;
} & Omit<Omit<BoxProps, 'ref'>, 'onClick'>;

export const CaptureItem = ({
    fileId,
    onClick,
    intentOnly,
    selected,
    style,
    data,
    hideIcon,
    ...props
}: CaptureItemProps) => {
    const theme = useTheme() as OSKThemeType;
    const geojson = data.footprint ? OSKGeoJson.fromAPIGeometry(data.footprint) : undefined;
    const dispatch = useDispatch();

    return (
        <Box
            style={{
                justifyContent: 'space-around',
                padding: '8px',
                paddingLeft: '16px',
                paddingRight: '0px',
                opacity: selected ? 1.0 : 0.5,
                borderLeft: `3px solid ${selected ? 'rgba(249, 102, 33, 0.7)' : 'transparent'}`,
                ...style,
            }}
            onClick={function (evt) {
                evt.stopPropagation();
                evt.preventDefault();

                if (intentOnly) {
                    dispatch(toggleFileIntent(`${data.id}`));
                } else {
                    if (!selected) {
                        dispatch(
                            enqueueFile(
                                {
                                    taskId: data.task_id,
                                    fileId: data.id,
                                    skip: false,
                                },
                                data,
                            ),
                        );
                    } else {
                        dispatch(dequeueFile(`${fileId}`));
                    }
                }
            }}
            grow
            {...props}
        >
            <Box col>
                <Text
                    style={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    variant="small"
                    title={`${fileId}`}
                >
                    {date_format_long(data.acquisition_time)}
                </Text>
                <Text variant="small">
                    {geojson ? (
                        <>
                            {fround(geojson.getCentroid().lat, 3)}, {fround(geojson.getCentroid().lng, 3)}
                        </>
                    ) : (
                        ''
                    )}
                </Text>
            </Box>
            <Box grow />
            {hideIcon !== true && (
                <Box center="all" w={40} style={{ marginRight: '7px' }}>
                    <OSKIcon fill={theme.colors.accent} code={selected ? 'trash' : 'plus'} />
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState, ownProps: Partial<CaptureItemProps>) => {
    const { fileId } = ownProps;
    const enqueuedItem = state.data.cart.enqueued[fileId ?? '-1'];

    return {
        selected: enqueuedItem ? enqueuedItem.skip !== true : false,
        data: state.data.search.resultMap[fileId ?? '-1'],
    };
};

export default connect(mapStateToProps, noop)(CaptureItem);
