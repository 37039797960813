import React from 'react';
import { Pagination, PaginationProps } from 'oskcomponents';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '~/redux/store';
import { SHORT_VISIBLE_PAGE_SIZE } from '~/pagination';
import { setOrderPage } from '~/redux/modules/data/orders';

export type TaskingOrderPaginatorProps = {
    /** Total count of visible catalog items */
    totalCatalogItems?: number;
    /** Current page for pagination from redux */
    currentPage?: number;
    /** Event that fires when the user initiates a page change */
    changePage?: (pageNumber: number) => void;
    /** Filters that are being applied to the catalog list */
    filters?: string[];
} & Omit<PaginationProps, 'offset'>;

const TaskingOrderPaginator = ({
    changePage,
    totalCatalogItems,
    currentPage,
    ...props
}: TaskingOrderPaginatorProps) => {
    return (
        <Pagination
            onChange={changePage}
            count={totalCatalogItems ?? 0}
            pageSize={SHORT_VISIBLE_PAGE_SIZE}
            offset={(currentPage ?? 0) * SHORT_VISIBLE_PAGE_SIZE}
            {...props}
        />
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        totalCatalogItems: state.data.orders.totalTaskingOrders,
        currentPage: state.data.orders.taskingOrderPage,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        changePage: (pageNumber: number) => {
            dispatch(setOrderPage('tasking', pageNumber));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskingOrderPaginator);
