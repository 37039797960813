import { Opportunity, OSKGeoJson, SigmaAPI } from 'oskcore';
import { RootState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';
import { SHORT_VISIBLE_PAGE_SIZE } from '~/pagination';
import { NullableDate } from 'oskcomponents';

export type TaskingInstance = {
    taskId: number;
    userTaskName: string;
    createdOn: number;
    dataSource: string;
    startDate: number;
    endDate: number;
    coverage: number;
    coordinates: string;
    status: string;
    fileId: number;
};

export type AOIProgress = {
    name: string;
    steps: AOIStep[];
    aoi?: OSKGeoJson;
    errorReason?: string;
};

export type AOIStep = {
    name: string;
    timestamp?: number;
    current?: boolean;
};

export type Tick = {
    start_time: Date;
    end_time: Date;
    nadir_fov: OSKGeoJson;
    sensor: number;
};

/********************
 Actions
 ********************/

const SET_LOADING_AOI_LIST = 'SET_LOADING_AOI_LIST';
export function setLoadingAoiList(isLoading: boolean) {
    return {
        type: SET_LOADING_AOI_LIST,
        payload: { isLoading },
    };
}

const SET_LOADING_TASKING_LIST = 'SET_LOADING_TASKING_LIST';
export function setLoadingTaskingList(isLoading: boolean) {
    return {
        type: SET_LOADING_TASKING_LIST,
        payload: { isLoading },
    };
}

const SET_TASKING_LIST = 'SET_TASKING_LIST';
export function setTaskingList(taskingList: Array<TaskingInstance>) {
    return {
        type: SET_TASKING_LIST,
        payload: { taskingList },
    };
}

const SET_CATALOG_LIST = 'SET_CATALOG_LIST';
export function setCatalogList(catalogList: Array<TaskingInstance>) {
    return {
        type: SET_CATALOG_LIST,
        payload: { catalogList },
    };
}

const SET_ACTIVE_TASKING_INSTANCE = 'SET_ACTIVE_TASKING_INSTANCE';
export function setActiveTaskingInstance(taskingInstance: TaskingInstance) {
    return {
        type: SET_ACTIVE_TASKING_INSTANCE,
        payload: { taskingInstance },
    };
}

const SET_ACTIVE_AOI_LIST = 'SET_ACTIVE_AOI_LIST';
export function setActiveAoiList(aoiList: Array<AOIProgress>) {
    return {
        type: SET_ACTIVE_AOI_LIST,
        payload: { aoiList },
    };
}

const SET_TASKINGS_PAGE = 'SET_TASKINGS_PAGE';
export function setTaskingsPage(taskingsPage: number) {
    return {
        type: SET_TASKINGS_PAGE,
        payload: { taskingsPage },
    };
}

const SET_CATALOG_PAGE = 'SET_CATALOG_PAGE';
export function setCatalogPage(catalogPage: number) {
    return {
        type: SET_CATALOG_PAGE,
        payload: { catalogPage },
    };
}

const SET_SELECTED_AOI = 'SET_SELECTED_AOI';
export function setSelectedAoi(selectedAoi: OSKGeoJson) {
    return {
        type: SET_SELECTED_AOI,
        payload: { selectedAoi },
    };
}

const SET_TASKING_SEARCH_START_DATE = 'SET_TASKING_SEARCH_START_DATE';
export function setTaskingSearchStartDate(taskingSearchStartDate: NullableDate) {
    return {
        type: SET_TASKING_SEARCH_START_DATE,
        payload: { taskingSearchStartDate },
    };
}

const SET_TASKING_SEARCH_END_DATE = 'SET_TASKING_SEARCH_END_DATE';
export function setTaskingSearchEndDate(taskingSearchEndDate: NullableDate) {
    return {
        type: SET_TASKING_SEARCH_END_DATE,
        payload: { taskingSearchEndDate },
    };
}

const SET_SELECTED_OPPORTUNITY = 'SET_SELECTED_OPPORTUNITY';
export function setSelectedOpportunity(selectedOpportunity: Opportunity) {
    return {
        type: SET_SELECTED_OPPORTUNITY,
        payload: { selectedOpportunity },
    };
}

/**
 * An opportunity window represents a group of ticks returned from the propagator
 * which have been aggregated based on start_time and one orbital period for the given
 * sensor which generated the ticks.
 */
export type OpportunityWindow = {
    /** An array of ticks associated with this opportunity. */
    ticks: Array<Opportunity>;
    /** The sensor which corresponds with the ticks. */
    sensor: number;
    /** The moment in which the sensor will pass over the first tick in the list. */
    start_time: Date;
    /** The moment in which the sensor will stop passing over the last tick in the list. */
    end_time: Date;
    /** The TLE of the datasource for this opportunity. */
    tle: string;
};

/**
 * The TLE map. Key is sensor_id, value is the associated TLE.
 */
export const SensorTLEMap: Record<number, string> = {
    1: `DEMO9 (AURORA)
1 48956U 21059CF  22137.58563099  .00007241  00000+0  38541-3 0  9996
2 48956  97.5518 268.0314 0016615 203.3094 156.7387 15.15421030 49308`,
    2: `DEMO9 (AURORA)
1 48956U 21059CF  22137.58563099  .00007241  00000+0  38541-3 0  9996
2 48956  97.5518 268.0314 0016615 203.3094 156.7387 15.15421030 49308`,
    7: `GHOST-1                 
1 56195U 23054T   23150.29074037  .00005208  00000+0  22763-3 0  9990
2 56195  97.4056  45.4599 0012802  80.7315 279.5368 15.22454290  7452`,
    8: `GHOST-2                 
1 56197U 23054V   23150.28305039  .00006279  00000+0  27123-3 0  9994
2 56197  97.4053  45.4686 0013716  81.9094 278.3698 15.22761880  7459`,
};

export const fetchOpportunityAsync = (area: OSKGeoJson) => {
    return new Promise<Array<Opportunity>>((resolve, reject) => {
        SigmaAPI.searchOrbits({
            aoi: JSON.stringify(area.toAPIGeometry()) as any,
            limit: 100000,
        })
            .then((resp) => {
                resolve(resp.data.results ?? []);
            })
            .catch((ex) => {
                console.error(ex);
                reject(ex);
            });
    });
};

/* Reducer */
type TaskingReducerType = {
    isLoadingTaskingList: boolean;
    isLoadingAois: boolean;
    taskingList: Array<TaskingInstance>;
    catalogList: Array<TaskingInstance>;
    activeTaskingInstance: TaskingInstance | null;
    activeAoiList: Array<AOIProgress>;
    taskingsPage: number;
    catalogPage: number;
    selectedAoi?: OSKGeoJson;
    taskingSearchStartDate: NullableDate;
    taskingSearchEndDate: NullableDate;
    selectedOpportunity?: Opportunity;
};

const initialState: TaskingReducerType = {
    isLoadingTaskingList: false,
    isLoadingAois: false,
    activeTaskingInstance: null,
    taskingList: [],
    catalogList: [],
    activeAoiList: [],
    taskingsPage: 0,
    catalogPage: 0,
    selectedAoi: undefined,
    taskingSearchStartDate: null,
    taskingSearchEndDate: null,
    selectedOpportunity: undefined,
};

export default function reducer(state = initialState, action: any): TaskingReducerType {
    switch (action.type) {
        case SET_LOADING_AOI_LIST: {
            const { isLoading } = action.payload;
            return {
                ...state,
                isLoadingAois: isLoading,
            };
        }

        case SET_ACTIVE_TASKING_INSTANCE: {
            const { taskingInstance } = action.payload;
            return {
                ...state,
                activeTaskingInstance: taskingInstance,
            };
        }

        case SET_LOADING_TASKING_LIST: {
            const { isLoading } = action.payload;
            return {
                ...state,
                isLoadingTaskingList: isLoading,
            };
        }

        case SET_TASKING_LIST: {
            const { taskingList } = action.payload;
            return {
                ...state,
                isLoadingTaskingList: false,
                taskingList,
            };
        }

        case SET_CATALOG_LIST: {
            const { catalogList } = action.payload;
            return {
                ...state,
                catalogList,
            };
        }

        case SET_ACTIVE_AOI_LIST: {
            const { aoiList } = action.payload;
            return {
                ...state,
                isLoadingAois: false,
                activeAoiList: aoiList,
            };
        }

        case SET_TASKINGS_PAGE: {
            const { taskingsPage } = action.payload;
            return {
                ...state,
                taskingsPage,
            };
        }

        case SET_CATALOG_PAGE: {
            const { catalogPage } = action.payload;
            return {
                ...state,
                catalogPage,
            };
        }

        case SET_SELECTED_AOI: {
            const { selectedAoi } = action.payload;
            return {
                ...state,
                selectedAoi,
            };
        }

        case SET_TASKING_SEARCH_START_DATE: {
            const { taskingSearchStartDate } = action.payload;
            return {
                ...state,
                taskingSearchStartDate,
            };
        }

        case SET_TASKING_SEARCH_END_DATE: {
            const { taskingSearchEndDate } = action.payload;
            return {
                ...state,
                taskingSearchEndDate,
            };
        }

        case SET_SELECTED_OPPORTUNITY: {
            const { selectedOpportunity } = action.payload;
            return {
                ...state,
                selectedOpportunity,
            };
        }

        default:
            return { ...state };
    }
}

export const selectTaskingsByPage = createSelector(
    [
        (state: RootState) => state.tasking.app.taskingList,
        (state: RootState) => state.tasking.app.taskingsPage,
        (state: RootState, filters: string[]) => filters,
    ],
    (taskingList: TaskingInstance[], taskingsPage: number, filters: string[]) => {
        return taskingList
            .filter((item) => filters.includes(item.dataSource))
            .slice(taskingsPage * SHORT_VISIBLE_PAGE_SIZE, (taskingsPage + 1) * SHORT_VISIBLE_PAGE_SIZE);
    },
);
export const selectCatalogItemsByPage = createSelector(
    [
        (state: RootState) => state.tasking.app.catalogList,
        (state: RootState) => state.tasking.app.catalogPage,
        (state: RootState, filters: string[]) => filters,
    ],
    (catalogList: TaskingInstance[], catalogPage: number, filters: string[]) => {
        return catalogList
            .filter((item) => filters.includes(item.dataSource))
            .slice(catalogPage * SHORT_VISIBLE_PAGE_SIZE, (catalogPage + 1) * SHORT_VISIBLE_PAGE_SIZE);
    },
);

export const getTaskingDataSources = createSelector(
    [(state: RootState) => state.tasking.app.taskingList, (state: RootState) => state.tasking.app.catalogList],
    (taskingList: TaskingInstance[], catalogList: TaskingInstance[]) => {
        return [...taskingList, ...catalogList]
            .map((item) => item.dataSource)
            .filter((source, idx, arr) => arr.indexOf(source) === idx);
    },
);
