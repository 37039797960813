import React from 'react';
import { OSKView } from '../../templates/OSKView';
import { Box, Typography } from 'oskcomponents';
import OskLogo from '../../assets/images/osk_logo_white_monogram.png';
import SpaceImage from '../../assets/images/spaaace.jpg';
import { useWaitForImage } from '../../hooks';

type GenericErrorViewProps = {};
const GenericErrorView = ({}: GenericErrorViewProps) => {
    const [render] = useWaitForImage(SpaceImage);

    return (
        <OSKView noheader grow>
            {render && (
                <Box
                    grow
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        backgroundImage: `url('${SpaceImage}')`,
                        backgroundSize: 'cover',
                    }}
                >
                    <Box
                        style={{
                            backgroundColor: '#111',
                            color: '#ccc',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            height: '350px',
                            paddingTop: '20px',
                            padding: '20px',
                            borderRadius: '14px',
                            width: '450px',
                            filter: 'drop-shadow(0 0 0.55rem black)',
                        }}
                        col
                    >
                        <img style={{ padding: '14px' }} width={100} src={OskLogo} />
                        <Typography variant="body2" style={{ textAlign: 'center' }} p={14}>
                            Something went wrong. We&apos;re sorry for the inconvenience. Please refresh the page and
                            again.
                        </Typography>
                    </Box>
                </Box>
            )}
        </OSKView>
    );
};

export { GenericErrorView };
