import { createSelector } from '@reduxjs/toolkit';
import { Capture, Order, SigmaAPI } from 'oskcore';
import { DEFAULT_PAGINATION_LIMIT } from '~/pagination';
import { AppDispatch, RootState } from '~/redux/store';

export type OrderType = 'tasking' | 'archive';

export type OrderStatus = 'none' | 'pending' | 'complete' | 'failed';

/**
 * This represents a Sigma Order in its base form.
 */
export type SigmaOrder = {
    bucket: string;
    data_level: string;
    delivery_type: string;

    /** Status of order, i.e. pending / failed */
    status: OrderStatus;
} & Order;

/**
 * This represents a Sigma Order that has been enhanced
 * with additional metadata. Such as the full capture
 * information for the items inside.
 */
export type SigmaOrderDetails = {
    captures?: Capture[];
} & SigmaOrder;

/**
 * This isn't supported by the schema yet. I'm not sure how that'll look
 * but we want the details to be present on the model for now anyway. Use
 * this object to enrich orders.
 */
const SampleDeliveryInformation = {
    bucket: 's3://orbitalsidekick.com',
    data_level: '1A',
    delivery_type: 'ENVI',

    /** Status of order, i.e. pending / failed */
    status: 'pending',
} as SigmaOrderDetails;

/* Actions */
const COMPUTE_ORDER_READY_STATE = 'COMPUTE_ORDER_READY_STATE';
export function computeOrderReadyState() {
    return {
        type: COMPUTE_ORDER_READY_STATE,
        payload: {},
    };
}

const SET_LOADING_ORDERS = 'SET_LOADING_ORDERS';
export function setLoadingOrders(type: OrderType, isLoading: boolean) {
    return {
        type: SET_LOADING_ORDERS,
        payload: {
            type,
            isLoading,
        },
    };
}

const SET_LOADING_SINGLE_ORDER = 'SET_LOADING_SINGLE_ORDER';
export function setLoadingSingleOrder(isLoading: boolean) {
    return {
        type: SET_LOADING_SINGLE_ORDER,
        payload: {
            isLoading,
        },
    };
}

const SET_TASKING_ORDERS = 'SET_TASKING_ORDERS';
export function setTaskingOrders(pageNumber: number, orders: SigmaOrder[]) {
    return {
        type: SET_TASKING_ORDERS,
        payload: {
            pageNumber,
            orders,
        },
    };
}

const SET_ARCHIVE_ORDERS = 'SET_ARCHIVE_ORDERS';
export function setArchiveOrders(pageNumber: number, orders: SigmaOrder[]) {
    return {
        type: SET_ARCHIVE_ORDERS,
        payload: {
            pageNumber,
            orders,
        },
    };
}

const SET_ORDER_PAGE = 'SET_ORDER_PAGE';
export function setOrderPage(type: OrderType, pageNumber: number) {
    return {
        type: SET_ORDER_PAGE,
        payload: {
            type,
            pageNumber,
        },
    };
}

const SET_ORDER_TOTALS = 'SET_ORDER_TOTALS';
export function setOrderTotals(type: OrderType, total: number) {
    return {
        type: SET_ORDER_TOTALS,
        payload: {
            type,
            total,
        },
    };
}

const SET_ACTIVE_ORDER = 'SET_ACTIVE_ORDER';
export function setActiveOrder(order: SigmaOrderDetails) {
    return {
        type: SET_ACTIVE_ORDER,
        payload: {
            order,
        },
    };
}

const SET_ACTIVE_ORDER_ID = 'SET_ACTIVE_ORDER_ID';
export function setActiveOrderId(orderId: string) {
    return {
        type: SET_ACTIVE_ORDER_ID,
        payload: {
            orderId,
        },
    };
}

export function fetchOrderAsync(programId: number, orderId: string) {
    return (dispatch: AppDispatch) => {
        dispatch(setLoadingSingleOrder(true));

        SigmaAPI.getOrder({
            id: orderId,
            program: programId,
        })
            .then(async (resp) => {
                if (resp.data.inventoryId) {
                    const promises = [];
                    for (const captureId of resp.data.inventoryId) {
                        promises.push(
                            SigmaAPI.getCapture({
                                id: parseInt(captureId),
                                program: programId,
                            }).then((resp) => {
                                return resp.data;
                            }),
                        );
                    }

                    const captures = await Promise.all(promises);
                    dispatch(
                        setActiveOrder({
                            ...resp.data,
                            captures,
                            ...SampleDeliveryInformation,
                        }),
                    );
                } else {
                    dispatch(
                        setActiveOrder({
                            ...resp.data,
                            ...SampleDeliveryInformation,
                        }),
                    );
                }
            })
            .finally(() => {
                dispatch(setLoadingSingleOrder(false));
                dispatch(computeOrderReadyState());
            });
    };
}

export function fetchOrdersAsync(type: OrderType, programId: number, page: number) {
    return (dispatch: AppDispatch) => {
        dispatch(setLoadingOrders(type, true));
        SigmaAPI.listOrders({
            program: programId,
            limit: DEFAULT_PAGINATION_LIMIT,
            offset: page * DEFAULT_PAGINATION_LIMIT,
        })
            .then((resp) => {
                if (resp.data.results && resp.data.count) {
                    if (type === 'tasking') {
                        dispatch(
                            setTaskingOrders(
                                page,
                                resp.data.results.map((data) => {
                                    return {
                                        ...data,
                                        ...SampleDeliveryInformation,
                                    };
                                }),
                            ),
                        );

                        dispatch(setOrderTotals('tasking', resp.data.count));
                    } else if (type === 'archive') {
                        dispatch(
                            setArchiveOrders(
                                page,
                                resp.data.results.map((data) => {
                                    return {
                                        ...data,
                                        ...SampleDeliveryInformation,
                                    };
                                }),
                            ),
                        );

                        dispatch(setOrderTotals('archive', resp.data.count));
                    }
                } else {
                }
            })
            .finally(() => {
                dispatch(setLoadingOrders(type, false));
                dispatch(computeOrderReadyState());
            });
    };
}

/* Reducer */
type OrderStateType = {
    pageInitialized: boolean;
    loadingSingleOrder: boolean;
    totalTaskingOrders: number;
    loadingTaskingOrders: boolean;
    taskingOrderPage: number;
    taskingOrdersByPage: Record<number, SigmaOrder[]>;
    totalArchiveOrders: number;
    loadingArchiveOrders: boolean;
    archiveOrderPage: number;
    archiveOrdersByPage: Record<number, SigmaOrder[]>;
    activeOrder?: SigmaOrderDetails;
    activeOrderId?: string;
};

const initialState: OrderStateType = {
    pageInitialized: false,
    loadingSingleOrder: false,
    totalTaskingOrders: 0,
    loadingTaskingOrders: false,
    taskingOrderPage: 0,
    taskingOrdersByPage: {},
    totalArchiveOrders: 0,
    loadingArchiveOrders: false,
    archiveOrderPage: 0,
    archiveOrdersByPage: {},
    activeOrder: undefined,
    activeOrderId: undefined,
};

export default function reducer(state = initialState, action: any): OrderStateType {
    switch (action.type) {
        case SET_TASKING_ORDERS: {
            const { pageNumber, orders } = action.payload;
            return {
                ...state,
                taskingOrdersByPage: {
                    ...state.taskingOrdersByPage,
                    [pageNumber]: orders,
                },
            };
        }

        case SET_ARCHIVE_ORDERS: {
            const { pageNumber, orders } = action.payload;
            return {
                ...state,
                archiveOrdersByPage: {
                    ...state.archiveOrdersByPage,
                    [pageNumber]: orders,
                },
            };
        }

        case SET_ORDER_PAGE: {
            const { type, pageNumber } = action.payload;
            if (type === 'archive') {
                return {
                    ...state,
                    archiveOrderPage: pageNumber,
                };
            } else if (type === 'tasking') {
                return {
                    ...state,
                    taskingOrderPage: pageNumber,
                };
            }
        }

        case SET_ORDER_TOTALS: {
            const { type, total } = action.payload;
            if (type === 'archive') {
                return {
                    ...state,
                    totalArchiveOrders: total,
                };
            } else if (type === 'tasking') {
                return {
                    ...state,
                    totalTaskingOrders: total,
                };
            }
        }

        case SET_ACTIVE_ORDER_ID: {
            const { orderId } = action.payload;
            return {
                ...state,
                activeOrderId: orderId,
            };
        }

        case SET_ACTIVE_ORDER: {
            const { order } = action.payload;
            return {
                ...state,
                activeOrder: order,
            };
        }

        case SET_LOADING_ORDERS: {
            const { type, isLoading } = action.payload;
            if (type === 'tasking') {
                return {
                    ...state,
                    loadingTaskingOrders: isLoading,
                };
            } else if (type === 'archive') {
                return {
                    ...state,
                    loadingArchiveOrders: isLoading,
                };
            }
        }

        case SET_LOADING_SINGLE_ORDER: {
            const { isLoading } = action.payload;
            return {
                ...state,
                loadingSingleOrder: isLoading,
            };
        }

        case COMPUTE_ORDER_READY_STATE: {
            return {
                ...state,
                pageInitialized:
                    state.pageInitialized ||
                    (!state.loadingArchiveOrders && !state.loadingSingleOrder && !state.loadingTaskingOrders),
            };
        }

        default:
            return { ...state };
    }
}

export const selectTaskingOrdersByPage = createSelector(
    [(state: RootState) => state.data.orders.taskingOrdersByPage, (state: RootState, pageNumber: number) => pageNumber],
    (orderPages, pageNumber): SigmaOrder[] => {
        return orderPages[pageNumber] ?? [];
    },
);

export const selectArchiveOrdersByPage = createSelector(
    [(state: RootState) => state.data.orders.archiveOrdersByPage, (state: RootState, pageNumber: number) => pageNumber],
    (orderPages, pageNumber): SigmaOrder[] => {
        return orderPages[pageNumber] ?? [];
    },
);

export const selectOrderDashboardReady = createSelector(
    [(state: RootState) => state.data.orders.pageInitialized],
    (pageInitialized) => {
        return pageInitialized;
    },
);
