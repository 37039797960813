import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import CSS from 'csstype';
import { map } from 'lodash';
import { Text } from '../Typography';
import { Box, BoxProps } from '../Box';
import { omit } from 'lodash';
import { ColorVariants } from '../DefaultThemeProvider';

type ListEntry = {
    /** A string or ReactNode element which will be displayed as an entry to the List component */
    label: string | React.ReactNode;
    /** The value of the entry */
    value: any;
};

type ListItemProps = {
    label: string | React.ReactNode;
    className?: string;
    style?: CSS.Properties;
    variant?: ColorVariants;
    /** If true, the foreground and background will be inverted */
    inverted?: boolean;
} & JSX.IntrinsicElements['input'];

const CoreListItem = React.forwardRef<HTMLInputElement, ListItemProps>(
    ({ style, className, label, ...props }: ListItemProps, ref) => {
        // If a string type was passed, wrap it in a consistent <Text /> element.
        // Otherwise, we can render the React.ReactNode element as a standalone entry.
        const isString = typeof label === 'string';

        // NOTE: There is a bit of a "creative" mechanism at play here. The invisible input box
        // is used to allow refs against this element to call the .focus() method
        // which will bring the row into the viewport. Its only purpose is navigating
        // long lists with scrollbars.
        return (
            <Box grow role={'listitem'} style={style} className={className} {...omit(props, ['ref'])}>
                <input
                    data-type="input"
                    type="text"
                    style={{ display: 'none', outline: 'none', border: 0, opacity: 0, height: 0, width: 0 }}
                    ref={ref}
                    readOnly={true}
                />
                {isString && <Text as="div">{label}</Text>}
                {!isString && label}
            </Box>
        );
    },
);

const ListItem = styled(CoreListItem)`
    cursor: pointer;
    margin: 0px;
    border: 0;
    padding: 0;
    margin: 0;
    color: ${(props: any) =>
        props.inverted
            ? props.theme.colors[props.variant ?? 'primary'].bg
            : props.theme.colors[props.variant ?? 'primary'].invertedBg};
    background-color: ${(props: any) =>
        props.inverted
            ? props.theme.colors[props.variant ?? 'primary'].invertedBg
            : props.theme.colors[props.variant ?? 'primary'].bg};

    &:hover {
        font-family: ${(props: any) => props.theme.font}, san-serif;
    }
`;

const ActiveListItem = styled(CoreListItem)`
    margin: 0px;
    border: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    background-color: ${(props: any) =>
        props.inverted
            ? props.theme.colors[props.variant ?? 'primary'].bg
            : props.theme.colors[props.variant ?? 'primary'].invertedBg};
    color: ${(props: any) =>
        props.inverted
            ? props.theme.colors[props.variant ?? 'primary'].fg
            : props.theme.colors[props.variant ?? 'primary'].invertedFg};
    &:focus,
    &:active {
        outline: none;
    }
`;

type ListProps = {
    /** Additional styling to apply to the container */
    style?: CSS.Properties;
    /** Additional styling to apply to each list item */
    itemStyle?: CSS.Properties;
    /** A list of items to render in the container. Each item must contain a label and value property */
    items?: Array<ListEntry>;
    /** Additional children elements to render inside the ListBox area after the list items*/
    children?: React.ReactNode;
    /** The method which is invoked when a selection event happens */
    onSelect?: (item: ListEntry) => void;
    /** The method which is invoked when the escape key is pressed */
    onCancel?: () => void;
    /** If true, hover effects and ability to select items will be disabled */
    plain?: boolean;
    /** The variant to render which informs the default colors */
    variant?: ColorVariants;
    /** If true, the foreground and background will be inverted */
    inverted?: boolean;
    /** Additional elements to render inside the ListBox area before the list items */
    header?: React.ReactNode;
    /** Max height before scrolling */
    maxHeight?: string;
} & Omit<BoxProps, 'onSelect'>;

const List = styled(
    ({
        onCancel,
        children,
        onSelect,
        plain,
        style,
        items,
        itemStyle,
        inverted,
        variant = 'primary',
        header,
        maxHeight,
        ...props
    }: ListProps) => {
        const [activeIdx, setActiveIdx] = useState(-1);
        const activeRef = useRef<HTMLInputElement>(null);

        const selectItem = (index: number) => {
            items && onSelect && onSelect(items[index]);
        };

        const handleKeydown = (evt: KeyboardEvent) => {
            const maxIdx = (items ?? []).length;
            switch (evt.key) {
                case 'ArrowDown':
                    {
                        const nextIdx = activeIdx + 1 >= maxIdx ? 0 : activeIdx + 1;
                        setActiveIdx(nextIdx);
                    }
                    break;

                case 'ArrowUp':
                    {
                        const nextIdx = activeIdx - 1 < 0 ? maxIdx - 1 : activeIdx - 1;
                        setActiveIdx(nextIdx);
                    }
                    break;

                case 'Enter':
                    {
                        selectItem(activeIdx);
                    }
                    break;

                case 'Escape':
                    {
                        onCancel && onCancel();
                    }
                    break;
            }
        };

        useEffect(() => {
            activeRef?.current?.focus();
            window.addEventListener('keydown', handleKeydown);
            return () => {
                window.removeEventListener('keydown', handleKeydown);
            };
        }, [activeIdx]);

        return (
            <Box role="list" style={style} grow col {...omit(props, ['ref'])}>
                {header}
                <Box style={{ overflow: 'scroll', maxHeight: maxHeight ?? '150px' }} col>
                    {map(items, (item, index) => (
                        <React.Fragment key={`list_item_${index}`}>
                            {item && !plain && index === activeIdx && (
                                <ActiveListItem
                                    style={itemStyle}
                                    variant={variant}
                                    inverted={inverted}
                                    ref={activeRef}
                                    onClick={() => selectItem(index)}
                                    key={`${item.label}_${index}`}
                                    label={item.label}
                                />
                            )}
                            {item && (index !== activeIdx || plain) && (
                                <ListItem
                                    style={itemStyle}
                                    variant={variant}
                                    inverted={inverted}
                                    tabIndex={index}
                                    onMouseEnter={() => setActiveIdx(index)}
                                    onFocus={() => setActiveIdx(index)}
                                    onClick={() => selectItem(index)}
                                    key={`${item.label}_${index}`}
                                    label={item.label}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </Box>
                {children}
            </Box>
        );
    },
)`
    display: flex;
    top: 0px;
    padding: 0px;
    position: relative;
    background-color: ${(props: any) =>
        props.inverted
            ? props.theme.colors[props.variant ?? 'primary'].invertedBg
            : props.theme.colors[props.variant ?? 'primary'].bg};
`;

export type { ListEntry, ListItemProps };
export { List };
