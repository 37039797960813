import { ProgramUser } from 'oskcore';
import { OSKProfile } from 'oskcore/src/templates/OSKAppProvider';

/* Constants */
export const PROGRAM_ID_LOCAL_STORAGE_KEY = 'user_session_program_id';
export const PROGRAM_PROFILE_LOCAL_STORAGE_KEY = 'user_session_program_profile';

/* Actions */
export const SET_SESSION_PROGRAM_ID = 'SET_SESSION_PROGRAM_ID';
export function setSessionProgramId(program_id: number) {
    return {
        type: SET_SESSION_PROGRAM_ID,
        payload: {
            program_id,
        },
    };
}

export const SET_SESSION_PROGRAM_PROFILE = 'SET_SESSION_PROGRAM_PROFILE';
export function setSessionProfile(payload: ProgramUser) {
    return {
        type: SET_SESSION_PROGRAM_PROFILE,
        payload,
    };
}

/**
 * This method accepts an OSKProfile and will configure the session
 * to have all the details about that user.
 *
 * @param user The user profile to configure
 */
export const SET_USER_SESSION = 'SET_USER_SESSION';
export function configureUserSession(user: OSKProfile) {
    return {
        type: SET_USER_SESSION,
        payload: {
            user,
        },
    };
}

/* Reducer */
type SessionStateType = {
    program_id?: number;
    user_id?: number;
    program_profile?: ProgramUser;
} & Omit<OSKProfile, 'id'>;

const initialState: SessionStateType = {
    program_id: undefined,
    user_id: undefined,
    first_name: undefined,
    last_name: undefined,
    job_title: undefined,
    program_profile: undefined,
    email: '',
    programs: [],
};

export default function reducer(state = initialState, action: any): SessionStateType {
    switch (action.type) {
        case SET_SESSION_PROGRAM_ID: {
            const { program_id } = action.payload;

            // Cache the program_id in local storage whenever it changes.
            localStorage.setItem(PROGRAM_ID_LOCAL_STORAGE_KEY, program_id);

            return {
                ...state,
                program_id,
            };
        }

        case SET_SESSION_PROGRAM_PROFILE: {
            const program_profile = action.payload as ProgramUser;

            // Cache the profile information in session storage for easy access
            // in places without redux.
            if (program_profile) {
                sessionStorage.setItem(PROGRAM_PROFILE_LOCAL_STORAGE_KEY, JSON.stringify(program_profile));
            } else {
                sessionStorage.removeItem(PROGRAM_PROFILE_LOCAL_STORAGE_KEY);
            }

            return {
                ...state,
                program_profile,
            };
        }

        case SET_USER_SESSION: {
            const { user } = action.payload as { user: OSKProfile };
            const { id, first_name, last_name, job_title, email, programs } = user || {};

            return {
                ...state,
                user_id: id,
                first_name,
                last_name,
                job_title,
                email,
                programs,
            };
        }

        default:
            return { ...state };
    }
}
