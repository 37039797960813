import React, { useState, useContext, forwardRef, useRef, useEffect } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import styled, { useTheme } from 'styled-components';
import { SelectItem } from './SelectItem';
import {
    Box,
    BoxProps,
    Button,
    ColorVariants,
    List,
    ListEntry,
    OSKIcon,
    OSKThemeType,
    Overlay,
    TextInput,
    Text,
} from '..';
import { ErrorContext } from '..';
import CSS from 'csstype';
import { useClickAway } from '../hooks/useClickAway';
import { capitalize } from 'lodash';

export const SELECT_ALL_LIST_VALUE = 'SELECT_ALL';

export type MultiPickerProps = {
    /** Override the background color of the input box */
    bg?: string;
    /** The default values for this MultiPicker */
    defaultValues?: Array<string | number>;
    /** The current values for this MultiPicker,
     * resets the internal selection list when updated */
    values?: Array<string | number>;
    /** The error message to display to the user */
    error?: string;
    /** The list of items to display inside this multi-select picker */
    items: Array<ListEntry>;
    /** The name of the form field this element is associated with.
     * If null, there will be no implicit form support. I.E. the
     * <Input type='hidden' /> element won't be rendered. */
    name?: string;
    /** The method which is invoked when a change happens. It accepts, as a parameter
     * the current list of selected items.*/
    onChange?: (selected: Array<ListEntry>, oldSelected?: Array<ListEntry>) => void;
    /** The placeholder text or ReactNode to render when no selections are made */
    placeholder?: string | React.ReactNode;
    /** If true, the MultiPicker will be in readonly mode and can't be opened. */
    readonly?: boolean;
    /** The text or ReactNode to render when there are selections (use this
     * to override the default selected text) */
    selectedNode?: string | React.ReactNode;
    /** The color variant to propagate throughout the component. */
    variant?: ColorVariants;
    /** Scope style of the input */
    inputStyle?: CSS.Properties;
    /** Flag to enable search filtering */
    searchEnabled?: boolean;
    /** Only trigger onChange when the user clicks "Apply". */
    requireConfirm?: boolean;
    /** Max height of the list */
    maxListHeight?: string;
    /** Method of displaying the selection */
    displaySelectedMode?: 'count' | 'list';
    /** Which clear button to display, if any */
    clearMode?: 'clear' | 'reset' | 'none';
} & Omit<Omit<BoxProps, 'ref'>, 'onChange'>;

/**
 * A multi-select dropdown box which supports either text entries or react node entries.
 * This component is compatible with forms by default. It will comma-separate the selected
 * values and store them in a hidden input field.
 *
 * Also accepts tab-to-open through a focus trap mechanism.
 */
export const MultiSelect = forwardRef<HTMLInputElement, MultiPickerProps>(
    (
        {
            bg,
            defaultValues,
            values,
            error,
            items,
            name,
            onChange,
            placeholder,
            readonly,
            selectedNode,
            variant = 'primary',
            inputStyle,
            style,
            searchEnabled,
            requireConfirm,
            maxListHeight,
            displaySelectedMode = 'list',
            clearMode = 'none',
            ...props
        }: MultiPickerProps,
        refOverride,
    ) => {
        const formErrors = useContext(ErrorContext);
        // Calculate the defaultArray if applicable
        const defaultArray: Array<number> = [];
        if (defaultValues) {
            defaultValues.forEach((value) => {
                defaultArray.push(items.findIndex((item) => item.value === value));
            });
        }
        let valuesByIndex: Array<number> = [];
        if (values) {
            values.forEach((value) => {
                valuesByIndex.push(items.findIndex((item) => item.value === value));
            });
        } else {
            valuesByIndex = defaultArray;
        }
        const ref = useRef<HTMLDivElement | null>(refOverride && null);
        const [open, setOpen] = useState(false);
        const [lastSelected, setLastSelected] = useState<Array<number>>(defaultArray);
        const [selected, setSelected] = useState<Array<number>>(defaultArray);
        const [_visible, setVisible] = useState(true);
        const [filter, setFilter] = useState<string>();
        const theme = useTheme() as OSKThemeType;

        useClickAway(ref, () => setOpen(false), open);

        useEffect(() => {
            if (values) {
                setLastSelected(selected);
                setSelected(valuesByIndex);
            }
        }, [values]);

        const getSelectedAllState = () =>
            selected.length === 0 ? false : selected.length === items.length ? true : 'some';

        const getItemIdx = (queryItem: ListEntry) => {
            return items.findIndex((item) => item.value === queryItem.value);
        };

        const handleSelect = (item: ListEntry) => {
            const idx = getItemIdx(item);
            const oldSelected = [...selected];
            let nextSelected = [...selected];

            if (item.value === SELECT_ALL_LIST_VALUE) {
                switch (getSelectedAllState()) {
                    case true:
                        // Currently selected all, now unselect all
                        nextSelected = [];
                        break;

                    case false:
                    case 'some':
                        // Currently selected none or some, now select all
                        nextSelected = items.map((item, idx) => idx);
                        break;
                }
            } else {
                if (nextSelected.includes(idx)) {
                    nextSelected.splice(nextSelected.indexOf(idx), 1);
                } else {
                    nextSelected.push(idx);
                }
                setVisible(false);
                setTimeout(setVisible.bind(this, true), 0);
            }

            setSelected(nextSelected);
            // Only trigger onChange on select if we don't have a confirmation button.
            if (!requireConfirm) {
                setLastSelected(defaultArray);
                onChange &&
                    onChange(
                        nextSelected.map((selectedIdx) => items[selectedIdx]),
                        oldSelected.map((selectedIdx) => items[selectedIdx]),
                    );
            } else {
                setLastSelected(oldSelected);
            }
        };

        const handleOpen = (shouldOpen: boolean) => {
            if (!readonly) {
                setOpen(shouldOpen);
            }
        };

        let selectedText: React.ReactNode = undefined;
        switch (displaySelectedMode) {
            case 'count':
                if (selected.length === items.length) {
                    selectedText = `All (${selected.length})`;
                } else if (selected.length > 0) {
                    selectedText = `${selected.length} selected`;
                } else {
                    selectedText = 'None';
                }
                break;
            case 'list':
                if (selected.length === items.length) {
                    selectedText = 'All';
                } else if (selected.length > 0) {
                    selectedText =
                        selectedNode ??
                        selected.map((idx) => (
                            <span key={idx} style={{ paddingRight: 6 }}>
                                {items[idx].label}
                            </span>
                        ));
                } else {
                    selectedText = placeholder ?? (readonly === false && <span>None</span>) ?? <span></span>;
                }
                break;
        }
        const inputBg = theme.colors[variant].bg;
        const inputFg = theme.colors[variant].fg;
        const errorMessage = error ?? (name && formErrors[name]) ?? '';
        return (
            <React.Fragment>
                <Box
                    ref={ref}
                    style={{
                        cursor: 'pointer',
                        borderRadius: '9px',
                        outline: errorMessage
                            ? `2px solid ${theme.input.errorBorderColor}`
                            : `1px solid ${theme.colors.primary.border}`,
                        ...style,
                    }}
                    col
                    {...props}
                >
                    {/* This input is a focus-trap. Used to capture tab events.*/}
                    <input
                        style={{
                            position: 'absolute',
                            top: '-1000000000px',
                        }}
                        onBlur={() => handleOpen(false)}
                        onFocus={() => handleOpen(true)}
                    />
                    <Box bg={bg ?? inputBg} fg={inputFg} style={{ borderRadius: '9px', ...inputStyle }}>
                        <Box
                            style={{ alignItems: 'center', paddingLeft: '10px', flexWrap: 'wrap', userSelect: 'none' }}
                            onClick={handleOpen.bind(this, !open)}
                            grow
                        >
                            {selectedText}
                        </Box>
                        {clearMode !== 'none' && (
                            <Box center="all">
                                <ClearButton
                                    onClick={() => {
                                        const oldSelected = [...selected];
                                        let newSelected: number[] = [];
                                        if (clearMode === 'reset') {
                                            newSelected = defaultArray;
                                        }

                                        setSelected(newSelected);
                                        onChange &&
                                            onChange(
                                                newSelected?.map((selectedIdx) => items[selectedIdx]),
                                                oldSelected.map((selectedIdx) => items[selectedIdx]),
                                            );
                                    }}
                                >
                                    {capitalize(clearMode)}
                                </ClearButton>
                            </Box>
                        )}
                        <Box p={10} onClick={handleOpen.bind(this, !open)}>
                            <FaCaretDown />
                        </Box>
                    </Box>
                    <Overlay
                        animation="fadeIn"
                        style={{
                            width: '100%',
                            paddingBottom: '50px',
                            paddingTop: '4px',
                            pointerEvents: 'none',
                        }}
                        show={open}
                    >
                        <List
                            onSelect={(item) => handleSelect(item)}
                            style={{
                                width: '100%',
                                outline: `1px solid ${theme.colors[variant ?? 'primary'].border}`,
                                borderRadius: '9px',
                                overflow: 'hidden',
                                pointerEvents: 'all',
                            }}
                            maxHeight={maxListHeight}
                            variant={variant}
                            onCancel={() => handleOpen(false)}
                            header={
                                searchEnabled && (
                                    <TextInput
                                        name="txt_multiselect_search_query"
                                        placeholder="Search"
                                        icon={<OSKIcon code="search" />}
                                        style={{ margin: '8px' }}
                                        value={filter}
                                        onChange={(e) => setFilter(e.target.value)}
                                    />
                                )
                            }
                            items={[
                                {
                                    label: <SelectItem text="Select All" selected={getSelectedAllState()} />,
                                    value: SELECT_ALL_LIST_VALUE,
                                },
                                ...items
                                    .filter((item) =>
                                        filter
                                            ? item.label?.toString().toLowerCase().includes(filter.toLowerCase())
                                            : true,
                                    )
                                    .map((item) => {
                                        return {
                                            label:
                                                typeof item.label === 'string' ? (
                                                    <SelectItem
                                                        key={item.value}
                                                        text={item.label}
                                                        selected={selected.includes(items.indexOf(item))}
                                                    />
                                                ) : (
                                                    item.label
                                                ),
                                            value: item.value,
                                        };
                                    }),
                            ]}
                        >
                            {requireConfirm && (
                                <Box m={8} grow center="all">
                                    <Button
                                        label="Apply"
                                        variant="action"
                                        onClick={() => {
                                            onChange &&
                                                onChange(
                                                    selected.map((idx) => items[idx]),
                                                    lastSelected.map((idx) => items[idx]),
                                                );
                                        }}
                                    />
                                </Box>
                            )}
                        </List>
                    </Overlay>
                    {name && selected.length > 0 && (
                        <input
                            type="hidden"
                            name={name}
                            value={selected.map((_, selectedIdx) => items[selectedIdx].value).join(',')}
                        />
                    )}
                </Box>
                {errorMessage ? `*${errorMessage}` : ''}
            </React.Fragment>
        );
    },
);

const ClearButton = styled(({ className, children, ...props }: any) => (
    <Text className={className} {...props}>
        {children}
    </Text>
))`
    & {
        color: ${(props: any) => props.theme.colors.primary.accent};
    }
    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;
