import { Box, Button, ColorVariants, OSKThemeType } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';
import CSS from 'csstype';

export type CartWizardButton = {
    /** The label for the button */
    label: string;
    /** The type of button */
    variant?: ColorVariants;
    /* If true, the color effects will be inverted */
    inverted?: boolean;
    /* If true, no border will be applied */
    noborder?: boolean;
    /** If true, the button will submit the form */
    submit?: boolean;
    /** The click action to invoke when the button is pressed */
    onClick: () => void;
};

export type CartWizardStep = {
    /** A name used for labels in the wizard step list */
    name: string;
    /** Method that's called when this step's Next action is triggered */
    onWizardNext?: () => void;
    /** Method that's called when this step's Back action is triggered */
    onWizardBack?: () => void;
    /** Method that's called when this step's Close action is triggered */
    onWizardClose?: () => void;
};

export type CartWizardTemplateProps = {
    /** Children elements to render inside the template */
    children?: React.ReactNode;
    /** The buttons to render in the bottom of the wizard pane */
    buttons?: CartWizardButton[];
    /** Override the content area css */
    style?: CSS.Properties;
};

export const CartWizardTemplate = ({ buttons, style, children }: CartWizardTemplateProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box col grow>
            <Box col style={{ ...style, flex: '1 0 0px' }} grow>
                <Box
                    bg={theme.colors.white1}
                    p={20}
                    center="horizontal"
                    style={{ width: '100%', height: '100%' }}
                    fg={theme.colors.black}
                    col
                >
                    {children}
                </Box>
            </Box>

            <Box
                h={60}
                style={{
                    filter: `drop-shadow(0 0 0.75rem ${theme.colors.lightGray})`,
                    flexShrink: 1,
                    justifyContent: 'center',
                }}
                bg={theme.colors.white}
                center="vertical"
            >
                {(buttons ?? []).map(({ label, variant, submit, noborder, inverted, onClick }) => (
                    <Button
                        inverted={inverted}
                        onClick={onClick}
                        noborder={noborder}
                        submit={submit}
                        key={label}
                        style={{
                            height: '40px',
                            border: noborder ? '' : '1px solid black',
                            minWidth: '160px',
                            margin: '10px',
                            borderRadius: '10px',
                        }}
                        variant={variant}
                        label={label}
                    />
                ))}
            </Box>
        </Box>
    );
};
