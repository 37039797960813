export type ActiveApp = 'sigma' | 'error' | 'data' | 'monitor' | 'tasking';

/**
 * This method will use the current path information to derive
 * which sigma product we are on.
 */
export function useCurrentApp(): ActiveApp {
    const pathname = window.location.pathname;

    // Determine the active application
    if (pathname.includes('/monitor')) {
        return 'monitor';
    } else if (pathname.startsWith('/tasking')) {
        return 'data';
    } else if (
        pathname.startsWith('/data') ||
        pathname.startsWith('/map') ||
        pathname.startsWith('/orders') ||
        pathname.includes('/checkout')
    ) {
        return 'data';
    } else if (pathname.includes('/error')) {
        return 'error';
    } else {
        return 'sigma';
    }
}
