import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DefaultThemeProvider } from 'oskcomponents';
import { useAuth0 } from '@auth0/auth0-react';
import { randomString, registerAuthenticationExpiredInterceptor, setAccessToken } from '../../utils';
import { RedirectTo } from '../../atoms/RedirectTo';
import { LoginView } from '../../views/LoginView';
import { ErrorView } from '../../views/ErrorView';
import { VerifyEmailView } from '../../views/VerifyEmailView';
import { PaginatedProgramList, Program, User } from '../../api/generated';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { AdminAPI } from '../../api';

export type ApplicationState = 'Loading' | 'Unknown' | 'Unauthorized' | 'Verify' | 'Authorized' | 'Error';

export type OSKAppProviderProps = {
    children: React.ReactNode;
    name: string;
    domain: string;
    rehydrateUri?: string;
};

export type AppContextType = {
    name: string;
    domain: string;
    errorMessage: string;
    isLoggedIn: boolean;
    doLogout: () => void;
};

export type ProfileContextType = {
    profile?: OSKProfile;
    setProfile: (newProfile?: OSKProfile) => void;
};

export type OSKProfile = User & {
    programs?: Array<Program>;
    picture?: string;
    internal_osk_role?: string;
};

const cachedAppContext = sessionStorage.getItem('appContext');
const defaultAppContext: AppContextType = cachedAppContext
    ? JSON.parse(cachedAppContext)
    : {
          name: '',
          domain: '',
          errorMessage: '',
          isLoggedIn: false,
          doLogout: () => {},
      };

const AppContext = React.createContext(defaultAppContext);
const cachedProfileContext = sessionStorage.getItem('profile');
const ProfileContext = React.createContext<ProfileContextType>({
    profile: undefined,
    setProfile: () => {},
});

export const OSKAppProvider = ({ children, rehydrateUri, domain, name }: OSKAppProviderProps) => {
    const { getAccessTokenSilently, loginWithRedirect, logout, user } = useAuth0();

    let profileDefault;
    try {
        profileDefault = JSON.parse(cachedProfileContext ?? '');
    } catch (ex) {}

    // Cache the initial url they tried to navigate to
    const redirectUrl = useRef(window.location.pathname + window.location.search);

    // If there is a local storage setting that puts us in test mode, we will skip the login page.
    // This is a tiny bit of magic that glues the e2e test together with m2m authentication. Without it,
    // the auth0 login page will show regardless of the fact we are authenticated.
    const defaultAppState = localStorage.getItem('SKIP_LOGIN') === 'true' ? 'Authorized' : 'Unknown';
    const [appState, setAppState] = useState<ApplicationState>(defaultAppState);
    const [profile, setProfile] = useState<OSKProfile | undefined>(profileDefault);
    const [appContext, setAppContext] = useState({
        ...defaultAppContext,
        domain,
        name,
    });

    // Refresh user auth token on page load, if applicable
    const handleLogout = useCallback(() => {
        sessionStorage.clear();
        setAppState('Loading');
        setAppContext({
            ...appContext,
            errorMessage: '',
            isLoggedIn: false,
        });
        logout({
            returnTo: window.location.origin,
        });
    }, [setAppContext]);

    const handleLogin = useCallback(
        (accessToken: string) => {
            setAccessToken(accessToken, '');
            AdminAPI.getProfile()
                .then((userProfileResponse) => {
                    AdminAPI.listPrograms().then((resp: AxiosResponse<PaginatedProgramList>) => {
                        const programs = resp.data.results;
                        setProfile({
                            ...(profile ?? {}),
                            ...userProfileResponse.data,
                            programs,
                        });

                        setAppContext({
                            ...appContext,
                            domain: domain,
                            name: name,
                            isLoggedIn: true,
                            doLogout: handleLogout,
                        });

                        // Wait a tick before showing the app
                        // to let the previous state changes
                        // settle.
                        setTimeout(() => {
                            setAppState('Authorized');
                        }, 0);
                    });
                })
                .catch((ex) => {
                    console.error(ex);
                    setAppContext({
                        ...appContext,
                        isLoggedIn: false,
                    });
                    setAppState('Error');
                });
        },
        [setAppContext, setProfile, user],
    );

    useEffect(() => {
        if (profile && !profile.picture && user?.picture) {
            setProfile({
                ...profile,
                picture: user?.picture,
            });
        }
    }, [user, profile, setProfile]);

    useEffect(() => {
        switch (appState) {
            // The state is unknown so we must attempt to get an access token
            // and if that's successful, then yay. Otherwise, analyze what
            // went wrong and transition accordingly.
            case 'Unknown': {
                getAccessTokenSilently({
                    audience: 'https://coreapi.orbitalsk.com',
                    scope: 'profile',
                    ignoreCache: true,
                    prompt: 'none',
                })
                    .then((accessToken) => {
                        handleLogin(accessToken);
                    })
                    .catch((err) => {
                        setAppContext({
                            ...appContext,
                            isLoggedIn: false,
                        });

                        if (err.error === 'unauthorized') {
                            setAppState('Verify');
                        } else if (err.error === 'login_required' || err.error === 'consent_required') {
                            setAppState('Unauthorized');
                        } else {
                            setAppState('Error');
                        }
                    });

                break;
            }

            // We have an account but need to login again or give consent to the auth0
            // application.
            case 'Unauthorized': {
                // Generate nonce
                const nonce = randomString(16);
                sessionStorage.setItem('loginNonce', nonce);

                loginWithRedirect({
                    audience: 'https://coreapi.orbitalsk.com',
                    scope: 'profile',
                    max_age: 0,
                    appState: {
                        redirectUri: redirectUrl.current,
                        nonce: nonce,
                    },
                });
                break;
            }

            // We are authorized. Do nothing more. The render function will load Sigma Data.
            case 'Authorized': {
                break;
            }

            // We will show the verify email message
            case 'Verify': {
                break;
            }

            // There was an error. Do nothing more. The render function will load the Error Page.
            case 'Error': {
                break;
            }
        }
    }, [appState]);

    useEffect(() => {
        setAppContext({
            ...appContext,
            doLogout: handleLogout,
        });

        // When authentication is expired, we want to be notified
        // so we can invalidate the current login
        registerAuthenticationExpiredInterceptor(() => {
            handleLogout();
        });
    }, []);

    useEffect(() => {
        sessionStorage.setItem('appContext', JSON.stringify(appContext));
    }, [appContext]);

    useEffect(() => {
        sessionStorage.setItem('profile', JSON.stringify(profile));
    }, [profile]);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                path="*"
                element={(() => {
                    switch (appState) {
                        case 'Unknown':
                        case 'Unauthorized':
                            return <LoginView />;
                        case 'Authorized':
                            return (
                                <>
                                    {children}
                                    <RedirectTo href={rehydrateUri} />
                                </>
                            );
                        case 'Error':
                            return <ErrorView />;
                        case 'Verify':
                            return <VerifyEmailView />;
                    }
                })()}
            />,
        ),
    );

    return (
        <DefaultThemeProvider>
            <AppContext.Provider value={appContext}>
                <ProfileContext.Provider value={{ profile, setProfile }}>
                    <RouterProvider router={router} />
                </ProfileContext.Provider>
            </AppContext.Provider>
        </DefaultThemeProvider>
    );
};

export { AppContext, ProfileContext };
