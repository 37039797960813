import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Picker, { DatePickerProps as PickerProps } from 'react-date-picker/dist/entry.nostyle';
import { Box, ColorVariants, ErrorContext } from '..';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { GlobalZIndex } from '../constants';
import CSS from 'csstype';

export type NullableDate = Date | null;

export type DatePickerProps = {
    /** Override for the background color */
    bg?: string;

    /** Override for the foreground color */
    color?: string;

    /** The default className used by styled-components to override themeing */
    className?: string;

    /** The default date to populate this element with. */
    defaultValue?: NullableDate;

    /** If true, this component is disabled */
    disabled?: boolean;

    /** The error message */
    error?: string;

    /** The name of the input element to bind a value to for form purposes */
    name: string;

    /** An optional method which is invoked when the date changes */
    onChange?: (date: NullableDate) => void;

    /** The color variant */
    variant: ColorVariants;

    /** Style passthrough */
    style?: CSS.Properties;
} & Omit<PickerProps, 'defaultValue'>;

export const DatePicker = styled(
    ({ className, defaultValue = null, disabled, error, name, onChange, style, ...props }: DatePickerProps) => {
        const ref = useRef(null);
        const formErrors = useContext(ErrorContext);
        const errorMessage = error ?? formErrors[name];
        const [value, setValue] = useState<NullableDate>(defaultValue);
        const handleChange = (date: NullableDate) => {
            setValue(date);
            onChange && onChange(date);
        };

        useEffect(() => {
            setValue(defaultValue);
        }, [defaultValue]);

        useEffect(() => {
            // This third-party component uses <input /> elements for each value
            // and they have hard-coded names... We disable those so they aren't
            // included in form submissions, since the selected ISOString() value
            // is hoisted out already and assigned the correct name.
            document.querySelectorAll('.react-date-picker__wrapper input').forEach((element) => {
                element.setAttribute('disabled', 'true');
            });
        }, [ref]);

        return (
            <Box
                /**
                 * This is a hack used to capture click events on the calendar
                 * regardless of z-index or location in the DOM
                 */
                onMouseDown={(evt) => {
                    evt.stopPropagation();
                    evt.preventDefault();
                    evt.target.dispatchEvent(new Event('onclick'));
                }}
                data-error={errorMessage}
                className={className}
                col
                style={style}
            >
                <Picker
                    inputRef={ref}
                    calendarAriaLabel="Toggle calendar"
                    clearAriaLabel="Clear value"
                    dayAriaLabel="Day"
                    monthAriaLabel="Month"
                    nativeInputAriaLabel="Date"
                    yearAriaLabel="Year"
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                    {...props}
                />
                {errorMessage ? `*${errorMessage}` : ''}
                <input type="hidden" name={name} value={(value && value.toISOString()) ?? ''} />
            </Box>
        );
    },
)`
    .react-date-picker--disabled {
        background-color: transparent !important;
    }

    .react-date-picker__wrapper {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        font-size: 0.875rem;
        border: 1px solid ${(props: any) => props.color ?? props.theme.colors[props.variant].border} !important;
        border-radius: 9px;
        padding: ${(props: any) => props.theme.input.padding};
        background-color: ${(props: any) => props.bg ?? props.theme.colors[props.variant].bg};
        color: ${(props: any) => props.color ?? props.theme.colors[props.variant].fg};
    }

    &[data-error] .react-date-picker__wrapper {
        border: 2px solid ${(props: any) => props.theme.input.errorBorderColor} !important;
    }

    .react-calendar,
    .react-date-picker__button__icon,
    .react-date-picker__inputGroup__input {
        background-color: ${(props: any) => props.bg ?? props.theme.colors[props.variant].bg};

        color: ${(props: any) => props.color ?? props.theme.colors[props.variant].fg};
    }

    .react-calendar__decade-view__years button:hover,
    .react-calendar__century-view__decades button:hover,
    .react-calendar button:hover abbr {
        color: black !important;
    }

    .react-calendar__tile--now,
    .react-calendar__tile--now abbr {
        color: black !important;
    }

    .react-calendar__navigation__label {
        background-color: ${(props: any) => props.bg ?? props.theme.colors[props.variant].bg} !important;

        color: ${(props: any) => props.color ?? props.theme.colors[props.variant].fg} !important;
    }

    .react-calendar__navigation .react-calendar__navigation__label:hover {
        background-color: ${(props: any) => props.theme.colors[props.variant].invertedBg} !important;
        color: ${(props: any) => props.theme.colors[props.variant].invertedFg} !important;
    }

    .react-calendar__navigation,
    .react-calendar__navigation button,
    .react-calendar__decade-view__years button,
    .react-calendar__century-view__decades button,
    .react-calendar button abbr {
        color: ${(props: any) => props.color ?? props.theme.colors[props.variant].fg};
    }

    svg {
        stroke: ${(props: any) => props.color ?? props.theme.colors[props.variant].fg};
    }
    .react-calendar,
    .react-date-picker__calendar {
        z-index: ${() => GlobalZIndex.DatePicker};
    }
`;
