import { noop } from 'oskcore';
import { Box, Heading, OSKIcon, OSKThemeType, Text } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';
import { CartWizardStep, CartWizardTemplate } from '~/templates';

export type TaskingOrderCompleteProps = {} & CartWizardStep;

export const TaskingOrderComplete = ({ onWizardClose }: TaskingOrderCompleteProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <CartWizardTemplate
            buttons={[
                { variant: 'action', noborder: true, label: 'Go to Order Dashboard', onClick: onWizardClose ?? noop },
            ]}
            style={{
                overflowY: 'hidden',
            }}
        >
            <Box col center="all" w={450} grow>
                <OSKIcon code="big-check-circle" />
                <Heading variant="small" style={{ padding: '15px 0px' }} color={theme.colors.black}>
                    Tasking Order Complete
                </Heading>
                <Text style={{ textAlign: 'center' }} color={theme.colors.black}>
                    Your Tasking Order is complete. You may view the progress of your Task, and view invoices by
                    clicking the Go to Order Dashboard button below.
                </Text>
            </Box>
        </CartWizardTemplate>
    );
};
