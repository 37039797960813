import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { Box, OSKIcon, OSKThemeType, OneOrMore } from 'oskcomponents';

type SidePanelCardProps = {
    /** Child element passthrough */
    children: OneOrMore<React.ReactNode>;
    /** Visibility flag */
    visible?: boolean;
    /** Whether this card can be dismissed */
    dismissable?: boolean;
};

const SidePanelCard = ({ children, visible = true, dismissable = true }: SidePanelCardProps) => {
    const [showCard, setShowCard] = useState(visible);
    const theme = useTheme() as OSKThemeType;

    return showCard ? (
        <Box
            col
            style={{
                backgroundColor: theme.colors.lightGray,
                border: `1px solid ${theme.colors.black600}`,
                borderWidth: '1px',
                borderRadius: '5px',
                height: 'fit-content',
                top: '16px',
                padding: '12px 16px',
                margin: '12px 0px',
            }}
        >
            {dismissable && (
                <Box
                    style={{
                        color: theme.colors.black,
                        position: 'absolute',
                        right: '20px',
                        width: '30px',
                        height: '30px',
                        cursor: 'pointer',
                    }}
                    center="all"
                    onClick={() => {
                        setShowCard(false);
                    }}
                >
                    <OSKIcon code="times" style={{ cursor: 'pointer' }} />
                </Box>
            )}
            {children}
        </Box>
    ) : (
        <React.Fragment />
    );
};

export { SidePanelCard };
export type { SidePanelCardProps };
