import { Box, OSKIcon, OSKThemeType } from 'oskcomponents';
import { noop } from 'oskcore';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { GlobalZIndex } from '~/constants';
import { getVisibleItems, setWizardFlow } from '~/redux/modules/data/cart';
import { RootState } from '~/redux/store';

export type CartButtonProps = {
    /** If > 0, this value will be shown as a badge on top of the cart button */
    visibleCartItems: number;
};

export const CartButton = ({ visibleCartItems }: CartButtonProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();

    return (
        <Box style={{ position: 'relative' }} pt={16} pb={16} pr={10}>
            <OSKIcon
                code="shopping-cart"
                onClick={
                    visibleCartItems > 0
                        ? () => {
                              dispatch(setWizardFlow('search'));
                          }
                        : undefined
                }
                hoverFill={visibleCartItems > 0 ? theme.colors.accent : 'default'}
                style={{ zIndex: GlobalZIndex.ActionBar }}
                scale={150}
            />
            {visibleCartItems > 0 && (
                <Box
                    style={{
                        borderRadius: '30px',
                        minWidth: '20px',
                        minHeight: '20px',
                        fontSize: '0.8rem',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: theme.colors.red,
                        color: 'white',
                        padding: '4px',
                        zIndex: GlobalZIndex.ActionBar + 1,
                        position: 'absolute',
                        pointerEvents: 'none',
                        top: '0px',
                        left: '10px',
                    }}
                >
                    {visibleCartItems > 0 ? visibleCartItems : ''}
                </Box>
            )}
        </Box>
    );
};

function mapStateToProps(state: RootState) {
    return {
        visibleCartItems: getVisibleItems(state).length,
    };
}

export default connect(mapStateToProps, noop)(CartButton);
