import { Box, Heading, OSKThemeType, Text } from 'oskcomponents';
import { OSKGeoJson, Opportunity, noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { CartWizardStep, CartWizardTemplate } from '~/templates';

import EditableMap from '~/organisms/map/EditableMap';
import OpportunityWindowTable from '~/organisms/tables/OpportunityWindowTable';
import { SensorTLEMap } from '~/redux/modules/tasking/app';
import { Footprint, MapCentroid, MapSatelliteTrack } from '~/atoms';
import { useTheme } from 'styled-components';

export type CoveragePreviewProps = {
    /** From redux, the currently selected aoi on the map */
    selectedAoi?: OSKGeoJson;
    /** From redux, the currently selected Opportunity */
    selectedOpportunity?: Opportunity;
} & CartWizardStep;

export const CoveragePreview = ({
    onWizardNext,
    onWizardClose,
    selectedAoi,
    selectedOpportunity,
}: CoveragePreviewProps) => {
    const theme = useTheme() as OSKThemeType;
    const centroid = selectedAoi?.getCentroid();
    const aoiPos = `@${centroid?.lat},${centroid?.lng}`;

    return (
        <CartWizardTemplate
            buttons={[
                { variant: 'primary', inverted: true, label: 'Close', onClick: onWizardClose ?? noop },
                {
                    variant: 'action',
                    noborder: true,
                    label: 'Next',
                    onClick: onWizardNext ?? noop,
                },
            ]}
            style={{
                overflowY: 'hidden',
            }}
        >
            <Box style={{ width: '100%', height: '100%' }}>
                <Box grow p={8} style={{ height: '100%' }}>
                    <Box col grow style={{ height: '100%' }}>
                        <Heading variant="small">Coverage Summary</Heading>
                        <Text variant="small" style={{ padding: '12px 0px' }}>
                            Review the potential overpass opportunities for your selected AOI.
                        </Text>
                        <Box
                            bg="white"
                            style={{ borderRadius: '5px', padding: '16px 8px', height: '100%', overflowY: 'scroll' }}
                        >
                            <OpportunityWindowTable />
                        </Box>
                    </Box>
                </Box>
                <Box style={{ width: '50%', height: '100%', borderRadius: '9px', overflow: 'hidden' }}>
                    <EditableMap position={aoiPos}>
                        {selectedOpportunity && selectedAoi && (
                            <>
                                <MapSatelliteTrack
                                    startTime={new Date(selectedOpportunity.start_time).getTime()}
                                    orbitalPeriods={1}
                                    tle={SensorTLEMap[selectedOpportunity.sensor]}
                                />
                                <MapCentroid area={OSKGeoJson.fromAPIGeometry(selectedOpportunity.max_fov)} />
                                <Footprint
                                    shape={OSKGeoJson.fromAPIGeometry(selectedOpportunity.max_fov)}
                                    color="#00d1ff"
                                />
                                <Footprint shape={selectedAoi} color={theme.colors.accent} />
                            </>
                        )}
                    </EditableMap>
                </Box>
            </Box>
        </CartWizardTemplate>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        footprints: state.data.map.footprints,
        selectedAoi: state.tasking.app.selectedAoi,
        selectedOpportunity: state.tasking.app.selectedOpportunity,
    };
};

export default connect(mapStateToProps, noop)(CoveragePreview);
