import { Box, Button, Heading, OSKThemeType, TabView, Text } from 'oskcomponents';
import { noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import SummaryTab from './SummaryTab';
import CollectsTab from './CollectsTab';
import { DataResourceProvider } from '~/redux';
import { getProgramId } from '~/utils';
import { RootState } from '~/redux/store';
import ActiveOrderStatusIndicator from '~/atoms/ActiveOrderStatusIndicator';
import OrderFailCard from '~/atoms/OrderFailCard';

export type OrderSummaryProps = {
    /** The orderId to lookup in store */
    orderId?: string;
};

export const OrderSummary = ({ orderId }: OrderSummaryProps) => {
    const theme = useTheme() as OSKThemeType;
    const programId = getProgramId();
    return (
        <DataResourceProvider features={['order']} orderId={orderId} programId={programId}>
            {orderId ? (
                <Box style={{ overflowY: 'scroll', width: '100%' }} grow col>
                    <Box p={40} grow col>
                        <Box pb={20}>
                            <Text style={{ fontSize: '2rem' }}>Order Id: {orderId}</Text>
                            <ActiveOrderStatusIndicator />
                            <Box style={{ justifyContent: 'flex-end' }} center="vertical" grow>
                                <Button variant="action" label="Order Again" />
                            </Box>
                        </Box>
                        <OrderFailCard />
                        <TabView
                            tabContainerStyle={{
                                backgroundColor: 'transparent',
                                borderBottom: `1px solid ${theme.colors.black600}`,
                            }}
                            tabStyle={{
                                color: theme.colors.black,
                                marginRight: '45px',
                            }}
                            contentStyle={{
                                paddingTop: '20px',
                            }}
                            tabItems={[
                                { label: 'Summary', component: <SummaryTab /> },
                                { label: 'Collects', component: <CollectsTab /> },
                            ]}
                        />
                    </Box>
                </Box>
            ) : (
                <Box center="all" grow>
                    <Heading>No order selected</Heading>
                </Box>
            )}
        </DataResourceProvider>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        orderId: state.data.orders.activeOrderId,
    };
};
export default connect(mapStateToProps, noop)(OrderSummary);
