import React, { useRef } from 'react';
import { Header, Content, ContentProps, OSKThemeType } from 'oskcomponents';
import { OSKNav } from '../../organisms/OSKNav';
import { useNavigate } from 'react-router-dom';
import CSS from 'csstype';
import { ActiveApp, useCurrentApp } from '../../hooks';
import { hasDeveloperOverrides } from '../../utils';
import { useTheme } from 'styled-components';

export type OSKViewProps = {
    children?: React.ReactNode;
    /** The style to pass into the <Header /> component */
    headerStyle?: CSS.Properties;
    nopadding?: boolean;
    /** If true, there won't be a header bar at all */
    noheader?: boolean;
    /** Optional flag to hide the gradient */
    nogradient?: boolean;
} & Omit<ContentProps, 'ref'>;

export type OSKHeaderContextProps = {
    ref?: React.MutableRefObject<any>;
};

export const OSKHeaderContext = React.createContext<OSKHeaderContextProps>({
    ref: undefined,
});

export const OSKView = ({ children, headerStyle, noheader, ...props }: OSKViewProps) => {
    const ref = useRef(null);
    const navigate = useNavigate();
    const currentApp = useCurrentApp();
    const theme = useTheme() as OSKThemeType;

    const redirectMap: Record<ActiveApp, () => void> = {
        monitor: () => navigate('/monitor'),
        tasking: () => navigate('/tasking'),
        data: () => navigate('/map'),
        error: () => navigate('/'),
        sigma: () => navigate('/'),
    };

    const computedStyle = { ...headerStyle };
    if (hasDeveloperOverrides()) {
        computedStyle.backgroundColor = theme.colors.orange950;
    }

    return (
        <OSKHeaderContext.Provider value={{ ref: ref }}>
            {(noheader === false || noheader === null || noheader === undefined) && (
                <Header style={computedStyle} onLogoClick={redirectMap[currentApp]}>
                    <OSKNav ref={ref} />
                </Header>
            )}
            <Content {...props}>{children}</Content>
        </OSKHeaderContext.Provider>
    );
};
