import { Box, BoxProps, OSKIcon, OSKThemeType, Text } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';
import { CollapsableGroup } from '~/atoms';

export type CaptureGroupIcon = 'all' | 'some' | 'none' | 'hidden';

export type CaptureGroupProps = {
    /** Children elements to render inside this group */
    children?: React.ReactNode;
    /** The taskId (used for the title) */
    taskId: string;
    /** The icon type to show. all | some | none */
    icon: CaptureGroupIcon;
    /** Method to invoke when the icon is clicked */
    onIconClick?: () => void;
    /** If true, the group will start expanded */
    defaultExpanded?: boolean;
} & Omit<BoxProps, 'ref'>;

export const CaptureGroup = ({
    children,
    icon,
    onIconClick,
    defaultExpanded,
    taskId,
    style,
    ...props
}: CaptureGroupProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <CollapsableGroup
            style={{
                borderRadius: '5px',
                backgroundColor: theme.colors.white,
                filter: 'drop-shadow(0px 2px 5px rgba(40, 40, 40, 0.26))',
                overflow: 'scroll',
                padding: '8px 0px 8px 0px',
                width: '100%',
                ...style,
            }}
            defaultExpanded={defaultExpanded}
            title={
                <Box
                    style={{
                        justifyContent: 'space-between',
                        padding: '8px 0px',
                    }}
                    grow
                >
                    <Box
                        center="vertical"
                        style={{ justifyContent: 'space-around', paddingRight: '8px', paddingLeft: '8px' }}
                    >
                        <OSKIcon code="AOI" />
                        <Box style={{ paddingLeft: '8px' }} col>
                            <Text variant="small" strong>
                                {taskId}
                            </Text>
                        </Box>
                    </Box>
                    <Box grow />
                    {icon !== 'hidden' ? (
                        <Box
                            center="all"
                            w={40}
                            onClick={(evt) => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                onIconClick && onIconClick();
                            }}
                            style={{ marginRight: '7px' }}
                        >
                            <OSKIcon
                                fill={theme.colors.black500}
                                code={icon === 'all' ? 'check-circle' : icon === 'some' ? 'dash-circle' : 'reg-circle'}
                                scale={150}
                                cursor="pointer"
                            />
                        </Box>
                    ) : null}
                </Box>
            }
            {...props}
        >
            {children}
        </CollapsableGroup>
    );
};
