import React from 'react';
import { noop } from 'lodash';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { Box, OSKIcon, OSKIconType, Text } from 'oskcomponents';
import { OrderStatus } from '~/redux/modules/data/orders';

type ActiveOrderStatusIndicatorProps = {
    orderStatus?: OrderStatus;
};

type StatusItem = {
    name: string;
    color: string;
    icon: OSKIconType;
};

const statusFormatMap: { [key: string]: StatusItem } = {
    none: {} as StatusItem,
    pending: { name: 'Order Complete', color: '#00D916', icon: 'check' },
    complete: { name: 'Order In Progress', color: '#4954C0', icon: 'ellipsis' },
    failed: { name: 'Order Failed', color: '#FF0000', icon: 'times-circle' },
};

const ActiveOrderStatusIndicator = ({ orderStatus }: ActiveOrderStatusIndicatorProps) => {
    const status = orderStatus ? orderStatus : 'none';
    const statusFormat = statusFormatMap[status];

    if (status === 'none') {
        return <></>;
    }

    return (
        <Box center="vertical" ml={18}>
            <Box
                style={{
                    borderRadius: '50px',
                    backgroundColor: statusFormat.color,
                    width: '16px',
                    aspectRatio: '1',
                    marginRight: '4px',
                }}
                center="all"
            >
                <OSKIcon
                    code={statusFormat.icon}
                    mr={4}
                    fill="white"
                    scale={80}
                    style={{ position: 'relative', left: '2px' }}
                />
            </Box>
            <Text strong>{statusFormat.name}</Text>
        </Box>
    );
};

const mapStateToProps = (state: RootState): Partial<ActiveOrderStatusIndicatorProps> => {
    return {
        orderStatus: state.data.orders.activeOrder?.status,
    };
};

export default connect(mapStateToProps, noop)(ActiveOrderStatusIndicator);
