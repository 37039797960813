import {
    Box,
    DatePicker,
    Heading,
    NullableDate,
    OSKIcon,
    OSKThemeType,
    SidePanel,
    Text,
    Divider,
    Button,
} from 'oskcomponents';
import { OSKGeoJson } from 'oskcore';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { SidePanelHeader } from '~/atoms';
import { useDisableFeatureOnMouseOver, useMap } from '~/hooks';
import { setWizardFlow } from '~/redux/modules/data/cart';
import { setTaskingSearchEndDate, setTaskingSearchStartDate } from '~/redux/modules/tasking/app';
import { RootState } from '~/redux/store';
import GeocodingSearch from '../GeocodingSearch';
import { useNavigate } from 'react-router-dom';
import { useToggles } from '~/hooks/useToggles';

type TaskingSidePanelProps = {
    /** From redux, the selected AOI if any. */
    selectedAoi?: OSKGeoJson;

    taskingSearchStartDate: NullableDate;
    taskingSearchEndDate: NullableDate;
};

export const TaskingSidePanel = ({
    selectedAoi,
    taskingSearchStartDate,
    taskingSearchEndDate,
}: TaskingSidePanelProps) => {
    const theme = useTheme() as OSKThemeType;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const map = useMap();
    const toggles = useToggles();

    const [searchStartDate, setSearchStartDate] = useState<NullableDate>(taskingSearchStartDate);
    const [searchEndDate, setSearchEndDate] = useState<NullableDate>(taskingSearchEndDate);

    useEffect(() => {
        setSearchStartDate(taskingSearchStartDate);
        setSearchEndDate(taskingSearchEndDate);
    }, [taskingSearchStartDate, taskingSearchEndDate]);

    const panelRef = useRef<HTMLDivElement>(null);

    useDisableFeatureOnMouseOver(panelRef, 'Zoom', true);
    useDisableFeatureOnMouseOver(panelRef, 'Drag', true);

    const resetPanel = () => {
        setSearchStartDate(null);
        setSearchEndDate(null);
    };

    return (
        <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <SidePanel hideOverflow={false}>
                <Box ref={panelRef} id="tasking-panel" col grow>
                    <Heading variant="small">Tasking Wizard</Heading>
                    {toggles.showGeocoding() && (
                        <GeocodingSearch
                            onSelected={(value) => {
                                const {
                                    geometry: { location },
                                } = value;
                                const url = `/tasking/@${location.lat},${location.lng}`;
                                navigate(url, { replace: true });

                                // @ts-ignore Typescript thinks that lat / lng are both functions. They aren't.
                                const coord = { lat: location.lat, lng: location.lng } as LatLng;
                                map.fitCoordinates([OSKGeoJson.fromCoordinate(coord)], 0.1);
                            }}
                        />
                    )}
                    <SidePanelHeader>
                        <Text variant="large" strong>
                            Build your task below
                        </Text>
                    </SidePanelHeader>
                    <Text strong variant="large">
                        Date
                    </Text>
                    <Text variant="small" color={theme.colors.black500} style={{ padding: '4px 0px' }}>
                        Select a timeframe to search within.
                    </Text>
                    <Box style={{ justifyContent: 'space-between', paddingTop: '20px' }}>
                        <DatePicker
                            variant="contrast"
                            name="start-date"
                            defaultValue={searchStartDate}
                            style={{ marginRight: '2px' }}
                            onChange={(newDate: NullableDate) => {
                                dispatch(setTaskingSearchStartDate(newDate));
                                setSearchStartDate(newDate);
                            }}
                        />
                        <DatePicker
                            variant="contrast"
                            name="end-date"
                            defaultValue={searchEndDate}
                            onChange={(newDate: NullableDate) => {
                                dispatch(setTaskingSearchEndDate(newDate));
                                setSearchEndDate(newDate);
                            }}
                        />
                    </Box>
                    <Divider style={{ margin: '40px 0px 20px 0px' }} />
                    <Box center="horizontal" style={{ width: '100%' }}>
                        <Text
                            onClick={() => {
                                resetPanel();
                            }}
                        >
                            Reset
                        </Text>
                    </Box>

                    <Box
                        h={80}
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'white',
                            width: '100%',
                            padding: '4px 8px',
                            boxShadow: '0px -1px 17px rgba(0, 0, 0, 0.19)',
                        }}
                        center="all"
                    >
                        <Button
                            variant="action"
                            style={{ width: '100%', height: '40px' }}
                            onClick={() => {
                                dispatch(setWizardFlow('tasking'));
                            }}
                            disabled={!selectedAoi}
                        >
                            <Box row center="all">
                                <OSKIcon code="shopping-cart" style={{ paddingRight: '8px' }} />
                                <Text strong>Preview & Checkout</Text>
                            </Box>
                        </Button>
                    </Box>
                </Box>
            </SidePanel>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        selectedAoi: state.tasking.app.selectedAoi,
        taskingSearchStartDate: state.tasking.app.taskingSearchStartDate,
        taskingSearchEndDate: state.tasking.app.taskingSearchEndDate,
    };
};

export default connect(mapStateToProps)(TaskingSidePanel);
