import { Box, OSKIcon, OSKThemeType, Spinner, Text } from 'oskcomponents';
import { noop, AssetDetail } from 'oskcore';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import MonitorAssetsTable from '~/organisms/tables/MonitorAssetsTable';
import { Asset } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import { getProgramId } from '~/utils';

type AssetsTableProps = {
    /** The current loading state of the user's asset list from redux  */
    fetchingAssets?: boolean;
    /** A list of all this user's assets, loaded from redux */
    assets: Asset[];
    /** The currently-selected asset */
    selectedAsset?: Asset;
    /** Error when fetching data */
    fetchError: boolean;
};

type UiState = 'Done' | 'Loading' | 'Error';

const AssetsTable = ({ fetchError, fetchingAssets, assets }: AssetsTableProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [uiState, setUiState] = useState<UiState>('Done');
    const theme = useTheme() as OSKThemeType;

    useEffect(() => {
        // Not fetching and no error
        !fetchingAssets && !fetchError && setUiState('Done');
        // Fetching and no error
        fetchingAssets && !fetchError && setUiState('Loading');
        // Not fetching and error
        !fetchingAssets && fetchError && setUiState('Error');
    }, [fetchError, fetchingAssets]);

    return (
        <Box ref={ref} data-testid="Assets-Table" style={{ overflow: 'scroll', height: '100%' }} col grow>
            {uiState === 'Done' && (
                <Box style={{ height: 0, width: '100%' }} col grow>
                    <MonitorAssetsTable data={assets} />
                </Box>
            )}
            {uiState === 'Loading' && (
                <Box grow center="all">
                    <Spinner />
                </Box>
            )}
            {uiState === 'Error' && (
                <Box col grow center="all">
                    <OSKIcon
                        m={10}
                        fill={theme.colors.red}
                        code="times"
                        style={{ fontSize: '50px', color: theme.colors.primary.inverted }}
                    />

                    <Text variant="large">There was an error when fetching your assets :(</Text>
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    const { assets, fetchingAssets, fetchError } = state.monitor.app;

    const assetList: Asset[] = [];
    for (const assetId in assets) {
        const assetEntry = assets[assetId];
        assetList.push({
            program: getProgramId(),
            id: assetEntry.id,
            monitoring_end_date: assetEntry.monitoring_end_date,
            monitoring_frequency: assetEntry.monitoring_frequency,
            monitoring_start_date: assetEntry.monitoring_start_date,
            name: assetEntry.name,

            // This is just an empty piece of data so we don't duplicate the entire aoi for a table
            aoi: { type: 'Point', coordinates: [] },
        } as AssetDetail);
    }

    return {
        fetchingAssets,
        assets: assetList,
        fetchError,
    };
};
export { AssetsTable };
export default connect(mapStateToProps, noop)(AssetsTable);
