import React from 'react';
import { useTheme } from 'styled-components';
import CSS from 'csstype';
import '../fonts.css';
import '../style.css';
import { OSKThemeType } from '../DefaultThemeProvider';

/** List of typography element types used as a parameter into the dynamic typography component  */
export type ElementTypes = 'Heading' | 'Subtitle' | 'Caption' | 'Text' | 'Overline';

/** List of variant types which are mostly used for specifying relative size */
export type VariantTypes = 'large' | 'medium' | 'small' | 'tiny' | 'huge';
export type TypographyTypes =
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'heading4'
    | 'heading5'
    | 'subtitle1'
    | 'subtitle2'
    | 'subtitle3'
    | 'caption1'
    | 'caption2'
    | 'caption3'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'body4'
    | 'overline1'
    | 'overline2';

type TextElementProps = {
    /** This defines which variant to render, mostly used for specifying relative size */
    variant: VariantTypes;
    /** Internal prop used by react to specify children components */
    children?: React.ReactNode;
    /** If true, apply an italic style to the text */
    italic?: boolean;
    /** If true, apply a bold style to the text */
    strong?: boolean;
    /** If true, apply an underline style to the text */
    underline?: boolean;
    /** Specify a class-based style */
    className?: string;
    /** Use this to override the component tag. For example, if you
       are dealing with a header you can specify whether it should
       be rendered as an H3 or H4 or H5 tag, etc. Regardless of default. */
    as?: string;
    /** Override default styles with an inline style object */
    style?: CSS.Properties;
    /** Override the default color */
    color?: string;
    /** Override the default font */
    font?: string;
    /** Width of component */
    w?: number;
    /** Height of component */
    h?: number;
    /** Margin of component */
    m?: number;
    /** Padding of component */
    p?: number;
    /** Padding top of component" */
    pt?: number;
} & JSX.IntrinsicElements['p'];

const variantLevel = {
    tiny: 0,
    small: 1,
    medium: 2,
    large: 3,
    huge: 4,
};

/**
 * This is a util method which takes an object and a variant and will return
 * either the nth element from the object (assuming it is an array)
 * or the raw value (assuming it is not an array). This is a convenience
 * helper to apply to variants which are defined in the ThemeProvider.
 *
 * For example:
 * Heading: {
 *  size: ['1.17rem', '1.04rem', '0.87rem']
 * }
 *
 * If you call getProperty(Heading.size, 'small')
 * It will return '0.87rem' because that's the 3rd element
 * which corresponds to the small variant.
 *
 * @param obj An array or single value to evaluate
 * @param variant A string representing the VariantTypes object
 * @returns The value from the obj evaluated for variant
 */
const getProperty = (obj: any, variant: VariantTypes) => {
    if (Array.isArray(obj)) {
        return obj[variantLevel[variant]];
    } else {
        return obj;
    }
};

const InternalTextElement = (
    el: ElementTypes,
    {
        as,
        color,
        className,
        w,
        h,
        p,
        pt,
        m,
        children,
        italic,
        strong,
        underline,
        variant = 'medium',
        style,
        font,
        ...props
    }: TextElementProps,
) => {
    const theme = useTheme() as OSKThemeType;
    const configObject = theme.text[el];

    return React.createElement(
        as ?? getProperty(configObject.as, variant),
        {
            role: 'typography',
            className,
            style: {
                color,
                fontFamily: font ?? theme.font,
                fontSize: getProperty(configObject.size, variant),
                fontWeight: strong ? 'bold' : getProperty(configObject.weight, variant),
                fontStyle: italic ? 'italic' : 'normal',
                textDecoration: underline ? 'underline' : 'none',
                width: w ? w + 'px' : '',
                height: h ? h + 'px' : '',
                padding: p ? p + 'px' : '',
                paddingTop: pt ?? 'default',
                margin: m ? m + 'px' : '',
                cursor: props.onClick ? 'pointer' : 'default',
                ...style,
            },
            ...configObject.props,
            ...props,
        },
        children,
    );
};

const Heading = ({ ...props }: TextElementProps) => {
    return InternalTextElement('Heading', props);
};

Heading.defaultProps = {
    variant: 'medium',
};

const Subtitle = ({ ...props }: TextElementProps) => {
    return InternalTextElement('Subtitle', props);
};

Subtitle.defaultProps = {
    variant: 'medium',
};

const Caption = ({ ...props }: TextElementProps) => {
    return InternalTextElement('Caption', props);
};

Caption.defaultProps = {
    variant: 'medium',
};

const Overline = ({ ...props }: TextElementProps) => {
    return InternalTextElement('Overline', props);
};

Overline.defaultProps = {
    variant: 'medium',
};

const Text = ({ ...props }: TextElementProps) => {
    return InternalTextElement('Text', props);
};

Text.defaultProps = {
    variant: 'medium',
};

const Typography = ({ ...props }: Omit<TextElementProps, 'variant'> & { variant: TypographyTypes }) => {
    const { variant, ...otherProps } = props;
    const MappedVariants: Record<TypographyTypes, VariantTypes> = {
        body1: 'huge',
        body2: 'large',
        body3: 'medium',
        body4: 'small',
        subtitle1: 'huge',
        subtitle2: 'large',
        subtitle3: 'medium',
        heading1: 'huge',
        heading2: 'large',
        heading3: 'medium',
        heading4: 'small',
        heading5: 'tiny',
        caption1: 'huge',
        caption2: 'large',
        caption3: 'medium',
        overline1: 'huge',
        overline2: 'large',
    };

    switch (variant) {
        case 'body1':
        case 'body2':
        case 'body3':
        case 'body4': {
            return <Text {...otherProps} variant={MappedVariants[variant]} />;
        }

        case 'heading1':
        case 'heading2':
        case 'heading3':
        case 'heading4':
        case 'heading5': {
            return <Heading {...otherProps} variant={MappedVariants[variant]} />;
        }

        case 'subtitle1':
        case 'subtitle2':
        case 'subtitle3': {
            return <Subtitle {...otherProps} variant={MappedVariants[variant]} />;
        }

        case 'caption1':
        case 'caption2':
        case 'caption3': {
            return <Caption {...otherProps} variant={MappedVariants[variant]} />;
        }

        case 'overline1':
        case 'overline2': {
            return <Overline {...otherProps} variant={MappedVariants[variant]} />;
        }
    }
};

export type { TextElementProps };
export { Heading, Text, Subtitle, Caption, Overline, Typography };
